import {formatDistanceToNowStrict, parseISO} from 'date-fns';
import {ImageTitleButton} from '../../components/buttons/ImageTitleButton';
import {LocalizationManager} from '../../localization/LocalizationManager';
import {AttributedText} from '../../models/attributed_text/AttributedText';
import {CompoundImage} from '../../models/image/CompoundImage';
import {Joke} from '../../models/joke/Joke';
import {User} from '../../models/user/User';
import {ApplicationConstraints} from '../../style/ApplicationConstraints';
import {ApplicationLocalization} from '../application/ApplicationLocalization';
import {JokeDetailsModels} from './JokeDetailsModels';
import {JokeDetailsDisplayLogic} from './JokeDetailsScene';
import {JokeDetailsStyle} from './JokeDetailsStyle';
import {enUS, ro} from 'date-fns/locale';
import {SpaceCell} from '../../components/cells/space/SpaceCell';
import {UserAvatarView} from '../../components/views/user_avatar_view/UserAvatarView';
import {JokeTextCell} from '../../components/cells/joke/JokeTextCell';
import {JokeQuestionAnswerCell} from '../../components/cells/joke/JokeQuestionAnswerCell';
import {LoadingImageView} from '../../components/views/image/LoadingImage';

export interface JokeDetailsPresentationLogic {
  presentLoadingState(): void;
  presentNotLoadingState(): void;

  presentItems(response: JokeDetailsModels.ItemsPresentation.Response): void;

  presentErrorActionAlert(response: JokeDetailsModels.ActionAlertPresentation.Response): void;

  presentNavigateToUrl(response: JokeDetailsModels.UrlNavigation.Response): void;
  presentNavigateToJokeReports(response: JokeDetailsModels.JokeReportsNavigation.Response): void;
  presentNavigateToShareJoke(response: JokeDetailsModels.ShareJokeNavigation.Response): void;

  presentDismissScene(): void;

  presentLikeLoadingState(response: JokeDetailsModels.ItemLoadingState.Response): void;
  presentDislikeLoadingState(response: JokeDetailsModels.ItemLoadingState.Response): void;

  presentLikeState(response: JokeDetailsModels.ItemState.Response): void;
  presentDislikeState(response: JokeDetailsModels.ItemState.Response): void;

  presentReadState(response: JokeDetailsModels.ItemReadState.Response): void;

  presentJokeAddLikeSuccessfully(response: JokeDetailsModels.JokeDetails.Response): void;
  presentJokeDeleteLikeSuccessfully(response: JokeDetailsModels.JokeDetails.Response): void;

  presentJokeAddDislikeSuccessfully(response: JokeDetailsModels.JokeDetails.Response): void;
  presentJokeDeleteDislikeSuccessfully(response: JokeDetailsModels.JokeDetails.Response): void;

  presentJokeUpdateLikeSuccessfully(response: JokeDetailsModels.JokeDetails.Response): void;

  presentDismissActionSheetScene(): void;
}

export class JokeDetailsPresenter implements JokeDetailsPresentationLogic {
  displayer?: JokeDetailsDisplayLogic;

  presentLoadingState() {
    this.displayer?.displayLoadingState();
  }

  presentNotLoadingState() {
    this.displayer?.displayNotLoadingState();
  }

  presentItems(response: JokeDetailsModels.ItemsPresentation.Response) {
    this.displayer?.displayItems(new JokeDetailsModels.ItemsPresentation.ViewModel(this.displayedItems(response.joke, response.isJokeRead)));
  }

  presentErrorActionAlert(response: JokeDetailsModels.ActionAlertPresentation.Response): void {
    let message = response.error.localizedMessage();
    this.displayer?.displayErrorActionAlert(new JokeDetailsModels.ActionAlertPresentation.ViewModel(undefined, message));
  }

  presentNavigateToUrl(response: JokeDetailsModels.UrlNavigation.Response): void {
    this.displayer?.displayNavigateToUrl(new JokeDetailsModels.UrlNavigation.ViewModel(response.url));
  }

  presentNavigateToJokeReports(response: JokeDetailsModels.JokeReportsNavigation.Response): void {
    this.displayer?.displayNavigateToJokeReports(new JokeDetailsModels.JokeReportsNavigation.ViewModel(response.id));
  }

  presentNavigateToShareJoke(response: JokeDetailsModels.ShareJokeNavigation.Response): void {
    // let title = ApplicationLocalization.instance.shareJokeMessage();
    // let url = EndpointsBuilder.instance.webJokeEndpoint(response.id);
    // this.displayer?.displayNavigateToShareJoke(new JokeDetailsModels.ShareJokeNavigation.ViewModel(title, url));
  }

  presentDismissScene(): void {
    this.displayer?.displayDismissScene();
  }

  presentLikeLoadingState(response: JokeDetailsModels.ItemLoadingState.Response) {
    this.displayer?.displayLikeLoadingState(new JokeDetailsModels.ItemLoadingState.ViewModel(response.isLoading, response.id));
  }

  presentDislikeLoadingState(response: JokeDetailsModels.ItemLoadingState.Response) {
    this.displayer?.displayDislikeLoadingState(new JokeDetailsModels.ItemLoadingState.ViewModel(response.isLoading, response.id));
  }

  presentLikeState(response: JokeDetailsModels.ItemState.Response) {
    let backgroundColor = response.isSelected ? JokeDetailsStyle.instance.jokeCellModel().selectedLikeCountBackgroundColor : JokeDetailsStyle.instance.jokeCellModel().unselectedLikeCountBackgroundColor;
    let textStyle = response.isSelected ? JokeDetailsStyle.instance.jokeCellModel().selectedLikeCountStyle : JokeDetailsStyle.instance.jokeCellModel().unselectedLikeCountStyle;
    let text = new AttributedText(response.count?.toString(), textStyle);
    let imageTintColor = response.isSelected ? JokeDetailsStyle.instance.jokeCellModel().selectedLikeCountTintColor : JokeDetailsStyle.instance.jokeCellModel().unselectedLikeCountTintColor;
    this.displayer?.displayLikeState(new JokeDetailsModels.ItemState.ViewModel(backgroundColor, response.id, text, imageTintColor));
  }

  presentDislikeState(response: JokeDetailsModels.ItemState.Response) {
    let backgroundColor = response.isSelected ? JokeDetailsStyle.instance.jokeCellModel().selectedDislikeCountBackgroundColor : JokeDetailsStyle.instance.jokeCellModel().unselectedDislikeCountBackgroundColor;
    let textStyle = response.isSelected ? JokeDetailsStyle.instance.jokeCellModel().selectedDislikeCountStyle : JokeDetailsStyle.instance.jokeCellModel().unselectedDislikeCountStyle;
    let text = new AttributedText(response.count?.toString(), textStyle);
    let imageTintColor = response.isSelected ? JokeDetailsStyle.instance.jokeCellModel().selectedDislikeCountTintColor : JokeDetailsStyle.instance.jokeCellModel().unselectedDislikeCountTintColor;
    this.displayer?.displayDislikeState(new JokeDetailsModels.ItemState.ViewModel(backgroundColor, response.id, text, imageTintColor));
  }

  presentReadState(response: JokeDetailsModels.ItemReadState.Response): void {
    this.displayer?.displayReadState(new JokeDetailsModels.ItemReadState.ViewModel(response.isRead, response.id));
  }

  presentJokeAddLikeSuccessfully(response: JokeDetailsModels.JokeDetails.Response): void {
    this.displayer?.displayJokeAddLikeSuccessfully(new JokeDetailsModels.JokeDetails.ViewModel(response.jokeId));
  }

  presentJokeDeleteLikeSuccessfully(response: JokeDetailsModels.JokeDetails.Response): void {
    this.displayer?.displayJokeDeleteLikeSuccessfully(new JokeDetailsModels.JokeDetails.ViewModel(response.jokeId));
  }

  presentJokeAddDislikeSuccessfully(response: JokeDetailsModels.JokeDetails.Response): void {
    this.displayer?.displayJokeAddDislikeSuccessfully(new JokeDetailsModels.JokeDetails.ViewModel(response.jokeId));
  }

  presentJokeDeleteDislikeSuccessfully(response: JokeDetailsModels.JokeDetails.Response): void {
    this.displayer?.displayJokeDeleteDislikeSuccessfully(new JokeDetailsModels.JokeDetails.ViewModel(response.jokeId));
  }

  presentJokeUpdateLikeSuccessfully(response: JokeDetailsModels.JokeDetails.Response): void {
    this.displayer?.displayJokeUpdateLikeSuccessfully(new JokeDetailsModels.JokeDetails.ViewModel(response.jokeId));
  }

  presentDismissActionSheetScene(): void {
    this.displayer?.displayDismissActionSheetScene();
  }

  //#region Items
  displayedItems(joke: Joke, isJokeRead: boolean): JokeDetailsModels.DisplayedItem[] {
    var items: (JokeDetailsModels.DisplayedItem | undefined)[] = [];
    items.push(this.displayedSpaceItem(ApplicationConstraints.constant.x16));
    items.push(this.displayedJokeItem(joke, isJokeRead));
    items.push(this.displayedSpaceItem(ApplicationConstraints.constant.x16));
    return items.filter((item): item is JokeDetailsModels.DisplayedItem => !!item);
  }

  displayedSpaceItem(height: number): JokeDetailsModels.DisplayedItem {
    return new JokeDetailsModels.DisplayedItem(JokeDetailsModels.ItemType.space, new SpaceCell.Model(height));
  }
  //#endregion

  //#region Joke
  displayedJokeItem(joke: Joke, isRead: boolean): JokeDetailsModels.DisplayedItem {
    if (joke.type === Joke.Type.qna.value && joke.answer !== undefined) {
      return this.displayedJokeQnaItem(joke, isRead);
    }
    return this.displayedJokeTextItem(joke);
  }

  displayedJokeTextItem(joke: Joke): JokeDetailsModels.DisplayedItem {
    let avatar = this.jokeAvatarViewModel(joke);
    let likeCount = this.jokeLikeViewModel(joke);
    let dislikeCount = this.jokeDislikeViewModel(joke);
    let commentCount = this.jokeCommentViewModel(joke);
    // let share = this.jokeShareViewModel();

    let model = new JokeTextCell.Model(avatar);
    model.id = joke.uuid;
    model.name = new AttributedText(joke.user?.name, JokeDetailsStyle.instance.jokeCellModel().nameStyle);
    model.username = new AttributedText(this.usernameText(joke.user), JokeDetailsStyle.instance.jokeCellModel().usernameStyle);
    model.text = new AttributedText(joke.text, JokeDetailsStyle.instance.jokeCellModel().textStyle);
    model.likeCount = likeCount;
    model.dislikeCount = dislikeCount;
    model.commentCount = commentCount;
    // model.share = share;
    model.hideMore = true;
    model.time = new AttributedText(this.time(joke.createdAt), JokeDetailsStyle.instance.jokeCellModel().timeStyle);
    model.isBlocked = false;
    return new JokeDetailsModels.DisplayedItem(JokeDetailsModels.ItemType.jokeText, model);
  }

  displayedJokeQnaItem(joke: Joke, isRead: boolean): JokeDetailsModels.DisplayedItem {
    let avatar = this.jokeAvatarViewModel(joke);
    let likeCount = this.jokeLikeViewModel(joke);
    let dislikeCount = this.jokeDislikeViewModel(joke);
    let commentCount = this.jokeCommentViewModel(joke);
    // let share = this.jokeShareViewModel();

    let model = new JokeQuestionAnswerCell.Model(avatar);
    model.id = joke.uuid;
    model.name = new AttributedText(joke.user?.name, JokeDetailsStyle.instance.jokeCellModel().nameStyle);
    model.username = new AttributedText(this.usernameText(joke.user), JokeDetailsStyle.instance.jokeCellModel().usernameStyle);
    model.text = new AttributedText(joke.text, JokeDetailsStyle.instance.jokeCellModel().textStyle);
    model.answer = new AttributedText(joke.answer, JokeDetailsStyle.instance.jokeCellModel().answerStyle);
    model.likeCount = likeCount;
    model.dislikeCount = dislikeCount;
    model.commentCount = commentCount;
    // model.share = share;
    model.hideMore = true;
    model.time = new AttributedText(this.time(joke.createdAt), JokeDetailsStyle.instance.jokeCellModel().timeStyle);
    model.isBlocked = false;
    model.isRead = isRead;
    return new JokeDetailsModels.DisplayedItem(JokeDetailsModels.ItemType.jokeQna, model);
  }
  //#endregion

  jokeAvatarViewModel(joke: Joke): UserAvatarView.Model {
    let loadingImage = new LoadingImageView.Model(this.compoundImage(joke.user?.photo?.url150, JokeDetailsStyle.instance.jokeCellModel().avatarPlaceholder), false);
    loadingImage.activityIndicatorColor = JokeDetailsStyle.instance.jokeCellModel().avatarActivityColor;
    loadingImage.borderTopLeftRadius = JokeDetailsStyle.instance.jokeCellModel().avatarBorderRadius;
    loadingImage.borderTopRightRadius = JokeDetailsStyle.instance.jokeCellModel().avatarBorderRadius;
    loadingImage.borderBottomLeftRadius = JokeDetailsStyle.instance.jokeCellModel().avatarBorderRadius;
    loadingImage.borderBottomRightRadius = JokeDetailsStyle.instance.jokeCellModel().avatarBorderRadius;

    let avatar = new UserAvatarView.Model(loadingImage);
    avatar.isDisabled = false;
    avatar.backgroundColor = JokeDetailsStyle.instance.jokeCellModel().avatarBackgroundColor;
    avatar.borderWidth = JokeDetailsStyle.instance.jokeCellModel().avatarBorderWidth;
    avatar.borderColor = JokeDetailsStyle.instance.jokeCellModel().avatarBorderColor;
    avatar.margin = JokeDetailsStyle.instance.jokeCellModel().avatarMargin;
    return avatar;
  }

  jokeLikeViewModel(joke: Joke): ImageTitleButton.Model {
    let isLiked = joke.isLiked();
    let likeCount = new ImageTitleButton.Model();
    likeCount.activityIndicatorColor = JokeDetailsStyle.instance.jokeCellModel().likeCountActivityColor;
    likeCount.image = new CompoundImage(undefined, JokeDetailsStyle.instance.jokeCellModel().likeCountImage);
    likeCount.imageTintColor = isLiked ? JokeDetailsStyle.instance.jokeCellModel().selectedLikeCountTintColor : JokeDetailsStyle.instance.jokeCellModel().unselectedLikeCountTintColor;
    likeCount.backgroundColor = isLiked ? JokeDetailsStyle.instance.jokeCellModel().selectedLikeCountBackgroundColor : JokeDetailsStyle.instance.jokeCellModel().unselectedLikeCountBackgroundColor;
    likeCount.title = new AttributedText(joke?.likeCount.toString(), isLiked ? JokeDetailsStyle.instance.jokeCellModel().selectedLikeCountStyle : JokeDetailsStyle.instance.jokeCellModel().unselectedLikeCountStyle);
    likeCount.borderRadius = ApplicationConstraints.constant.x12;
    likeCount.isDisabled = false;
    likeCount.isLoading = false;
    return likeCount;
  }

  jokeDislikeViewModel(joke: Joke): ImageTitleButton.Model {
    let isDisliked = joke.isDisliked();
    let dislikeCount = new ImageTitleButton.Model();
    dislikeCount.activityIndicatorColor = JokeDetailsStyle.instance.jokeCellModel().dislikeCountActivityColor;
    dislikeCount.image = new CompoundImage(undefined, JokeDetailsStyle.instance.jokeCellModel().dislikeCountImage);
    dislikeCount.imageTintColor = isDisliked ? JokeDetailsStyle.instance.jokeCellModel().selectedDislikeCountTintColor : JokeDetailsStyle.instance.jokeCellModel().unselectedDislikeCountTintColor;
    dislikeCount.backgroundColor = isDisliked ? JokeDetailsStyle.instance.jokeCellModel().selectedDislikeCountBackgroundColor : JokeDetailsStyle.instance.jokeCellModel().unselectedDislikeCountBackgroundColor;
    dislikeCount.title = new AttributedText(joke?.dislikeCount.toString(), isDisliked ? JokeDetailsStyle.instance.jokeCellModel().selectedDislikeCountStyle : JokeDetailsStyle.instance.jokeCellModel().unselectedDislikeCountStyle);
    dislikeCount.borderRadius = ApplicationConstraints.constant.x12;
    dislikeCount.isDisabled = false;
    dislikeCount.isLoading = false;
    return dislikeCount;
  }

  jokeCommentViewModel(joke: Joke): ImageTitleButton.Model {
    let commentCount = new ImageTitleButton.Model();
    commentCount.activityIndicatorColor = JokeDetailsStyle.instance.jokeCellModel().commentCountActivityColor;
    commentCount.image = new CompoundImage(undefined, JokeDetailsStyle.instance.jokeCellModel().commentCountImage);
    commentCount.imageTintColor = JokeDetailsStyle.instance.jokeCellModel().commentCountTintColor;
    commentCount.backgroundColor = JokeDetailsStyle.instance.jokeCellModel().commentCountBackgroundColor;
    commentCount.title = new AttributedText(joke.commentCount.toString(), JokeDetailsStyle.instance.jokeCellModel().commentCountStyle);
    commentCount.borderRadius = ApplicationConstraints.constant.x12;
    commentCount.isDisabled = true;
    commentCount.isLoading = false;
    return commentCount;
  }

  jokeShareViewModel(): ImageTitleButton.Model {
    let shareModel = new ImageTitleButton.Model();
    shareModel.image = new CompoundImage(undefined, JokeDetailsStyle.instance.jokeCellModel().shareImage);
    shareModel.backgroundColor = JokeDetailsStyle.instance.jokeCellModel().shareBackgroundColor;
    shareModel.title = new AttributedText(ApplicationLocalization.instance.shareTitle(), JokeDetailsStyle.instance.jokeCellModel().shareStyle);
    shareModel.isDisabled = false;
    shareModel.isLoading = false;
    return shareModel;
  }

  usernameText(user?: User): string | undefined {
    let username = user?.username;
    if (username !== undefined) {
      return ApplicationLocalization.instance.usernameTitle(username);
    }
    return undefined;
  }

  compoundImage(url?: string, placeholder?: any): CompoundImage {
    if (url !== undefined && url !== null && url.length > 0) {
      let image = new CompoundImage(url);
      image.resizeMode = 'cover';
      return image;
    }
    let image = new CompoundImage(undefined, placeholder);
    image.resizeMode = 'cover';
    return image;
  }

  time(createdAt?: string): string | undefined {
    if (createdAt === undefined || createdAt === null || createdAt.length === 0) {
      return undefined;
    }
    let date = parseISO(createdAt);
    let locale = this.locale();
    return formatDistanceToNowStrict(date, {addSuffix: false, roundingMethod: 'floor', locale: locale});
  }

  locale(): Locale | undefined {
    switch (LocalizationManager.instance.preferredLocalization()) {
      case 'en':
        return enUS;
      case 'ro':
        return ro;
      default:
        return undefined;
    }
  }

  isValidUrl(value: string): boolean {
    try {
      return Boolean(new URL(value));
    } catch {
      return false;
    }
  }
}
