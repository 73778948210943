import {Message} from '../../models/message/Message';
import {OperationError} from '../../operations/base/errors/OperationError';
import {ChangePasswordModels} from './ChangePasswordModels';
import {ChangePasswordPresentationLogic} from './ChangePasswordPresenter';
import {ChangePasswordWorker, ChangePasswordWorkerDelegate} from './ChangePasswordWorker';

export interface ChangePasswordBusinessLogic {
  shouldSetResetPasswordToken(request: ChangePasswordModels.UpdateToken.Request): void;

  shouldCheckNewPasswordValidity(): void;
  shouldCheckConfirmNewPasswordValidity(): void;

  shouldUpdateNewPassword(request: ChangePasswordModels.UpdateInput.Request): void;
  shouldUpdateConfirmNewPassword(request: ChangePasswordModels.UpdateInput.Request): void;

  shouldChangePassword(): void;
}

export class ChangePasswordInteractor implements ChangePasswordBusinessLogic, ChangePasswordWorkerDelegate {
  presenter?: ChangePasswordPresentationLogic;
  worker?: ChangePasswordWorker;

  newPassword?: string;
  confirmNewPassword?: string;
  resetPasswordToken: string | null = null;

  constructor() {
    this.worker = new ChangePasswordWorker(this);
  }

  shouldSetResetPasswordToken(request: ChangePasswordModels.UpdateToken.Request): void {
    this.resetPasswordToken = request.resetPasswordToken;
  }

  shouldCheckNewPasswordValidity(): void {
    if (this.isValidNewPassword()) {
      this.presenter?.presentValidNewPassword();
    } else {
      this.presenter?.presentInvalidNewPassword();
    }
  }

  shouldCheckConfirmNewPasswordValidity(): void {
    if (this.isValidConfirmNewPassword()) {
      this.presenter?.presentValidConfirmNewPassword();
    } else {
      this.presenter?.presentInvalidConfirmNewPassword();
    }
  }

  shouldUpdateNewPassword(request: ChangePasswordModels.UpdateInput.Request): void {
    this.newPassword = request.text;
    this.presenter?.presentValidNewPassword();
    this.presenter?.presentNoErrorMessage();
    this.presenter?.presentNoSuccessMessage();
    this.handleChangePasswordEligibility();
  }

  shouldUpdateConfirmNewPassword(request: ChangePasswordModels.UpdateInput.Request): void {
    this.confirmNewPassword = request.text;
    this.presenter?.presentValidConfirmNewPassword();
    this.presenter?.presentNoErrorMessage();
    this.presenter?.presentNoSuccessMessage();
    this.handleChangePasswordEligibility();
  }

  //#region Change password
  shouldChangePassword(): void {
    if (this.newPassword === undefined || this.confirmNewPassword === undefined || this.resetPasswordToken === null) {
      return;
    }

    this.presenter?.presentDisabledChangePasswordButton();
    this.presenter?.presentLoadingState();
    this.worker?.changePassword(this.newPassword, this.confirmNewPassword, this.resetPasswordToken);
  }

  successDidChangePassword(message?: Message): void {
    this.presenter?.presentEnabledChangePasswordButton();
    this.presenter?.presentNotLoadingState();
    this.presenter?.presentSuccessChangePassword(new ChangePasswordModels.ChangePasswordSuccess.Response(message));
  }

  failureDidChangePassword(error: OperationError): void {
    this.presenter?.presentEnabledChangePasswordButton();
    this.presenter?.presentNotLoadingState();
    this.presenter?.presentFailureChangePassword(new ChangePasswordModels.ChangePasswordFailure.Response(error));
  }
  //#endregion

  //#region Auxiliary
  isEligibleForChangePassword() {
    return this.isValidNewPassword() && this.isValidConfirmNewPassword();
  }

  isValidNewPassword() {
    return this.newPassword && this.newPassword.length >= 6;
  }

  isValidConfirmNewPassword() {
    return this.newPassword === this.confirmNewPassword;
  }

  handleChangePasswordEligibility() {
    if (this.isEligibleForChangePassword()) {
      this.presenter?.presentEnabledChangePasswordButton();
    } else {
      this.presenter?.presentDisabledChangePasswordButton();
    }
  }
  //#endregion
}
