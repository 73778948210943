import React from 'react';

export default class View extends React.Component<View.Props, View.State> {
  render() {
    return <div {...this.props}>{this.props.children}</div>;
  }
}

export namespace View {
  export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

  export interface State {}
}
