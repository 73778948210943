import React from 'react';
import {ChangePasswordBusinessLogic, ChangePasswordInteractor} from './ChangePasswordInteractor';
import {ChangePasswordModels} from './ChangePasswordModels';
import {ChangePasswordPresenter} from './ChangePasswordPresenter';
import {ChangePasswordRoutingLogic, ChangePasswordRouter} from './ChangePasswordRouter';
import {ChangePasswordStyle} from './ChangePasswordStyle';
import {ApplicationConstraints} from '../../style/ApplicationConstraints';
import {LogoNavigationView} from '../../components/views/logo_navigation_view/LogoNavigationView';
import View from '../../components/views/view/View';
import Text from '../../components/text/Text';
import {StyleSheet} from '../../models/style_sheet/StyleSheet';
import {UserAvatarView} from '../../components/views/user_avatar_view/UserAvatarView';
import {LoadingImageView} from '../../components/views/image/LoadingImage';
import {CompoundImage} from '../../models/image/CompoundImage';
import {ApplicationStyle} from '../../style/ApplicationStyle';
import {InputTextField, InputTextFieldState} from '../../components/input_text_field/InputTextField';
import {AttributedText} from '../../models/attributed_text/AttributedText';
import {ChangePasswordLocalization} from './ChangePasswordLocalization';
import {ImageTitleButton} from '../../components/buttons/ImageTitleButton';

export interface ChangePasswordDisplayLogic {
  displayNewPasswordInputValidation(viewModel: ChangePasswordModels.PasswordValidation.ViewModel): void;
  displayConfirmNewPasswordInputValidation(viewModel: ChangePasswordModels.PasswordValidation.ViewModel): void;

  displayDisabledChangePasswordButton(): void;
  displayEnabledChangePasswordButton(): void;

  displayLoadingState(): void;
  displayNotLoadingState(): void;

  displaySuccessChangePassword(viewModel: ChangePasswordModels.ChangePasswordSuccess.ViewModel): void;
  displayFailureChangePassword(viewModel: ChangePasswordModels.ChangePasswordFailure.ViewModel): void;

  displayNoErrorMessage(): void;
  displayNoSuccessMessage(): void;
}

export class ChangePasswordScene extends React.Component<ChangePasswordScene.Props, ChangePasswordScene.State> implements ChangePasswordDisplayLogic, LogoNavigationView.Delegate {
  interactor?: ChangePasswordBusinessLogic;
  router?: ChangePasswordRoutingLogic;

  navigationBarView?: LogoNavigationView | null;
  newPasswordInputTextField?: InputTextField | null;
  confirmNewPasswordInputTextField?: InputTextField | null;
  changePasswordButton?: ImageTitleButton | null;

  constructor(props: ChangePasswordScene.Props) {
    super(props);
    this.setup();
  }

  setup() {
    let interactor = new ChangePasswordInteractor();
    let presenter = new ChangePasswordPresenter();
    let router = new ChangePasswordRouter();
    this.interactor = interactor;
    this.router = router;
    interactor.presenter = presenter;
    presenter.displayer = this;
    router.scene = this;
  }

  componentDidMount() {
    this.interactor?.shouldSetResetPasswordToken(new ChangePasswordModels.UpdateToken.Request(this.props.model.resetPasswordToken));
  }

  //#region Subviews
  reload() {
    this.setState({});
  }

  render() {
    return (
      <View style={{...this.constraints.safeAreaView, ...this.styles.safeAreaView}}>
        {this.setupNavigationBarView()}
        {this.setupContainerView()}
      </View>
    );
  }

  setupNavigationBarView() {
    let model = new LogoNavigationView.Model(new UserAvatarView.Model(new LoadingImageView.Model(new CompoundImage(ApplicationStyle.images.userAvatarPlaceholderSmallImage), false)));
    model.includeSeparator = true;
    return <LogoNavigationView ref={ref => (this.navigationBarView = ref)} delegate={this} style={this.constraints.navigationBarView} model={model} />;
  }

  logoNavigationViewOnPressBackButton(_view: LogoNavigationView): void {}

  logoNavigationViewOnPressLogoImage(_view: LogoNavigationView): void {}

  logoNavigationViewOnPressUserAvatar(_view: LogoNavigationView): void {}

  setupContainerView() {
    return (
      <View style={this.constraints.containerView}>
        {this.setupNewPasswordInputTextField()}
        {this.setupConfirmNewPasswordInputTextField()}
        {this.setupChangePasswordButton()}
        {this.setupSuccessMessageText()}
        {this.setupErrorMessageText()}
      </View>
    );
  }

  setupNewPasswordInputTextField() {
    let model = new InputTextField.Model();
    model.text = new AttributedText(this.props.model.newPassword, ChangePasswordStyle.instance.inputTextModel().textStyle);
    model.error = new AttributedText(this.props.model.newPasswordError, ChangePasswordStyle.instance.inputTextModel().errorStyle);
    model.state = this.props.model.newPasswordState;
    model.placeholderText = ChangePasswordLocalization.instance.newPasswordPlaceholder();
    model.autoComplete = 'off';
    model.keyboardType = 'password';
    model.borderColor = ChangePasswordStyle.instance.inputTextModel().borderColor;
    model.borderRadius = ChangePasswordStyle.instance.inputTextModel().borderRadius;
    model.borderWidth = ChangePasswordStyle.instance.inputTextModel().borderWidth;
    return (
      <InputTextField
        ref={ref => (this.newPasswordInputTextField = ref)}
        style={this.constraints.inputText}
        model={model}
        onChangeText={text => this.onChangeNewPasswordText(text)}
        onSubmitEditing={() => this.interactor?.shouldCheckNewPasswordValidity()}
      />
    );
  }

  onChangeNewPasswordText(text?: string) {
    this.props.model.newPassword = text;
    this.interactor?.shouldUpdateNewPassword(new ChangePasswordModels.UpdateInput.Request(text));
  }

  setupConfirmNewPasswordInputTextField() {
    let model = new InputTextField.Model();
    model.text = new AttributedText(this.props.model.confirmNewPassword, ChangePasswordStyle.instance.inputTextModel().textStyle);
    model.error = new AttributedText(this.props.model.confirmNewPasswordError, ChangePasswordStyle.instance.inputTextModel().errorStyle);
    model.state = this.props.model.confirmNewPasswordState;
    model.placeholderText = ChangePasswordLocalization.instance.confirmNewPasswordPlaceholder();
    model.autoComplete = 'off';
    model.keyboardType = 'password';
    model.borderColor = ChangePasswordStyle.instance.inputTextModel().borderColor;
    model.borderRadius = ChangePasswordStyle.instance.inputTextModel().borderRadius;
    model.borderWidth = ChangePasswordStyle.instance.inputTextModel().borderWidth;
    return (
      <InputTextField
        ref={ref => (this.confirmNewPasswordInputTextField = ref)}
        style={this.constraints.inputText}
        model={model}
        onChangeText={text => this.onChangeConfirmNewPasswordText(text)}
        onSubmitEditing={() => this.interactor?.shouldCheckConfirmNewPasswordValidity()}
      />
    );
  }

  onChangeConfirmNewPasswordText(text?: string) {
    this.props.model.confirmNewPassword = text;
    this.interactor?.shouldUpdateConfirmNewPassword(new ChangePasswordModels.UpdateInput.Request(text));
  }

  setupChangePasswordButton() {
    let model = new ImageTitleButton.Model();
    model.title = new AttributedText(ChangePasswordLocalization.instance.changePasswordText(), ChangePasswordStyle.instance.changePasswordButtonModel().textStyle);
    model.backgroundColor = ChangePasswordStyle.instance.changePasswordButtonModel().backgroundColor;
    model.activityIndicatorColor = ChangePasswordStyle.instance.changePasswordButtonModel().activityIndicatorColor;
    model.isDisabled = this.props.model.isChangePasswordDisabled;
    model.isLoading = this.props.model.isChangePasswordLoading;
    model.opacity = this.props.model.isChangePasswordDisabled ? 0.25 : 1;
    model.borderRadius = ChangePasswordStyle.instance.changePasswordButtonModel().borderRadius;
    model.backgroundImage = new CompoundImage(undefined, ApplicationStyle.images.buttonBackgroundImage, 'cover');
    return <ImageTitleButton ref={ref => (this.changePasswordButton = ref)} style={this.constraints.changePasswordButton} model={model} onPress={() => this.interactor?.shouldChangePassword()} />;
  }

  setupSuccessMessageText() {
    if (this.props.model.successMessage === undefined) {
      return null;
    }
    return <Text attributedText={new AttributedText(this.props.model.successMessage, ChangePasswordStyle.instance.contentViewModel().successStyle)} />;
  }

  setupErrorMessageText() {
    if (this.props.model.errorMessage === undefined) {
      return null;
    }
    return <Text attributedText={new AttributedText(this.props.model.errorMessage, ChangePasswordStyle.instance.contentViewModel().errorStyle)} />;
  }
  //#endregion

  //#region Display logic
  displayNewPasswordInputValidation(viewModel: ChangePasswordModels.PasswordValidation.ViewModel) {
    this.props.model.newPasswordState = viewModel.state;
    this.props.model.newPasswordError = viewModel.errorMessage;
    this.reload();
  }

  displayConfirmNewPasswordInputValidation(viewModel: ChangePasswordModels.PasswordValidation.ViewModel) {
    this.props.model.confirmNewPasswordState = viewModel.state;
    this.props.model.confirmNewPasswordError = viewModel.errorMessage;
    this.reload();
  }

  displayDisabledChangePasswordButton() {
    this.props.model.isChangePasswordDisabled = true;
    this.reload();
  }

  displayEnabledChangePasswordButton() {
    this.props.model.isChangePasswordDisabled = false;
    this.reload();
  }

  displayLoadingState() {
    this.props.model.isChangePasswordLoading = true;
    this.reload();
  }

  displayNotLoadingState() {
    this.props.model.isChangePasswordLoading = false;
    this.reload();
  }

  displaySuccessChangePassword(viewModel: ChangePasswordModels.ChangePasswordSuccess.ViewModel) {
    this.props.model.successMessage = viewModel.text;
    this.reload();
  }

  displayFailureChangePassword(viewModel: ChangePasswordModels.ChangePasswordFailure.ViewModel) {
    this.props.model.errorMessage = viewModel.errorMessage;
    this.reload();
  }

  displayNoErrorMessage() {
    this.props.model.errorMessage = undefined;
    this.reload();
  }

  displayNoSuccessMessage() {
    this.props.model.successMessage = undefined;
    this.reload();
  }
  //#endregion

  //#region Styles
  styles = StyleSheet.create({
    safeAreaView: {
      backgroundColor: ChangePasswordStyle.instance.contentViewModel().backgroundColor,
    },
  });
  //#endregion

  //#region Constraints
  constraints = StyleSheet.create({
    imageBackgroundView: {
      flex: 1,
    },
    safeAreaView: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    containerView: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      alignSelf: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: 500,
      margin: 'auto',
    },
    navigationBarView: {
      position: 'sticky',
      top: 0,
      width: '100%',
    },
    inputText: {
      flex: 1,
      display: 'flex',
      marginBottom: ApplicationConstraints.constant.x16,
    },
    changePasswordButton: {
      flex: 1,
      display: 'flex',
      height: ApplicationConstraints.constant.x40,
      marginBottom: ApplicationConstraints.constant.x8,
    },
  });
  //#endregion
}

export namespace ChangePasswordScene {
  export class Model {
    resetPasswordToken: string | null;

    newPassword?: string;
    newPasswordState: InputTextFieldState = InputTextFieldState.normal;
    newPasswordError?: string = 'Error';

    confirmNewPassword?: string;
    confirmNewPasswordState: InputTextFieldState = InputTextFieldState.normal;
    confirmNewPasswordError?: string = 'Error';

    isChangePasswordLoading: boolean = false;
    isChangePasswordDisabled: boolean = true;

    successMessage?: string;
    errorMessage?: string;

    constructor(resetPasswordToken: string | null) {
      this.resetPasswordToken = resetPasswordToken;
    }
  }

  export interface Props {
    model: Model;
    delegate?: Delegate;
  }

  export interface State {}

  export interface Delegate {}
}
