import {v4 as uuidv4} from 'uuid';
import {AttributedText} from '../../models/attributed_text/AttributedText';
import {OperationError} from '../../operations/base/errors/OperationError';
import {Joke} from '../../models/joke/Joke';
import {User} from '../../models/user/User';
import {ImageTitleButton} from '../../components/buttons/ImageTitleButton';

export namespace JokesModels {
  export class PaginationModel {
    isFetchingItems: boolean = false;
    noMoreItems: boolean = false;
    hasError: boolean = false;
    currentPage: number = 0;
    limit: number = 10;
    items: Joke[] = [];

    isFetchingTopItems: Map<TopItemType, boolean> = new Map();
    topItem: Map<TopItemType, Joke | undefined> = new Map();

    isUpdatingLikeState: Map<string | undefined, boolean> = new Map();

    isFetchingBlockedUsers: boolean = false;
    blockedUsers: User[] = [];

    readJokes: Joke[] = [];

    hasAcceptedPushNotifications: boolean = false;

    reset() {
      this.isFetchingItems = false;
      this.noMoreItems = false;
      this.hasError = false;
      this.currentPage = 0;
      this.limit = 10;
      this.items = [];

      this.isFetchingTopItems = new Map();
      this.topItem = new Map();

      this.isUpdatingLikeState = new Map();

      this.isFetchingBlockedUsers = false;
      this.blockedUsers = [];

      this.readJokes = [];
    }
  }

  export class TopItemModel {
    type: TopItemType;
    item: Joke;

    constructor(type: TopItemType, item: Joke) {
      this.type = type;
      this.item = item;
    }
  }

  export class TopItemType {
    static daily = new TopItemType(0);
    static weekly = new TopItemType(1);
    static monthly = new TopItemType(2);
    static yearly = new TopItemType(3);

    static allCases: TopItemType[] = [this.daily, this.weekly, this.monthly, this.yearly];

    value: number;

    constructor(value: number) {
      this.value = value;
    }

    nextType(): TopItemType | undefined {
      switch (this.value) {
        case TopItemType.daily.value:
          return TopItemType.weekly;
        case TopItemType.weekly.value:
          return TopItemType.monthly;
        case TopItemType.monthly.value:
          return TopItemType.yearly;
        default:
          return undefined;
      }
    }
  }

  export enum ItemType {
    topJokeText,
    topJokeQna,
    jokeText,
    jokeQna,
    shareApp,
    space,
  }

  export class DisplayedItem {
    uuid: string;
    type: ItemType;
    model?: any;

    constructor(type: ItemType, model?: any) {
      this.uuid = uuidv4();
      this.type = type;
      this.model = model;
    }
  }

  export namespace ItemsPresentation {
    export class Response {
      items: Joke[];
      topItemModel?: TopItemModel;
      readJokes: Joke[];
      blockedUsers: User[];
      currentUser?: User;
      hasAcceptedPushNotifications: boolean;

      constructor(items: Joke[], blockedUsers: User[], readJokes: Joke[], hasAcceptedPushNotifications: boolean, topItemModel?: TopItemModel, currentUser?: User) {
        this.items = items;
        this.topItemModel = topItemModel;
        this.readJokes = readJokes;
        this.blockedUsers = blockedUsers;
        this.currentUser = currentUser;
        this.hasAcceptedPushNotifications = hasAcceptedPushNotifications;
      }
    }

    export class ViewModel {
      items: DisplayedItem[];

      constructor(items: DisplayedItem[]) {
        this.items = items;
      }
    }
  }

  export namespace LoadMoreItemsPresentation {
    export class ViewModel {
      imageTitleButton?: ImageTitleButton.Model;

      constructor(imageTitleButton?: ImageTitleButton.Model) {
        this.imageTitleButton = imageTitleButton;
      }
    }
  }

  export namespace NoMoreItemsPresentation {
    export class ViewModel {
      text?: AttributedText;

      constructor(text?: AttributedText) {
        this.text = text;
      }
    }
  }

  export namespace ErrorPresentation {
    export class Response {
      error: OperationError;

      constructor(error: OperationError) {
        this.error = error;
      }
    }

    export class ViewModel {
      errorText?: AttributedText;

      constructor(errorText?: AttributedText) {
        this.errorText = errorText;
      }
    }
  }

  export namespace ActionAlertPresentation {
    export class Response {
      error: OperationError;

      constructor(error: OperationError) {
        this.error = error;
      }
    }

    export class ViewModel {
      title?: string;
      message?: string;

      constructor(title?: string, message?: string) {
        this.title = title;
        this.message = message;
      }
    }
  }

  export namespace ItemSelection {
    export class Request {
      id?: string;

      constructor(id?: string) {
        this.id = id;
      }
    }
  }

  export namespace ItemLoadingState {
    export class Response {
      isLoading: boolean;
      id?: string;

      constructor(isLoading: boolean, id?: string) {
        this.isLoading = isLoading;
        this.id = id;
      }
    }

    export class ViewModel {
      id?: string;
      isLoading: boolean;

      constructor(isLoading: boolean, id?: string) {
        this.isLoading = isLoading;
        this.id = id;
      }
    }
  }

  export namespace ItemState {
    export class Response {
      count?: number;
      isSelected?: boolean;
      id?: string;

      constructor(id?: string, count?: number, isSelected?: boolean) {
        this.count = count;
        this.isSelected = isSelected;
        this.id = id;
      }
    }

    export class ViewModel {
      id?: string;
      backgroundColor: string;
      text?: AttributedText;
      imageTintColor?: string;

      constructor(backgroundColor: string, id?: string, text?: AttributedText, imageTintColor?: string) {
        this.backgroundColor = backgroundColor;
        this.id = id;
        this.text = text;
        this.imageTintColor = imageTintColor;
      }
    }
  }

  export namespace ItemReadState {
    export class Response {
      isRead: boolean;
      id?: string;

      constructor(isRead: boolean, id?: string) {
        this.isRead = isRead;
        this.id = id;
      }
    }

    export class ViewModel {
      isRead: boolean;
      id?: string;

      constructor(isRead: boolean, id?: string) {
        this.isRead = isRead;
        this.id = id;
      }
    }
  }

  export namespace ItemScroll {
    export class Response {
      animated: boolean;
      index: number;

      constructor(animated: boolean, index: number) {
        this.animated = animated;
        this.index = index;
      }
    }

    export class ViewModel {
      animated: boolean;
      index: number;

      constructor(animated: boolean, index: number) {
        this.animated = animated;
        this.index = index;
      }
    }
  }

  export namespace UrlNavigation {
    export class Response {
      url: string;
      constructor(url: string) {
        this.url = url;
      }
    }

    export class ViewModel {
      url: string;
      constructor(url: string) {
        this.url = url;
      }
    }
  }

  export namespace JokeReportsNavigation {
    export class Response {
      id?: string;

      constructor(id?: string) {
        this.id = id;
      }
    }

    export class ViewModel {
      id?: string;

      constructor(id?: string) {
        this.id = id;
      }
    }
  }

  export namespace UserDetailsNavigation {
    export class Request {
      jokeId?: string;

      constructor(jokeId?: string) {
        this.jokeId = jokeId;
      }
    }

    export class Response {
      userId?: string;

      constructor(userId?: string) {
        this.userId = userId;
      }
    }

    export class ViewModel {
      userId?: string;

      constructor(userId?: string) {
        this.userId = userId;
      }
    }
  }

  export namespace ShareJokeNavigation {
    export class Response {
      id: string;

      constructor(id: string) {
        this.id = id;
      }
    }
  }

  export namespace ShareNavigation {
    export class ViewModel {
      title: string;
      url: string;

      constructor(title: string, url: string) {
        this.title = title;
        this.url = url;
      }
    }
  }
}
