import React from 'react';
import {AttributedText} from '../../models/attributed_text/AttributedText';

export default class Text extends React.Component<Text.Props, Text.State> {
  render() {
    return <span style={{...this.props.style, ...this.props.attributedText?.style}}>{this.props.attributedText?.text}</span>;
  }
}

export namespace Text {
  export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    attributedText: AttributedText | undefined;
  }

  export interface State {}
}
