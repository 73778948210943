import {OperationError} from '../../operations/base/errors/OperationError';
import {JokesModels} from './JokesModels';
import {JokesPresentationLogic} from './JokesPresenter';
import {JokesWorker, JokesWorkerDelegate} from './JokesWorker';
import {Joke} from '../../models/joke/Joke';
import {User} from '../../models/user/User';
import {endOfDay, endOfMonth, endOfWeek, endOfYear, format, startOfDay, startOfMonth, startOfWeek, startOfYear} from 'date-fns';
import {Configuration} from '../../config';

export interface JokesBusinessLogic {
  shouldFetchJokes(): void;

  shouldRefreshDetails(): void;

  shouldSelectLike(request: JokesModels.ItemSelection.Request): void;
  shouldSelectDislike(request: JokesModels.ItemSelection.Request): void;
  shouldSelectComment(request: JokesModels.ItemSelection.Request): void;
  shouldSelectShare(request: JokesModels.ItemSelection.Request): void;
  shouldSelectReadAnswer(request: JokesModels.ItemSelection.Request): void;
  shouldSelectShareApp(): void;

  shouldSelectLogo(): void;

  shouldNavigateToUserDetails(request: JokesModels.UserDetailsNavigation.Request): void;
  shouldNavigateToSettingsApplication(): void;

  shouldNavigateToAppleStore(): void;
  shouldNavigateToGooglePlay(): void;
}

export class JokesInteractor implements JokesBusinessLogic, JokesWorkerDelegate {
  presenter?: JokesPresentationLogic;
  worker?: JokesWorker;

  dateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";
  paginationModel: JokesModels.PaginationModel = new JokesModels.PaginationModel();
  user?: User;

  constructor() {
    this.worker = new JokesWorker(this);
    this.user = User.currentUser;
  }

  //#region Fetch jokes
  shouldFetchJokes(): void {
    if (!this.paginationModel.isFetchingItems && !this.paginationModel.noMoreItems) {
      this.paginationModel.isFetchingItems = true;
      this.presenter?.presentLoadingState();
      this.worker?.fetchJokes(this.paginationModel.currentPage, this.paginationModel.limit, Joke.OrderBy.latest.value);
    }
  }

  successDidFetchJokes(jokes: Joke[]): void {
    this.paginationModel.isFetchingItems = false;
    jokes.forEach(element => this.paginationModel.items.push(element));
    this.paginationModel.currentPage += 1;
    this.paginationModel.hasError = false;

    this.presenter?.presentNotLoadingState();
    this.presentItems();
    this.presenter?.presentRemoveError();

    this.shouldVerifyNoMoreItems(jokes.length);
    this.shouldVerifyLoadMoreItems(jokes.length);
  }

  failureDidFetchJokes(error: OperationError): void {
    this.paginationModel.isFetchingItems = false;
    this.paginationModel.hasError = true;
    this.presenter?.presentNotLoadingState();
    this.presenter?.presentError(new JokesModels.ErrorPresentation.Response(error));
  }
  //#endregion

  //#region Top jokes
  shouldFetchTopJokes(type: JokesModels.TopItemType) {
    if (this.paginationModel.isFetchingTopItems.get(type) === undefined || this.paginationModel.isFetchingTopItems.get(type) === false) {
      this.paginationModel.isFetchingTopItems.set(type, true);
      this.presenter?.presentLoadingState();
      this.fetchTopJokes(type);
    }
  }

  fetchTopJokes(type: JokesModels.TopItemType) {
    let date = new Date();
    let page = 0;
    let limit = 1;
    this.worker?.fetchTopJokes(page, limit, Joke.OrderBy.points.value, type, this.startedAtForTopJoke(type, date), this.endedAtForTopJoke(type, date));
  }

  successDidFetchTopJokes(jokes: Joke[], type: JokesModels.TopItemType): void {
    this.paginationModel.isFetchingTopItems.set(type, false);
    this.presenter?.presentNotLoadingState();

    let nextType = type.nextType();
    if (jokes.length > 0) {
      this.paginationModel.topItem.set(type, jokes[0]);
      this.shouldFetchJokes();
    } else if (nextType !== undefined) {
      this.shouldFetchTopJokes(nextType);
    } else {
      this.shouldFetchJokes();
    }
  }

  failureDidFetchTopJokes(error: OperationError, type: JokesModels.TopItemType): void {
    this.paginationModel.isFetchingTopItems.set(type, false);
    this.presenter?.presentNotLoadingState();
    this.presenter?.presentError(new JokesModels.ErrorPresentation.Response(error));
  }

  startedAtForTopJoke(type: JokesModels.TopItemType, date: Date): string | undefined {
    switch (type.value) {
      case JokesModels.TopItemType.daily.value:
        return format(startOfDay(date), this.dateFormat);
      case JokesModels.TopItemType.weekly.value:
        return format(startOfWeek(date), this.dateFormat);
      case JokesModels.TopItemType.monthly.value:
        return format(startOfMonth(date), this.dateFormat);
      case JokesModels.TopItemType.yearly.value:
        return format(startOfYear(date), this.dateFormat);
      default:
        return undefined;
    }
  }

  endedAtForTopJoke(type: JokesModels.TopItemType, date: Date): string | undefined {
    switch (type.value) {
      case JokesModels.TopItemType.daily.value:
        return format(endOfDay(date), this.dateFormat);
      case JokesModels.TopItemType.weekly.value:
        return format(endOfWeek(date), this.dateFormat);
      case JokesModels.TopItemType.monthly.value:
        return format(endOfMonth(date), this.dateFormat);
      case JokesModels.TopItemType.yearly.value:
        return format(endOfYear(date), this.dateFormat);
      default:
        return undefined;
    }
  }
  //#endregion

  //#region Refresh details
  shouldRefreshDetails(): void {
    this.user = User.currentUser;
    this.paginationModel.reset();

    this.presentItems();
    this.presenter?.presentRemoveError();
    this.presenter?.presentRemoveNoMoreItems();
    this.presenter?.presentRemoveLoadMoreItems();

    this.shouldFetchTopJokes(JokesModels.TopItemType.daily);
  }
  //#endregion

  presentItems() {
    this.presenter?.presentItems(
      new JokesModels.ItemsPresentation.Response(this.itemsToPresent(), this.paginationModel.blockedUsers, this.paginationModel.readJokes, this.paginationModel.hasAcceptedPushNotifications, this.topItemToPresent(), this.user),
    );
  }

  itemsToPresent(): Joke[] {
    let types = JokesModels.TopItemType.allCases;
    let topItem: Joke | undefined;
    for (let index = 0; index < types.length; index++) {
      const type = types[index];
      let item = this.paginationModel.topItem.get(type);
      if (this.paginationModel.topItem.has(type) && item !== undefined) {
        topItem = item;
      }
    }
    if (topItem !== undefined) {
      return this.paginationModel.items.filter(element => element.uuid !== topItem?.uuid);
    }
    return this.paginationModel.items;
  }

  topItemToPresent(): JokesModels.TopItemModel | undefined {
    let types = JokesModels.TopItemType.allCases;
    for (let index = 0; index < types.length; index++) {
      const type = types[index];
      let item = this.paginationModel.topItem.get(type);
      if (this.paginationModel.topItem.has(type) && item !== undefined) {
        return new JokesModels.TopItemModel(type, item);
      }
    }
    return undefined;
  }

  shouldVerifyNoMoreItems(count: number) {
    if (count < this.paginationModel.limit) {
      this.paginationModel.noMoreItems = true;
      this.presenter?.presentNoMoreItems();
    }
  }

  shouldVerifyLoadMoreItems(count: number) {
    if (count < this.paginationModel.limit) {
      this.presenter?.presentRemoveLoadMoreItems();
    } else {
      this.presenter?.presentLoadMoreItems();
    }
  }

  joke(id?: string): Joke | undefined {
    if (id === undefined || id === null) {
      return undefined;
    }
    let types = JokesModels.TopItemType.allCases;
    for (let index = 0; index < types.length; index++) {
      const type = types[index];
      let item = this.paginationModel.topItem.get(type);
      if (this.paginationModel.topItem.has(type) && item !== undefined && item.uuid === id) {
        return item;
      }
    }
    return this.paginationModel.items.find(element => element.uuid === id);
  }

  isTopJoke(id?: string): boolean {
    if (id === undefined || id === null) {
      return false;
    }

    let types = JokesModels.TopItemType.allCases;
    for (let index = 0; index < types.length; index++) {
      const type = types[index];
      let item = this.paginationModel.topItem.get(type);
      if (this.paginationModel.topItem.has(type) && item !== undefined && item.uuid === id) {
        return true;
      }
    }
    return false;
  }

  shouldSelectLike(request: JokesModels.ItemSelection.Request): void {}

  shouldSelectDislike(request: JokesModels.ItemSelection.Request): void {}

  shouldSelectComment(_request: JokesModels.ItemSelection.Request): void {
    // TODO: - Add navigation to comments!
  }

  shouldSelectShare(request: JokesModels.ItemSelection.Request): void {
    let joke = this.joke(request.id);
    if (joke === undefined || joke.uuid === undefined) {
      return;
    }
    this.presenter?.presentNavigateToShareJoke(new JokesModels.ShareJokeNavigation.Response(joke.uuid));
  }

  shouldSelectReadAnswer(request: JokesModels.ItemSelection.Request): void {
    let joke = this.joke(request.id);
    if (joke !== undefined) {
      this.paginationModel.readJokes.push(joke);
      this.presenter?.presentReadState(new JokesModels.ItemReadState.Response(true, joke.uuid));
    }
  }

  shouldSelectShareApp(): void {
    this.presenter?.presentNavigateToShareApp();
  }

  isValidUrl(value: string): boolean {
    try {
      return Boolean(new URL(value));
    } catch {
      return false;
    }
  }

  shouldPresentLikeLoadingState(isLoading: boolean, id?: string) {}

  shouldPresentDislikeLoadingState(isLoading: boolean, id?: string) {}

  shouldPresentLikeState(id?: string, count?: number, isSelected?: boolean) {}

  shouldPresentDislikeState(id?: string, count?: number, isSelected?: boolean) {}

  shouldSelectLogo(): void {
    this.presenter?.presentScrollToItem(new JokesModels.ItemScroll.Response(false, 0));
  }

  isUserLoggedIn(): boolean {
    return this.user !== undefined;
  }

  shouldNavigateToUserDetails(request: JokesModels.UserDetailsNavigation.Request): void {}

  shouldNavigateToSettingsApplication(): void {}

  shouldNavigateToAppleStore(): void {
    let url = Configuration.instance.appleStoreUrl();
    this.presenter?.presentNavigateToUrl(new JokesModels.UrlNavigation.Response(url));
  }

  shouldNavigateToGooglePlay(): void {
    let url = Configuration.instance.googlePlayUrl();
    this.presenter?.presentNavigateToUrl(new JokesModels.UrlNavigation.Response(url));
  }
}
