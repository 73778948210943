import React from 'react';
import {SpaceCell} from '../../components/cells/space/SpaceCell';
import {AttributedText} from '../../models/attributed_text/AttributedText';
import {JokeTextCell} from '../../components/cells/joke/JokeTextCell';
import {JokesBusinessLogic, JokesInteractor} from './JokesInteractor';
import {JokesModels} from './JokesModels';
import {JokesPresenter} from './JokesPresenter';
import {JokesRoutingLogic, JokesRouter} from './JokesRouter';
import {JokesStyle} from './JokesStyle';
import {v4 as uuidv4} from 'uuid';
import {ApplicationConstraints} from '../../style/ApplicationConstraints';
import {LogoNavigationView} from '../../components/views/logo_navigation_view/LogoNavigationView';
import View from '../../components/views/view/View';
import Text from '../../components/text/Text';
import {ActivityIndicator} from '../../components/views/activity_indicator/ActivityIndicator';
import TouchableOpacity from '../../components/touchable_opacity/TouchableOpacity';
import {StyleSheet} from '../../models/style_sheet/StyleSheet';
import {JokeQuestionAnswerCell} from '../../components/cells/joke/JokeQuestionAnswerCell';
import {TopJokeTextCell} from '../../components/cells/joke/TopJokeTextCell';
import {TopJokeQuestionAnswerCell} from '../../components/cells/joke/TopJokeQuestionAnswerCell';
import {ShareAppCell} from '../../components/cells/app/ShareAppCell';
import {DownloadAppView} from '../../components/cells/app/DownloadAppView';
import {UserAvatarView} from '../../components/views/user_avatar_view/UserAvatarView';
import {LoadingImageView} from '../../components/views/image/LoadingImage';
import {CompoundImage} from '../../models/image/CompoundImage';
import {ApplicationStyle} from '../../style/ApplicationStyle';
import {ImageTitleButton} from '../../components/buttons/ImageTitleButton';
import {Helmet} from 'react-helmet';
import JokesMetaTagsBuilder from './meta_tags/JokesMetaTagsBuilder';

export interface JokesDisplayLogic {
  displayLoadingState(): void;
  displayNotLoadingState(): void;

  displayItems(viewModel: JokesModels.ItemsPresentation.ViewModel): void;

  displayLoadMoreItems(viewModel: JokesModels.LoadMoreItemsPresentation.ViewModel): void;
  displayRemoveLoadMoreItems(): void;

  displayNoMoreItems(viewModel: JokesModels.NoMoreItemsPresentation.ViewModel): void;
  displayRemoveNoMoreItems(): void;

  displayError(viewModel: JokesModels.ErrorPresentation.ViewModel): void;
  displayRemoveError(): void;

  displayReadState(viewModel: JokesModels.ItemReadState.ViewModel): void;

  displayNavigateToUrl(viewModel: JokesModels.UrlNavigation.ViewModel): void;
  displayNavigateToAuthentication(): void;
  displayNavigateToUserDetails(viewModel: JokesModels.UserDetailsNavigation.ViewModel): void;
  displayNavigateToShare(viewModel: JokesModels.ShareNavigation.ViewModel): void;

  displayScrollToItem(viewModel: JokesModels.ItemScroll.ViewModel): void;
}

export class JokesScene extends React.Component<JokesScene.Props, JokesScene.State> implements JokesDisplayLogic, JokeTextCell.Delegate, TopJokeTextCell.Delegate, LogoNavigationView.Delegate, DownloadAppView.Delegate {
  interactor?: JokesBusinessLogic;
  router?: JokesRoutingLogic;

  navigationView?: LogoNavigationView | null;

  constructor(props: JokesScene.Props) {
    super(props);
    this.setup();
  }

  setup() {
    let interactor = new JokesInteractor();
    let presenter = new JokesPresenter();
    let router = new JokesRouter();
    this.interactor = interactor;
    this.router = router;
    interactor.presenter = presenter;
    presenter.displayer = this;
    router.scene = this;
  }

  componentDidMount() {
    this.interactor?.shouldRefreshDetails();
  }

  shouldRefreshDetails() {
    this.interactor?.shouldRefreshDetails();
  }

  //#region Subviews
  reload() {
    this.setState({});
  }

  render() {
    return (
      <View style={{...this.constraints.safeAreaView, ...this.styles.safeAreaView}}>
        {this.setupMetaTags()}
        {this.setupNavigationBar()}
        {this.setupFlatList()}
        {this.setupFlatListFooterContainer()}
        {this.setupDownloadView()}
      </View>
    );
  }

  setupMetaTags() {
    return <JokesMetaTagsBuilder />;
  }

  setupNavigationBar() {
    if (this.props.model.logoNavigation !== undefined && this.props.model.logoNavigation !== null) {
      return <LogoNavigationView ref={ref => (this.navigationView = ref)} delegate={this} style={this.constraints.navigationView} model={this.props.model.logoNavigation} />;
    } else {
      let model = this.setupLogoNavigationViewModel();
      this.props.model.logoNavigation = model;
      return <LogoNavigationView ref={ref => (this.navigationView = ref)} delegate={this} style={this.constraints.navigationView} model={this.props.model.logoNavigation} />;
    }
  }

  setupLogoNavigationViewModel(): LogoNavigationView.Model {
    let model = new LogoNavigationView.Model(new UserAvatarView.Model(new LoadingImageView.Model(new CompoundImage(ApplicationStyle.images.userAvatarPlaceholderSmallImage), false)));
    model.includeSeparator = true;
    return model;
  }

  logoNavigationViewOnPressBackButton(_view: LogoNavigationView): void {}

  logoNavigationViewOnPressLogoImage(_view: LogoNavigationView): void {
    this.interactor?.shouldSelectLogo();
  }

  logoNavigationViewOnPressUserAvatar(_view: LogoNavigationView): void {
    this.props.delegate?.jokesSceneNavigateToUserProfile(this);
  }

  setupFlatList() {
    return <View style={this.constraints.flatList}>{this.props.model.displayedItems.map(item => this.renderFlatListItem(item))}</View>;
  }

  setupFlatListFooterContainer() {
    return <View style={this.constraints.flatListFooter}>{this.setupFlatListFooter()}</View>;
  }

  setupFlatListFooter() {
    if (this.props.model.isLoading) {
      return this.setupActivityIndicator();
    } else if (this.props.model.noMoreItems) {
      return this.setupNoMoreItemsText();
    } else if (this.props.model.hasError) {
      return this.setupErrorText();
    } else if (this.props.model.loadMoreItems) {
      return this.setupLoadMoreItemsButton();
    }
    return null;
  }

  setupActivityIndicator() {
    return <ActivityIndicator style={this.constraints.activityIndicator} color={JokesStyle.instance.flatListModel().activityIndicatorColor} />;
  }

  setupNoMoreItemsText() {
    return <Text key={uuidv4()} style={{...this.constraints.noMoreItemsText, ...this.props.model.noMoreItemsText?.style}} attributedText={this.props.model.noMoreItemsText} />;
  }

  setupLoadMoreItemsButton() {
    return <ImageTitleButton style={this.constraints.readMore} model={this.props.model.loadMoreItemsImageTitleButton} onPress={() => this.interactor?.shouldFetchJokes()} />;
  }

  setupErrorText() {
    return (
      <TouchableOpacity style={this.constraints.errorText} onPress={() => this.interactor?.shouldRefreshDetails()}>
        <Text key={uuidv4()} style={this.props.model.errorText?.style} attributedText={this.props.model.errorText} />
      </TouchableOpacity>
    );
  }

  setupRefreshControl() {}

  setupDownloadView() {
    return <DownloadAppView delegate={this} style={this.constraints.downloadContainerView} model={new DownloadAppView.Model()} />;
  }

  downloadAppViewOnPressApple(cell: DownloadAppView): void {
    this.interactor?.shouldNavigateToAppleStore();
  }

  downloadAppViewOnPressGoogle(cell: DownloadAppView): void {
    this.interactor?.shouldNavigateToGooglePlay();
  }

  //#endregion

  //#region Cells
  renderFlatListItem(item: JokesModels.DisplayedItem): any {
    switch (item.type) {
      case JokesModels.ItemType.topJokeText:
        return this.setupTopJokeTextCell(item);
      case JokesModels.ItemType.topJokeQna:
        return this.setupTopJokeQnaCell(item);
      case JokesModels.ItemType.jokeText:
        return this.setupJokeTextCell(item);
      case JokesModels.ItemType.jokeQna:
        return this.setupJokeQnaCell(item);
      case JokesModels.ItemType.space:
        return this.setupSpaceCell(item);
      case JokesModels.ItemType.shareApp:
        return this.setupShareAppCell(item);
    }
  }

  setupTopJokeTextCell(item: JokesModels.DisplayedItem) {
    let model = item.model as TopJokeTextCell.Model;
    return <TopJokeTextCell model={model} delegate={this} />;
  }

  topJokeTextCellOnPressLikeCount(_cell: TopJokeTextCell, id?: string): void {
    this.interactor?.shouldSelectLike(new JokesModels.ItemSelection.Request(id));
  }

  topJokeTextCellOnPressDislikeCount(_cell: TopJokeTextCell, id?: string): void {
    this.interactor?.shouldSelectDislike(new JokesModels.ItemSelection.Request(id));
  }

  topJokeTextCellOnPressCommentCount(_cell: TopJokeTextCell, id?: string): void {
    this.interactor?.shouldSelectComment(new JokesModels.ItemSelection.Request(id));
  }

  topJokeTextCellOnPressShare(_cell: TopJokeTextCell, id?: string): void {
    this.interactor?.shouldSelectShare(new JokesModels.ItemSelection.Request(id));
  }

  topJokeTextCellOnPressMore(_cell: TopJokeTextCell, id?: string): void {}

  topJokeTextCellOnPressUserAvatar(_cell: TopJokeTextCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  topJokeTextCellOnPressUserName(_cell: TopJokeTextCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  topJokeTextCellOnPressBlockedContainerView(_cell: TopJokeTextCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  topJokeTextCellOnPressBlockedMore(_cell: TopJokeTextCell, _id?: string): void {}

  setupTopJokeQnaCell(item: JokesModels.DisplayedItem) {
    let model = item.model as TopJokeQuestionAnswerCell.Model;
    return <TopJokeQuestionAnswerCell model={model} delegate={this} />;
  }

  topJokeQuestionAnswerCellOnPressLikeCount(_cell: TopJokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldSelectLike(new JokesModels.ItemSelection.Request(id));
  }

  topJokeQuestionAnswerCellOnPressDislikeCount(_cell: TopJokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldSelectDislike(new JokesModels.ItemSelection.Request(id));
  }

  topJokeQuestionAnswerCellOnPressCommentCount(_cell: TopJokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldSelectComment(new JokesModels.ItemSelection.Request(id));
  }

  topJokeQuestionAnswerCellOnPressShare(_cell: TopJokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldSelectShare(new JokesModels.ItemSelection.Request(id));
  }

  topJokeQuestionAnswerCellOnPressMore(_cell: TopJokeQuestionAnswerCell, id?: string): void {}

  topJokeQuestionAnswerCellOnPressUserAvatar(_cell: TopJokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  topJokeQuestionAnswerCellOnPressUserName(_cell: TopJokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  topJokeQuestionAnswerCellOnPressBlockedContainerView(_cell: TopJokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  topJokeQuestionAnswerCellOnPressBlockedMore(_cell: TopJokeQuestionAnswerCell, _id?: string): void {}

  topJokeQuestionAnswerCellOnPressReadAnswer(_cell: TopJokeQuestionAnswerCell, id?: string | undefined): void {
    this.interactor?.shouldSelectReadAnswer(new JokesModels.ItemSelection.Request(id));
  }

  setupJokeTextCell(item: JokesModels.DisplayedItem) {
    let model = item.model as JokeTextCell.Model;
    return <JokeTextCell model={model} delegate={this} />;
  }

  jokeTextCellOnPressLikeCount(_cell: JokeTextCell, id?: string): void {
    this.interactor?.shouldSelectLike(new JokesModels.ItemSelection.Request(id));
  }

  jokeTextCellOnPressDislikeCount(_cell: JokeTextCell, id?: string): void {
    this.interactor?.shouldSelectDislike(new JokesModels.ItemSelection.Request(id));
  }

  jokeTextCellOnPressCommentCount(_cell: JokeTextCell, id?: string): void {
    this.interactor?.shouldSelectComment(new JokesModels.ItemSelection.Request(id));
  }

  jokeTextCellOnPressShare(_cell: JokeTextCell, id?: string): void {
    this.interactor?.shouldSelectShare(new JokesModels.ItemSelection.Request(id));
  }

  jokeTextCellOnPressMore(_cell: JokeTextCell, id?: string): void {}

  jokeTextCellOnPressUserAvatar(_cell: JokeTextCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  jokeTextCellOnPressUserName(_cell: JokeTextCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  jokeTextCellOnPressBlockedMore(_cell: JokeTextCell, _id?: string): void {}

  jokeTextCellOnPressBlockedContainerView(_cell: JokeTextCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  setupJokeQnaCell(item: JokesModels.DisplayedItem) {
    let model = item.model as JokeQuestionAnswerCell.Model;
    return <JokeQuestionAnswerCell model={model} delegate={this} />;
  }

  jokeQuestionAnswerCellOnPressLikeCount(_cell: JokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldSelectLike(new JokesModels.ItemSelection.Request(id));
  }

  jokeQuestionAnswerCellOnPressDislikeCount(_cell: JokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldSelectDislike(new JokesModels.ItemSelection.Request(id));
  }

  jokeQuestionAnswerCellOnPressCommentCount(_cell: JokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldSelectComment(new JokesModels.ItemSelection.Request(id));
  }

  jokeQuestionAnswerCellOnPressShare(_cell: JokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldSelectShare(new JokesModels.ItemSelection.Request(id));
  }

  jokeQuestionAnswerCellOnPressMore(_cell: JokeQuestionAnswerCell, id?: string): void {}

  jokeQuestionAnswerCellOnPressUserAvatar(_cell: JokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  jokeQuestionAnswerCellOnPressUserName(_cell: JokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  jokeQuestionAnswerCellOnPressBlockedMore(_cell: JokeQuestionAnswerCell, _id?: string): void {}

  jokeQuestionAnswerCellOnPressBlockedContainerView(_cell: JokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldNavigateToUserDetails(new JokesModels.UserDetailsNavigation.Request(id));
  }

  jokeQuestionAnswerCellOnPressReadAnswer(_cell: JokeQuestionAnswerCell, id?: string): void {
    this.interactor?.shouldSelectReadAnswer(new JokesModels.ItemSelection.Request(id));
  }

  setupSpaceCell(item: JokesModels.DisplayedItem) {
    let model = item.model as SpaceCell.Model;
    return <SpaceCell model={model} />;
  }

  setupShareAppCell(item: JokesModels.DisplayedItem) {
    let model = item.model as ShareAppCell.Model;
    return <ShareAppCell model={model} delegate={this} />;
  }

  shareAppCellOnPressShare(_cell: ShareAppCell): void {
    this.interactor?.shouldSelectShareApp();
  }
  //#endregion

  //#region Display logic
  displayLoadingState() {
    this.props.model.isLoading = true;
    this.reload();
  }

  displayNotLoadingState() {
    this.props.model.isLoading = false;
    this.reload();
  }

  displayItems(viewModel: JokesModels.ItemsPresentation.ViewModel) {
    this.props.model.displayedItems = viewModel.items;
    this.reload();
  }

  displayLoadMoreItems(viewModel: JokesModels.LoadMoreItemsPresentation.ViewModel): void {
    this.props.model.loadMoreItems = true;
    this.props.model.loadMoreItemsImageTitleButton = viewModel.imageTitleButton;
    this.reload();
  }

  displayRemoveLoadMoreItems(): void {
    this.props.model.loadMoreItems = false;
    this.props.model.loadMoreItemsImageTitleButton = undefined;
    this.reload();
  }

  displayNoMoreItems(viewModel: JokesModels.NoMoreItemsPresentation.ViewModel) {
    this.props.model.noMoreItems = true;
    this.props.model.noMoreItemsText = viewModel.text;
    this.reload();
  }

  displayRemoveNoMoreItems() {
    this.props.model.noMoreItems = false;
    this.props.model.noMoreItemsText = undefined;
    this.reload();
  }

  displayError(viewModel: JokesModels.ErrorPresentation.ViewModel) {
    this.props.model.hasError = true;
    this.props.model.errorText = viewModel.errorText;
    this.reload();
  }

  displayRemoveError() {
    this.props.model.hasError = false;
    this.props.model.errorText = undefined;
    this.reload();
  }

  displayReadState(viewModel: JokesModels.ItemReadState.ViewModel): void {
    let topJokeQnaModel = this.displayedTopJokeQnaModel(JokesModels.ItemType.topJokeQna, viewModel.id);
    let jokeQnaModel = this.displayedJokeQnaModel(JokesModels.ItemType.jokeQna, viewModel.id);
    if (topJokeQnaModel !== undefined) {
      topJokeQnaModel.isRead = viewModel.isRead;
      topJokeQnaModel.cellInterface?.reload();
    } else if (jokeQnaModel !== undefined) {
      jokeQnaModel.isRead = viewModel.isRead;
      jokeQnaModel.cellInterface?.reload();
    }
  }

  displayNavigateToUrl(viewModel: JokesModels.UrlNavigation.ViewModel): void {
    this.router?.navigateToUrl(viewModel.url);
  }

  displayNavigateToAuthentication(): void {
    this.props.delegate?.jokesSceneNavigateToAuthentication(this);
  }

  displayNavigateToUserDetails(viewModel: JokesModels.UserDetailsNavigation.ViewModel): void {
    this.props.delegate?.jokesSceneNavigateToUserDetails(this, viewModel.userId);
  }

  displayNavigateToShare(viewModel: JokesModels.ShareNavigation.ViewModel): void {
    // this.router?.navigateToShare(viewModel.title, viewModel.url);
  }

  displayScrollToItem(viewModel: JokesModels.ItemScroll.ViewModel) {}

  //#endregion

  //#region Auxiliary
  displayedTopJokeTextModel(type: JokesModels.ItemType, id?: string): TopJokeTextCell.Model | undefined {
    for (let index = 0; index < this.props.model.displayedItems.length; index++) {
      let item = this.props.model.displayedItems[index];
      if (item.type === type && item.model instanceof TopJokeTextCell.Model) {
        let model = item.model as TopJokeTextCell.Model | undefined;
        if (model !== undefined && model.id === id) {
          return model;
        }
      }
    }
    return undefined;
  }

  displayedTopJokeQnaModel(type: JokesModels.ItemType, id?: string): TopJokeQuestionAnswerCell.Model | undefined {
    for (let index = 0; index < this.props.model.displayedItems.length; index++) {
      let item = this.props.model.displayedItems[index];
      if (item.type === type && item.model instanceof TopJokeQuestionAnswerCell.Model) {
        let model = item.model as TopJokeQuestionAnswerCell.Model | undefined;
        if (model !== undefined && model.id === id) {
          return model;
        }
      }
    }
    return undefined;
  }

  displayedJokeTextModel(type: JokesModels.ItemType, id?: string): JokeTextCell.Model | undefined {
    for (let index = 0; index < this.props.model.displayedItems.length; index++) {
      let item = this.props.model.displayedItems[index];
      if (item.type === type && item.model instanceof JokeTextCell.Model) {
        let model = item.model as JokeTextCell.Model | undefined;
        if (model !== undefined && model.id === id) {
          return model;
        }
      }
    }
    return undefined;
  }

  displayedJokeQnaModel(type: JokesModels.ItemType, id?: string): JokeQuestionAnswerCell.Model | undefined {
    for (let index = 0; index < this.props.model.displayedItems.length; index++) {
      let item = this.props.model.displayedItems[index];
      if (item.type === type && item.model instanceof JokeQuestionAnswerCell.Model) {
        let model = item.model as JokeQuestionAnswerCell.Model | undefined;
        if (model !== undefined && model.id === id) {
          return model;
        }
      }
    }
    return undefined;
  }
  //#endregion

  //#region Styles
  styles = StyleSheet.create({
    safeAreaView: {
      backgroundColor: JokesStyle.instance.contentViewModel().backgroundColor,
    },
    flatList: {
      backgroundColor: JokesStyle.instance.flatListModel().backgroundColor,
    },
    loadMore: {},
  });
  //#endregion

  //#region Constraints
  constraints = StyleSheet.create({
    imageBackgroundView: {
      flex: 1,
    },
    safeAreaView: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    navigationView: {
      position: 'sticky',
      top: 0,
      width: '100%',
    },
    flatList: {
      width: '100%',
      maxWidth: 800,
      margin: 'auto',
    },
    flatListFooter: {
      width: '100%',
      maxWidth: 800,
      margin: 'auto',
      paddingBottom: ApplicationConstraints.constant.x120,
    },
    activityIndicator: {
      margin: ApplicationConstraints.constant.x8,
    },
    noMoreItemsText: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      margin: ApplicationConstraints.constant.x16,
    },
    errorText: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      margin: ApplicationConstraints.constant.x16,
    },
    readMore: {
      height: ApplicationConstraints.constant.x32,
      margin: ApplicationConstraints.constant.x16,
    },
    downloadContainerView: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      height: ApplicationConstraints.constant.x120,
    },
  });
  //#endregion
}

export namespace JokesScene {
  export class Model {
    logoNavigation?: LogoNavigationView.Model;

    displayedItems: JokesModels.DisplayedItem[] = [];
    isLoading: boolean = false;
    hasError: boolean = false;
    errorText?: AttributedText;
    noMoreItems: boolean = false;
    noMoreItemsText?: AttributedText;
    loadMoreItems: boolean = false;
    loadMoreItemsImageTitleButton?: ImageTitleButton.Model;
  }

  export interface Props {
    model: Model;
    delegate?: Delegate;
  }

  export interface State {}

  export interface Delegate {
    jokesSceneNavigateToUserProfile(scene: JokesScene): void;
    jokesSceneNavigateToAuthentication(scene: JokesScene): void;
    jokesSceneNavigateToUserDetails(scene: JokesScene, userId?: string): void;
  }
}
