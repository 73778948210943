import React from 'react';
import {ViewProps} from '../../../models/style_sheet/StyleSheet';

export class ActivityIndicator extends React.Component<ActivityIndicator.Props, ActivityIndicator.State> {
  render() {
    return <div style={{...this.props.style}} />;
  }
}

export namespace ActivityIndicator {
  export interface Props extends ViewProps {
    animating?: boolean;
    color: string | any;
    delegate?: Delegate;
  }

  export interface Delegate {}

  export interface State {}
}
