import LocalizedStrings from 'react-localization';
import en from './en.json';
import ro from './ro.json';

export const localizedStrings = new LocalizedStrings({
  en: en,
  ro: ro,
});

export class LocalizationManager {
  static instance = new LocalizationManager();

  defaultLanguage = 'en';

  preferredLocalization(): string {
    let currentDeviceInterfaceLanguage = localizedStrings.getInterfaceLanguage();
    if (currentDeviceInterfaceLanguage === 'ro') {
      return 'ro';
    }

    return this.defaultLanguage;
  }

  preferredLocalizations(): string[] {
    return localizedStrings.getAvailableLanguages();
  }

  setLanguage(language: string) {
    localizedStrings.setLanguage(language);
  }
}
