import React from 'react';
import {JokeDetailsBusinessLogic, JokeDetailsInteractor} from './JokeDetailsInteractor';
import {JokeDetailsModels} from './JokeDetailsModels';
import {JokeDetailsPresenter} from './JokeDetailsPresenter';
import {JokeDetailsRoutingLogic, JokeDetailsRouter} from './JokeDetailsRouter';
import {JokeDetailsStyle} from './JokeDetailsStyle';
import {ApplicationConstraints} from '../../style/ApplicationConstraints';
import {SpaceCell} from '../../components/cells/space/SpaceCell';
import {LogoNavigationView} from '../../components/views/logo_navigation_view/LogoNavigationView';
import {UserAvatarView} from '../../components/views/user_avatar_view/UserAvatarView';
import {CompoundImage} from '../../models/image/CompoundImage';
import {JokeTextCell} from '../../components/cells/joke/JokeTextCell';
import {JokeQuestionAnswerCell} from '../../components/cells/joke/JokeQuestionAnswerCell';
import View from '../../components/views/view/View';
import {LoadingImageView} from '../../components/views/image/LoadingImage';
import {ActivityIndicator} from '../../components/views/activity_indicator/ActivityIndicator';
import {StyleSheet} from '../../models/style_sheet/StyleSheet';
import {DownloadAppView} from '../../components/cells/app/DownloadAppView';
import {Helmet} from 'react-helmet';
import JokeDetailsMetaTagsBuilder from './meta_tags/JokeDetailsMetaTagsBuilder';

export interface JokeDetailsDisplayLogic {
  displayLoadingState(): void;
  displayNotLoadingState(): void;

  displayItems(viewModel: JokeDetailsModels.ItemsPresentation.ViewModel): void;

  displayErrorActionAlert(viewModel: JokeDetailsModels.ActionAlertPresentation.ViewModel): void;

  displayNavigateToUrl(viewModel: JokeDetailsModels.UrlNavigation.ViewModel): void;
  displayNavigateToJokeReports(viewModel: JokeDetailsModels.JokeReportsNavigation.ViewModel): void;
  displayNavigateToShareJoke(viewModel: JokeDetailsModels.ShareJokeNavigation.ViewModel): void;

  displayDismissScene(): void;

  displayLikeLoadingState(viewModel: JokeDetailsModels.ItemLoadingState.ViewModel): void;
  displayDislikeLoadingState(viewModel: JokeDetailsModels.ItemLoadingState.ViewModel): void;

  displayLikeState(viewModel: JokeDetailsModels.ItemState.ViewModel): void;
  displayDislikeState(viewModel: JokeDetailsModels.ItemState.ViewModel): void;

  displayReadState(viewModel: JokeDetailsModels.ItemReadState.ViewModel): void;

  displayJokeAddLikeSuccessfully(viewModel: JokeDetailsModels.JokeDetails.ViewModel): void;
  displayJokeDeleteLikeSuccessfully(viewModel: JokeDetailsModels.JokeDetails.ViewModel): void;

  displayJokeAddDislikeSuccessfully(viewModel: JokeDetailsModels.JokeDetails.ViewModel): void;
  displayJokeDeleteDislikeSuccessfully(viewModel: JokeDetailsModels.JokeDetails.ViewModel): void;

  displayJokeUpdateLikeSuccessfully(viewModel: JokeDetailsModels.JokeDetails.ViewModel): void;

  displayDismissActionSheetScene(): void;
}

export class JokeDetailsScene extends React.Component<JokeDetailsScene.Props, JokeDetailsScene.State> implements JokeDetailsDisplayLogic, JokeTextCell.Delegate, JokeQuestionAnswerCell.Delegate {
  interactor?: JokeDetailsBusinessLogic;
  router?: JokeDetailsRoutingLogic;

  constructor(props: JokeDetailsScene.Props) {
    super(props);
    this.setup();
  }

  setup() {
    let interactor = new JokeDetailsInteractor();
    let presenter = new JokeDetailsPresenter();
    let router = new JokeDetailsRouter();
    this.interactor = interactor;
    this.router = router;
    interactor.presenter = presenter;
    presenter.displayer = this;
    router.scene = this;
  }

  componentDidMount() {
    this.interactor?.shouldResetDetails();
    this.interactor?.shouldSetupJokeDetails(new JokeDetailsModels.JokeDetails.Request(this.props.id));
    this.interactor?.shouldFetchJokeDetails();
  }

  //#region Subviews
  reload() {
    this.setState({});
  }

  render() {
    return (
      <View style={{...this.constraints.safeAreaView, ...this.styles.safeAreaView}}>
        {this.setupMetaTags()}
        {this.setupNavigationBar()}
        {this.setupFlatList()}
        {this.setupDownloadView()}
      </View>
    );
  }

  setupMetaTags() {
    return <JokeDetailsMetaTagsBuilder id={this.props.id} />;
  }

  setupNavigationBar() {
    let model = new LogoNavigationView.Model(new UserAvatarView.Model(new LoadingImageView.Model(new CompoundImage(undefined), false)));
    model.includeBack = false;
    model.includeSeparator = true;
    model.includeUserAvatar = false;
    return <LogoNavigationView delegate={this} style={this.constraints.navigationView} model={model} />;
  }

  logoNavigationViewOnPressBackButton(_view: LogoNavigationView): void {
    this.router?.dismissScene();
  }

  logoNavigationViewOnPressLogoImage(_view: LogoNavigationView): void {}

  logoNavigationViewOnPressUserAvatar(_view: LogoNavigationView): void {}

  setupFlatList() {
    return <View style={this.constraints.flatList}>{this.props.model.displayedItems.map(item => this.renderFlatListItem(item))}</View>;
  }

  setupActivityIndicator() {
    return <ActivityIndicator style={this.constraints.activityIndicator} color={JokeDetailsStyle.instance.flatListModel().activityIndicatorColor} />;
  }

  setupDownloadView() {
    return <DownloadAppView delegate={this} style={this.constraints.downloadContainerView} model={new DownloadAppView.Model()} />;
  }

  downloadAppViewOnPressApple(cell: DownloadAppView): void {
    this.interactor?.shouldNavigateToAppleStore();
  }

  downloadAppViewOnPressGoogle(cell: DownloadAppView): void {
    this.interactor?.shouldNavigateToGooglePlay();
  }
  //#endregion

  //#region Cells
  renderFlatListItem(item: JokeDetailsModels.DisplayedItem) {
    switch (item.type) {
      case JokeDetailsModels.ItemType.jokeText:
        return this.setupJokeTextCell(item);
      case JokeDetailsModels.ItemType.jokeQna:
        return this.setupJokeQnaCell(item);
      case JokeDetailsModels.ItemType.space:
        return this.setupSpaceCell(item);
    }
  }

  setupJokeTextCell(item: JokeDetailsModels.DisplayedItem) {
    let model = item.model as JokeTextCell.Model;
    return <JokeTextCell model={model} delegate={this} />;
  }

  jokeTextCellOnPressCommentCount(_cell: JokeTextCell, _id?: string): void {
    // TODO: - Add logic in the future!
  }

  jokeTextCellOnPressLikeCount(_cell: JokeTextCell, _id?: string): void {
    this.interactor?.shouldSelectLike();
  }

  jokeTextCellOnPressDislikeCount(_cell: JokeTextCell, _id?: string): void {
    this.interactor?.shouldSelectDislike();
  }

  jokeTextCellOnPressShare(_cell: JokeTextCell, _id?: string): void {
    this.interactor?.shouldSelectShare();
  }

  jokeTextCellOnPressMore(_cell: JokeTextCell, _id?: string): void {
    this.interactor?.shouldSelectMore();
  }

  jokeTextCellOnPressUserAvatar(_cell: JokeTextCell, _id?: string | undefined): void {}

  jokeTextCellOnPressUserName(_cell: JokeTextCell, _id?: string | undefined): void {}

  jokeTextCellOnPressBlockedContainerView(_cell: JokeTextCell, _id?: string): void {}

  jokeTextCellOnPressBlockedMore(_cell: JokeTextCell, _id?: string): void {}

  setupJokeQnaCell(item: JokeDetailsModels.DisplayedItem) {
    let model = item.model as JokeQuestionAnswerCell.Model;
    return <JokeQuestionAnswerCell model={model} delegate={this} />;
  }

  jokeQuestionAnswerCellOnPressCommentCount(_cell: JokeQuestionAnswerCell, _id?: string): void {
    // TODO: - Add logic in the future!
  }

  jokeQuestionAnswerCellOnPressLikeCount(_cell: JokeQuestionAnswerCell, _id?: string): void {
    this.interactor?.shouldSelectLike();
  }

  jokeQuestionAnswerCellOnPressDislikeCount(_cell: JokeQuestionAnswerCell, _id?: string): void {
    this.interactor?.shouldSelectDislike();
  }

  jokeQuestionAnswerCellOnPressShare(_cell: JokeQuestionAnswerCell, _id?: string): void {
    this.interactor?.shouldSelectShare();
  }

  jokeQuestionAnswerCellOnPressMore(_cell: JokeQuestionAnswerCell, _id?: string): void {
    this.interactor?.shouldSelectMore();
  }

  jokeQuestionAnswerCellOnPressUserAvatar(_cell: JokeQuestionAnswerCell, _id?: string | undefined): void {}

  jokeQuestionAnswerCellOnPressUserName(_cell: JokeQuestionAnswerCell, _id?: string | undefined): void {}

  jokeQuestionAnswerCellOnPressBlockedContainerView(_cell: JokeQuestionAnswerCell, _id?: string): void {}

  jokeQuestionAnswerCellOnPressBlockedMore(_cell: JokeQuestionAnswerCell, _id?: string): void {}

  jokeQuestionAnswerCellOnPressReadAnswer(_cell: JokeQuestionAnswerCell, _id?: string): void {
    this.interactor?.shouldSelectReadAnswer();
  }

  setupSpaceCell(item: JokeDetailsModels.DisplayedItem) {
    let model = item.model as SpaceCell.Model;
    return <SpaceCell model={model} />;
  }
  //#endregion

  //#region Display logic
  displayLoadingState() {
    this.props.model.isLoading = true;
    this.reload();
  }

  displayNotLoadingState() {
    this.props.model.isLoading = false;
    this.reload();
  }

  displayItems(viewModel: JokeDetailsModels.ItemsPresentation.ViewModel) {
    this.props.model.displayedItems = viewModel.items;
    this.reload();
  }

  displayErrorActionAlert(viewModel: JokeDetailsModels.ActionAlertPresentation.ViewModel): void {
    this.router?.showActionAlertScene(viewModel.title, viewModel.message);
  }

  displayNavigateToUrl(viewModel: JokeDetailsModels.UrlNavigation.ViewModel): void {
    this.router?.navigateToUrl(viewModel.url);
  }

  displayNavigateToJokeReports(viewModel: JokeDetailsModels.JokeReportsNavigation.ViewModel): void {
    this.router?.navigateToJokeReports(viewModel.id);
  }

  displayNavigateToShareJoke(viewModel: JokeDetailsModels.ShareJokeNavigation.ViewModel): void {
    this.router?.navigateToShare(viewModel.title, viewModel.url);
  }

  displayDismissScene(): void {
    this.router?.dismissScene();
  }

  displayLikeLoadingState(viewModel: JokeDetailsModels.ItemLoadingState.ViewModel) {
    let jokeTextModel = this.displayedJokeTextModel(JokeDetailsModels.ItemType.jokeText, viewModel.id);
    let jokeQnaModel = this.displayedJokeQnaModel(JokeDetailsModels.ItemType.jokeQna, viewModel.id);

    if (jokeTextModel !== undefined && jokeTextModel.likeCount !== undefined) {
      jokeTextModel.likeCount.isLoading = viewModel.isLoading;
      jokeTextModel.likeCount.viewInterface?.reload();
    } else if (jokeQnaModel !== undefined && jokeQnaModel.likeCount !== undefined) {
      jokeQnaModel.likeCount.isLoading = viewModel.isLoading;
      jokeQnaModel.likeCount.viewInterface?.reload();
    }
  }

  displayDislikeLoadingState(viewModel: JokeDetailsModels.ItemLoadingState.ViewModel) {
    let jokeTextModel = this.displayedJokeTextModel(JokeDetailsModels.ItemType.jokeText, viewModel.id);
    let jokeQnaModel = this.displayedJokeQnaModel(JokeDetailsModels.ItemType.jokeQna, viewModel.id);

    if (jokeTextModel !== undefined && jokeTextModel.dislikeCount !== undefined) {
      jokeTextModel.dislikeCount.isLoading = viewModel.isLoading;
      jokeTextModel.dislikeCount.viewInterface?.reload();
    } else if (jokeQnaModel !== undefined && jokeQnaModel.dislikeCount !== undefined) {
      jokeQnaModel.dislikeCount.isLoading = viewModel.isLoading;
      jokeQnaModel.dislikeCount.viewInterface?.reload();
    }
  }

  displayLikeState(viewModel: JokeDetailsModels.ItemState.ViewModel) {
    let jokeTextModel = this.displayedJokeTextModel(JokeDetailsModels.ItemType.jokeText, viewModel.id);
    let jokeQnaModel = this.displayedJokeQnaModel(JokeDetailsModels.ItemType.jokeQna, viewModel.id);

    if (jokeTextModel !== undefined && jokeTextModel.likeCount !== undefined) {
      jokeTextModel.likeCount.title = viewModel.text;
      jokeTextModel.likeCount.backgroundColor = viewModel.backgroundColor;
      jokeTextModel.likeCount.imageTintColor = viewModel.imageTintColor;
      jokeTextModel.likeCount.viewInterface?.reload();
    } else if (jokeQnaModel !== undefined && jokeQnaModel.likeCount !== undefined) {
      jokeQnaModel.likeCount.title = viewModel.text;
      jokeQnaModel.likeCount.backgroundColor = viewModel.backgroundColor;
      jokeQnaModel.likeCount.imageTintColor = viewModel.imageTintColor;
      jokeQnaModel.likeCount.viewInterface?.reload();
    }
  }

  displayDislikeState(viewModel: JokeDetailsModels.ItemState.ViewModel) {
    let jokeTextModel = this.displayedJokeTextModel(JokeDetailsModels.ItemType.jokeText, viewModel.id);
    let jokeQnaModel = this.displayedJokeQnaModel(JokeDetailsModels.ItemType.jokeQna, viewModel.id);

    if (jokeTextModel !== undefined && jokeTextModel.dislikeCount !== undefined) {
      jokeTextModel.dislikeCount.title = viewModel.text;
      jokeTextModel.dislikeCount.backgroundColor = viewModel.backgroundColor;
      jokeTextModel.dislikeCount.imageTintColor = viewModel.imageTintColor;
      jokeTextModel.dislikeCount.viewInterface?.reload();
    } else if (jokeQnaModel !== undefined && jokeQnaModel.dislikeCount !== undefined) {
      jokeQnaModel.dislikeCount.title = viewModel.text;
      jokeQnaModel.dislikeCount.backgroundColor = viewModel.backgroundColor;
      jokeQnaModel.dislikeCount.imageTintColor = viewModel.imageTintColor;
      jokeQnaModel.dislikeCount.viewInterface?.reload();
    }
  }

  displayReadState(viewModel: JokeDetailsModels.ItemReadState.ViewModel): void {
    let jokeQnaModel = this.displayedJokeQnaModel(JokeDetailsModels.ItemType.jokeQna, viewModel.id);
    if (jokeQnaModel !== undefined) {
      jokeQnaModel.isRead = viewModel.isRead;
      jokeQnaModel.cellInterface?.reload();
    }
  }

  displayJokeAddLikeSuccessfully(viewModel: JokeDetailsModels.JokeDetails.ViewModel): void {
    this.props.delegate?.jokeDetailsSceneDidAddJokeLike(this, viewModel.jokeId);
  }

  displayJokeDeleteLikeSuccessfully(viewModel: JokeDetailsModels.JokeDetails.ViewModel): void {
    this.props.delegate?.jokeDetailsSceneDidDeleteJokeLike(this, viewModel.jokeId);
  }

  displayJokeAddDislikeSuccessfully(viewModel: JokeDetailsModels.JokeDetails.ViewModel): void {
    this.props.delegate?.jokeDetailsSceneDidAddJokeDislike(this, viewModel.jokeId);
  }

  displayJokeDeleteDislikeSuccessfully(viewModel: JokeDetailsModels.JokeDetails.ViewModel): void {
    this.props.delegate?.jokeDetailsSceneDidDeleteJokeDislike(this, viewModel.jokeId);
  }

  displayJokeUpdateLikeSuccessfully(viewModel: JokeDetailsModels.JokeDetails.ViewModel): void {
    this.props.delegate?.jokeDetailsSceneDidUpdateJokeLike(this, viewModel.jokeId);
  }

  displayDismissActionSheetScene(): void {
    this.router?.dismissActionSheetScene();
  }
  //#endregion

  //#region Auxiliary
  displayedJokeTextModel(type: JokeDetailsModels.ItemType, id?: string): JokeTextCell.Model | undefined {
    for (let index = 0; index < this.props.model.displayedItems.length; index++) {
      let item = this.props.model.displayedItems[index];
      if (item.type === type) {
        let model = item.model as JokeTextCell.Model | undefined;
        if (model !== undefined && model.id === id) {
          return model;
        }
      }
    }
    return undefined;
  }

  displayedJokeQnaModel(type: JokeDetailsModels.ItemType, id?: string): JokeQuestionAnswerCell.Model | undefined {
    for (let index = 0; index < this.props.model.displayedItems.length; index++) {
      let item = this.props.model.displayedItems[index];
      if (item.type === type) {
        let model = item.model as JokeQuestionAnswerCell.Model | undefined;
        if (model !== undefined && model.id === id) {
          return model;
        }
      }
    }
    return undefined;
  }
  //#endregion

  //#region Styles
  styles = StyleSheet.create({
    safeAreaView: {
      backgroundColor: JokeDetailsStyle.instance.flatListModel().backgroundColor,
    },
    flatList: {
      backgroundColor: JokeDetailsStyle.instance.flatListModel().backgroundColor,
    },
  });
  //#endregion

  //#region Constraints
  constraints = StyleSheet.create({
    imageBackgroundView: {
      flex: 1,
    },
    safeAreaView: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    navigationView: {
      position: 'sticky',
      top: 0,
      width: '100%',
    },
    containerView: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
    },
    flatList: {
      width: '100%',
      maxWidth: 800,
      margin: 'auto',
    },
    activityIndicator: {
      margin: ApplicationConstraints.constant.x8,
    },
    downloadContainerView: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      height: ApplicationConstraints.constant.x120,
    },
  });
  //#endregion
}

export namespace JokeDetailsScene {
  export class Model {
    displayedItems: JokeDetailsModels.DisplayedItem[] = [];
    isLoading: boolean = false;
    isVisible: boolean = false;

    jokeId?: string;
  }

  export interface Props {
    id?: any | undefined;
    model: Model;
    delegate?: Delegate;
  }

  export interface State {}

  export interface Delegate {
    jokeDetailsSceneShouldDismiss(scene?: JokeDetailsScene): void;

    jokeDetailsSceneDidAddJokeLike(scene?: JokeDetailsScene, jokeId?: string): void;
    jokeDetailsSceneDidDeleteJokeLike(scene?: JokeDetailsScene, jokeId?: string): void;

    jokeDetailsSceneDidAddJokeDislike(scene?: JokeDetailsScene, jokeId?: string): void;
    jokeDetailsSceneDidDeleteJokeDislike(scene?: JokeDetailsScene, jokeId?: string): void;

    jokeDetailsSceneDidUpdateJokeLike(scene?: JokeDetailsScene, jokeId?: string): void;
  }
}
