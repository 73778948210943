import {Expose, Type, plainToInstance} from 'class-transformer';
import {EndpointsBuilder} from '../base/builders/EndpointsBuilder';
import {HTTPMethod, OperationRequestBuilder} from '../base/builders/OperationRequestBuilder';
import {AsynchronousOperation} from '../base/operations/AsynchronousOperation';
import {JSONFileOperation} from '../base/operations/JSONFileOperation';
import {Message} from '../../models/message/Message';

export namespace UpdatePasswordOperationModels {
  export class Request {
    newPassword: string;
    confirmNewPassword: string;

    resetPasswordToken: string;

    constructor(newPassword: string, confirmNewPassword: string, resetPasswordToken: string) {
      this.newPassword = newPassword;
      this.confirmNewPassword = confirmNewPassword;
      this.resetPasswordToken = resetPasswordToken;
    }
  }

  export class BodyContent {
    @Expose({name: 'new_password'})
    newPassword: string;

    @Expose({name: 'confirm_new_password'})
    confirmNewPassword: string;

    @Expose({name: 'reset_password_token'})
    resetPasswordToken: string;

    constructor(newPassword: string, confirmNewPassword: string, resetPasswordToken: string) {
      this.newPassword = newPassword;
      this.confirmNewPassword = confirmNewPassword;
      this.resetPasswordToken = resetPasswordToken;
    }
  }

  export class Response {
    @Expose({name: 'data'})
    @Type(() => MessageModel)
    data?: MessageModel;

    toMessage(): Message | undefined {
      return this.data?.toMessage();
    }
  }

  export class MessageModel {
    @Expose({name: 'message'})
    message?: string;

    toMessage(): Message {
      return new Message(this.message);
    }
  }
}

class UpdatePasswordOperationRequestBuilder extends OperationRequestBuilder<UpdatePasswordOperationModels.Request> {
  url(): string {
    return EndpointsBuilder.instance.updatePasswordEndpoint();
  }

  httpMethod(): string {
    return HTTPMethod.post.toString();
  }

  bodyParameters(): Object | undefined {
    return new UpdatePasswordOperationModels.BodyContent(this.model.newPassword, this.model.confirmNewPassword, this.model.resetPasswordToken);
  }
}

export class UpdatePasswordOperation extends AsynchronousOperation<UpdatePasswordOperationModels.Response> {
  request(): XMLHttpRequest {
    return new UpdatePasswordOperationRequestBuilder(this.model).request();
  }

  parse(body: any): UpdatePasswordOperationModels.Response | undefined {
    let response = plainToInstance(UpdatePasswordOperationModels.Response, body);
    if (response.data !== undefined) {
      return response;
    }
    return undefined;
  }
}

export class UpdatePasswordLocalOperation extends JSONFileOperation<UpdatePasswordOperationModels.Response> {
  delay: number = 500;
  shouldFail: boolean = Math.random() > 0.9;

  json(): any | undefined {
    return require('../../resources/json/update_password_local_operation.json');
  }

  parse(body: any): UpdatePasswordOperationModels.Response | undefined {
    return plainToInstance(UpdatePasswordOperationModels.Response, body);
  }
}
