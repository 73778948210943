import {Configuration} from '../../../config';

export class EndpointsBuilder {
  static instance = new EndpointsBuilder();

  //#region Authentication
  updatePasswordEndpoint() {
    return Configuration.instance.updatePasswordEndpoint();
  }
  //#endregion

  //#region Jokes
  jokesEndpoint() {
    return Configuration.instance.jokesEndpoint();
  }

  jokeEndpoint(jokeId: string) {
    return Configuration.instance.jokesEndpoint() + '/' + jokeId;
  }
  //#endregion
}
