// TODO: - Throw error?

export class Configuration {
  static instance = new Configuration();

  environment(): string {
    return process.env.REACT_APP_ENVIRONMENT ?? '';
  }

  operationLoggingEnabled(): boolean {
    return process.env.REACT_APP_OPERATION_LOGGING_ENABLED === 'false' ? false : true;
  }

  jokesEndpoint(): string {
    return process.env.REACT_APP_JOKES_ENDPOINT ?? '';
  }

  updatePasswordEndpoint(): string {
    return process.env.REACT_APP_UPDATE_PASSWORD_ENDPOINT ?? '';
  }

  appleStoreUrl(): string {
    return process.env.REACT_APP_APPLE_STORE_URL ?? '';
  }

  googlePlayUrl(): string {
    return process.env.REACT_APP_GOOGLE_PLAY_URL ?? '';
  }

  iOSDeepLink(): string {
    return process.env.REACT_APP_IOS_DEEP_LINK ?? '';
  }

  iOSStoreId(): string {
    return process.env.REACT_APP_IOS_STORE_ID ?? '';
  }

  androidDeepLink(): string {
    return process.env.REACT_APP_ANDROID_DEEP_LINK ?? '';
  }

  androidPackage(): string {
    return process.env.REACT_APP_ANDROID_PACKAGE ?? '';
  }
}
