import {ApplicationStyle} from '../../style/ApplicationStyle';
import {ApplicationConstraints} from '../../style/ApplicationConstraints';

export class ChangePasswordStyle {
  static instance = new ChangePasswordStyle();

  contentViewModel(): ChangePasswordStyle.ContentViewModel {
    return new ChangePasswordStyle.ContentViewModel();
  }

  changePasswordButtonModel(): ChangePasswordStyle.ChangePasswordButtonModel {
    return new ChangePasswordStyle.ChangePasswordButtonModel();
  }

  inputTextModel(): ChangePasswordStyle.InputTextModel {
    return new ChangePasswordStyle.InputTextModel();
  }
}

export namespace ChangePasswordStyle {
  export class ContentViewModel {
    backgroundColor: string = ApplicationStyle.colors.transparent;

    successStyle: any = {
      color: ApplicationStyle.colors.primary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
    };

    errorStyle: any = {
      color: ApplicationStyle.colors.secondary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
    };
  }

  export class InputTextModel {
    borderColor: string = ApplicationStyle.colors.primary;
    borderRadius = ApplicationConstraints.constant.x8;
    borderWidth = ApplicationConstraints.constant.x1;

    textStyle: any = {
      color: ApplicationStyle.colors.primary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
    };

    errorStyle: any = {
      color: ApplicationStyle.colors.secondary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
    };
  }

  export class ChangePasswordButtonModel {
    backgroundColor: string = ApplicationStyle.colors.primary;
    activityIndicatorColor: string = ApplicationStyle.colors.primary;
    borderRadius = ApplicationConstraints.constant.x16;

    textStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
      textAlign: 'center',
    };
  }
}
