import React from 'react';

export default class TouchableOpacity extends React.Component<TouchableOpacity.Props, TouchableOpacity.State> {
  render() {
    return (
      <div {...this.props} onClick={() => this.onPress()}>
        {this.props.children}
      </div>
    );
  }

  onPress() {
    if (this.props.disabled) {
      return;
    }
    this.props.onPress && this.props.onPress();
  }
}

export namespace TouchableOpacity {
  export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    disabled?: boolean;
    onPress?: () => void;
  }

  export interface State {}
}
