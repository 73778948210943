import {ImageTitleButton} from '../../components/buttons/ImageTitleButton';
import {SpaceCell} from '../../components/cells/space/SpaceCell';
import {AttributedText} from '../../models/attributed_text/AttributedText';
import {CompoundImage} from '../../models/image/CompoundImage';
import {ApplicationConstraints} from '../../style/ApplicationConstraints';
import {JokeTextCell} from '../../components/cells/joke/JokeTextCell';
import {JokesLocalization} from './JokesLocalization';
import {JokesModels} from './JokesModels';
import {JokesDisplayLogic} from './JokesScene';
import {JokesStyle} from './JokesStyle';
import {formatDistanceToNowStrict, Locale, parseISO} from 'date-fns';
import {ro, enUS} from 'date-fns/locale';
import {UserAvatarView} from '../../components/views/user_avatar_view/UserAvatarView';
import {Joke} from '../../models/joke/Joke';
import {User} from '../../models/user/User';
import {LoadingImageView} from '../../components/views/image/LoadingImage';
import {LocalizationManager} from '../../localization/LocalizationManager';
import {JokeQuestionAnswerCell} from '../../components/cells/joke/JokeQuestionAnswerCell';
import {TopJokeTextCell} from '../../components/cells/joke/TopJokeTextCell';
import {TopJokeQuestionAnswerCell} from '../../components/cells/joke/TopJokeQuestionAnswerCell';
import {ApplicationStyle} from '../../style/ApplicationStyle';
import {ApplicationLocalization} from '../application/ApplicationLocalization';

export interface JokesPresentationLogic {
  presentLoadingState(): void;
  presentNotLoadingState(): void;

  presentItems(response: JokesModels.ItemsPresentation.Response): void;

  presentLoadMoreItems(): void;
  presentRemoveLoadMoreItems(): void;

  presentNoMoreItems(): void;
  presentRemoveNoMoreItems(): void;

  presentError(response: JokesModels.ErrorPresentation.Response): void;
  presentRemoveError(): void;

  presentReadState(response: JokesModels.ItemReadState.Response): void;

  presentNavigateToUrl(response: JokesModels.UrlNavigation.Response): void;
  presentNavigateToUserDetails(response: JokesModels.UserDetailsNavigation.Response): void;
  presentNavigateToShareJoke(response: JokesModels.ShareJokeNavigation.Response): void;
  presentNavigateToShareApp(): void;

  presentScrollToItem(response: JokesModels.ItemScroll.Response): void;
}

export class JokesPresenter implements JokesPresentationLogic {
  displayer?: JokesDisplayLogic;

  presentLoadingState() {
    this.displayer?.displayLoadingState();
  }

  presentNotLoadingState() {
    this.displayer?.displayNotLoadingState();
  }

  presentItems(response: JokesModels.ItemsPresentation.Response) {
    this.displayer?.displayItems(new JokesModels.ItemsPresentation.ViewModel(this.displayedItems(response.items, response.blockedUsers, response.readJokes, response.hasAcceptedPushNotifications, response.topItemModel)));
  }

  presentNoMoreItems() {
    let text = new AttributedText(JokesLocalization.instance.noMoreItemsText(), JokesStyle.instance.flatListModel().noMoreItemsTextStyle);
    this.displayer?.displayNoMoreItems(new JokesModels.NoMoreItemsPresentation.ViewModel(text));
  }

  presentRemoveNoMoreItems() {
    this.displayer?.displayRemoveNoMoreItems();
  }

  presentLoadMoreItems(): void {
    let model = new ImageTitleButton.Model();
    model.isDisabled = false;
    model.backgroundImage = new CompoundImage(undefined, JokesStyle.instance.flatListModel().loadMoreBackground, 'contain');
    model.borderRadius = ApplicationConstraints.constant.x16;
    model.borderColor = ApplicationStyle.colors.white;
    model.backgroundColor = ApplicationStyle.colors.primary;
    model.title = new AttributedText(ApplicationLocalization.instance.loadMoreTitle(), JokesStyle.instance.flatListModel().loadMoreItemsTextStyle);
    this.displayer?.displayLoadMoreItems(new JokesModels.LoadMoreItemsPresentation.ViewModel(model));
  }

  presentRemoveLoadMoreItems(): void {
    this.displayer?.displayRemoveLoadMoreItems();
  }

  presentError(_response: JokesModels.ErrorPresentation.Response) {
    let text = new AttributedText(JokesLocalization.instance.errorText(), JokesStyle.instance.flatListModel().errorTextStyle);
    this.displayer?.displayError(new JokesModels.ErrorPresentation.ViewModel(text));
  }

  presentRemoveError() {
    this.displayer?.displayRemoveError();
  }

  presentReadState(response: JokesModels.ItemReadState.Response): void {
    this.displayer?.displayReadState(new JokesModels.ItemReadState.ViewModel(response.isRead, response.id));
  }

  presentNavigateToUrl(response: JokesModels.UrlNavigation.Response): void {
    this.displayer?.displayNavigateToUrl(new JokesModels.UrlNavigation.ViewModel(response.url));
  }

  presentNavigateToAuthentication(): void {
    this.displayer?.displayNavigateToAuthentication();
  }

  presentNavigateToUserDetails(response: JokesModels.UserDetailsNavigation.Response): void {
    this.displayer?.displayNavigateToUserDetails(new JokesModels.UserDetailsNavigation.ViewModel(response.userId));
  }

  presentNavigateToShareJoke(response: JokesModels.ShareJokeNavigation.Response): void {
    // let title = JokesLocalization.instance.shareJokeMessage();
    // let url = EndpointsBuilder.instance.webJokeEndpoint(response.id);
    // this.displayer?.displayNavigateToShare(new JokesModels.ShareNavigation.ViewModel(title, url));
  }

  presentNavigateToShareApp(): void {
    // let title = JokesLocalization.instance.shareAppMessage();
    // let url = Configuration.instance.webSiteUrl();
    // this.displayer?.displayNavigateToShare(new JokesModels.ShareNavigation.ViewModel(title, url));
  }

  presentScrollToItem(response: JokesModels.ItemScroll.Response): void {
    this.displayer?.displayScrollToItem(new JokesModels.ItemScroll.ViewModel(response.animated, response.index));
  }

  //#region Items
  displayedItems(items: Joke[], blockedUsers: User[], readJokes: Joke[], hasAcceptedPushNotifications: boolean, topItemModel?: JokesModels.TopItemModel): JokesModels.DisplayedItem[] {
    if (topItemModel === undefined && items.length === 0) {
      return [];
    }
    var displayedItems: (JokesModels.DisplayedItem | undefined)[] = [];
    displayedItems.push(this.displayedSpaceItem(ApplicationConstraints.constant.x16));

    if (topItemModel !== undefined) {
      let isBlocked = blockedUsers.some(element => topItemModel.item.user?.uuid === element.uuid);
      let isRead = readJokes.some(element => topItemModel.item.uuid === element.uuid);
      let displayedTopJokeItem: JokesModels.DisplayedItem | undefined = this.displayedTopJokeItem(topItemModel, isBlocked, isRead);

      if (displayedTopJokeItem !== undefined) {
        displayedItems.push(displayedTopJokeItem);
        displayedItems.push(this.displayedSpaceItem(ApplicationConstraints.constant.x16));
      }
    }

    items.forEach((joke, index, array) => {
      let isBlocked = blockedUsers.some(element => joke.user?.uuid === element.uuid);
      let isRead = readJokes.some(element => joke.uuid === element.uuid);
      displayedItems.push(this.displayedJokeItem(joke, isBlocked, isRead));

      if (index !== array.length - 1) {
        displayedItems.push(this.displayedSpaceItem(ApplicationConstraints.constant.x16));
      }
    });
    return displayedItems.filter((item): item is JokesModels.DisplayedItem => !!item);
  }

  displayedSpaceItem(height: number): JokesModels.DisplayedItem {
    return new JokesModels.DisplayedItem(JokesModels.ItemType.space, new SpaceCell.Model(height));
  }

  //#endregion

  //#region Top joke
  displayedTopJokeItem(topItemModel: JokesModels.TopItemModel, isBlocked: boolean, isRead: boolean): JokesModels.DisplayedItem | undefined {
    if (topItemModel.item.type === Joke.Type.qna.value && topItemModel.item.answer !== undefined) {
      return this.displayedTopJokeQnaItem(topItemModel, isBlocked, isRead);
    }
    return this.displayedTopJokeTextItem(topItemModel, isBlocked);
  }

  displayedTopJokeTextItem(topItemModel: JokesModels.TopItemModel, isBlocked: boolean): JokesModels.DisplayedItem | undefined {
    let joke = topItemModel.item;
    if (joke === undefined || joke === null) {
      return undefined;
    }
    let avatar = this.topJokeAvatarViewModel(joke);
    let likeCount = this.topJokeLikeViewModel(joke);
    let dislikeCount = this.topJokeDislikeViewModel(joke);
    let commentCount = this.topJokeCommentViewModel(joke);
    // let share = this.topJokeShareViewModel();

    let model = new TopJokeTextCell.Model(avatar);
    model.id = joke.uuid;
    model.isRanked = true;
    model.rankJoke = new AttributedText(this.topJokeTitle(topItemModel.type), JokesStyle.instance.topJokeCellModel().topJokeTextStyle);
    model.name = new AttributedText(joke.user?.name, JokesStyle.instance.topJokeCellModel().nameStyle);
    model.username = new AttributedText(this.usernameText(joke.user), JokesStyle.instance.topJokeCellModel().usernameStyle);
    model.text = new AttributedText(joke.text, JokesStyle.instance.topJokeCellModel().textStyle);
    model.likeCount = likeCount;
    model.dislikeCount = dislikeCount;
    model.commentCount = commentCount;
    // model.share = share;
    model.hideMore = true;
    model.time = new AttributedText(this.time(joke.createdAt), JokesStyle.instance.topJokeCellModel().timeStyle);
    model.isBlocked = isBlocked;
    model.hideBlockedMore = true;
    return new JokesModels.DisplayedItem(JokesModels.ItemType.topJokeText, model);
  }

  displayedTopJokeQnaItem(topItemModel: JokesModels.TopItemModel, isBlocked: boolean, isRead: boolean): JokesModels.DisplayedItem | undefined {
    let joke = topItemModel.item;
    if (joke === undefined || joke === null) {
      return undefined;
    }
    let avatar = this.topJokeAvatarViewModel(joke);
    let likeCount = this.topJokeLikeViewModel(joke);
    let dislikeCount = this.topJokeDislikeViewModel(joke);
    let commentCount = this.topJokeCommentViewModel(joke);
    // let share = this.topJokeShareViewModel();

    let model = new TopJokeQuestionAnswerCell.Model(avatar);
    model.id = joke.uuid;
    model.isRanked = true;
    model.rankJoke = new AttributedText(this.topJokeTitle(topItemModel.type), JokesStyle.instance.topJokeCellModel().topJokeTextStyle);
    model.name = new AttributedText(joke.user?.name, JokesStyle.instance.topJokeCellModel().nameStyle);
    model.username = new AttributedText(this.usernameText(joke.user), JokesStyle.instance.topJokeCellModel().usernameStyle);
    model.text = new AttributedText(joke.text, JokesStyle.instance.topJokeCellModel().textStyle);
    model.answer = new AttributedText(joke.answer, JokesStyle.instance.topJokeCellModel().answerStyle);
    model.likeCount = likeCount;
    model.dislikeCount = dislikeCount;
    model.commentCount = commentCount;
    // model.share = share;
    model.hideMore = true;
    model.time = new AttributedText(this.time(joke.createdAt), JokesStyle.instance.topJokeCellModel().timeStyle);
    model.isBlocked = isBlocked;
    model.hideBlockedMore = true;
    model.isRead = isRead;
    return new JokesModels.DisplayedItem(JokesModels.ItemType.topJokeQna, model);
  }

  topJokeTitle(type: JokesModels.TopItemType): string | undefined {
    switch (type.value) {
      case JokesModels.TopItemType.daily.value:
        return JokesLocalization.instance.jokeOfTheDayTitle();
      case JokesModels.TopItemType.weekly.value:
        return JokesLocalization.instance.jokeOfTheWeekTitle();
      case JokesModels.TopItemType.monthly.value:
        return JokesLocalization.instance.jokeOfTheMonthTitle();
      case JokesModels.TopItemType.yearly.value:
        return JokesLocalization.instance.jokeOfTheYearTitle();
      default:
        return undefined;
    }
  }

  topJokeAvatarViewModel(joke: Joke): UserAvatarView.Model {
    let loadingImage = new LoadingImageView.Model(this.compoundImage(joke.user?.photo?.url150, JokesStyle.instance.topJokeCellModel().avatarPlaceholder), false);
    loadingImage.activityIndicatorColor = JokesStyle.instance.topJokeCellModel().avatarActivityColor;
    loadingImage.borderTopLeftRadius = JokesStyle.instance.topJokeCellModel().avatarBorderRadius;
    loadingImage.borderTopRightRadius = JokesStyle.instance.topJokeCellModel().avatarBorderRadius;
    loadingImage.borderBottomLeftRadius = JokesStyle.instance.topJokeCellModel().avatarBorderRadius;
    loadingImage.borderBottomRightRadius = JokesStyle.instance.topJokeCellModel().avatarBorderRadius;

    let avatar = new UserAvatarView.Model(loadingImage);
    avatar.isDisabled = false;
    avatar.backgroundColor = JokesStyle.instance.topJokeCellModel().avatarBackgroundColor;
    avatar.borderWidth = JokesStyle.instance.topJokeCellModel().avatarBorderWidth;
    avatar.borderColor = JokesStyle.instance.topJokeCellModel().avatarBorderColor;
    avatar.margin = JokesStyle.instance.topJokeCellModel().avatarMargin;
    return avatar;
  }

  topJokeLikeViewModel(joke: Joke): ImageTitleButton.Model {
    let isLiked = joke.isLiked();
    let likeCount = new ImageTitleButton.Model();
    likeCount.activityIndicatorColor = JokesStyle.instance.topJokeCellModel().likeCountActivityColor;
    likeCount.image = new CompoundImage(undefined, JokesStyle.instance.topJokeCellModel().likeCountImage);
    likeCount.imageTintColor = isLiked ? JokesStyle.instance.topJokeCellModel().selectedLikeCountTintColor : JokesStyle.instance.topJokeCellModel().unselectedLikeCountTintColor;
    likeCount.backgroundColor = isLiked ? JokesStyle.instance.topJokeCellModel().selectedLikeCountBackgroundColor : JokesStyle.instance.topJokeCellModel().unselectedLikeCountBackgroundColor;
    likeCount.title = new AttributedText(joke.likeCount.toString(), isLiked ? JokesStyle.instance.topJokeCellModel().selectedLikeCountStyle : JokesStyle.instance.topJokeCellModel().unselectedLikeCountStyle);
    likeCount.borderRadius = ApplicationConstraints.constant.x12;
    likeCount.isDisabled = false;
    likeCount.isLoading = false;
    return likeCount;
  }

  topJokeDislikeViewModel(joke: Joke): ImageTitleButton.Model {
    let isDisliked = joke.isDisliked();
    let dislikeCount = new ImageTitleButton.Model();
    dislikeCount.activityIndicatorColor = JokesStyle.instance.topJokeCellModel().dislikeCountActivityColor;
    dislikeCount.image = new CompoundImage(undefined, JokesStyle.instance.topJokeCellModel().dislikeCountImage);
    dislikeCount.imageTintColor = isDisliked ? JokesStyle.instance.topJokeCellModel().selectedDislikeCountTintColor : JokesStyle.instance.topJokeCellModel().unselectedDislikeCountTintColor;
    dislikeCount.backgroundColor = isDisliked ? JokesStyle.instance.topJokeCellModel().selectedDislikeCountBackgroundColor : JokesStyle.instance.topJokeCellModel().unselectedDislikeCountBackgroundColor;
    dislikeCount.title = new AttributedText(joke.dislikeCount.toString(), isDisliked ? JokesStyle.instance.topJokeCellModel().selectedDislikeCountStyle : JokesStyle.instance.topJokeCellModel().unselectedDislikeCountStyle);
    dislikeCount.borderRadius = ApplicationConstraints.constant.x12;
    dislikeCount.isDisabled = false;
    dislikeCount.isLoading = false;
    return dislikeCount;
  }

  topJokeCommentViewModel(joke: Joke): ImageTitleButton.Model {
    let commentCount = new ImageTitleButton.Model();
    commentCount.activityIndicatorColor = JokesStyle.instance.topJokeCellModel().commentCountActivityColor;
    commentCount.image = new CompoundImage(undefined, JokesStyle.instance.topJokeCellModel().commentCountImage);
    commentCount.backgroundColor = JokesStyle.instance.topJokeCellModel().commentCountBackgroundColor;
    commentCount.imageTintColor = JokesStyle.instance.topJokeCellModel().commentCountTintColor;
    commentCount.title = new AttributedText(joke.commentCount.toString(), JokesStyle.instance.topJokeCellModel().commentCountStyle);
    commentCount.borderRadius = ApplicationConstraints.constant.x12;
    commentCount.isDisabled = false;
    commentCount.isLoading = false;
    return commentCount;
  }

  topJokeShareViewModel(): ImageTitleButton.Model {
    let shareModel = new ImageTitleButton.Model();
    shareModel.image = new CompoundImage(undefined, JokesStyle.instance.topJokeCellModel().shareImage);
    shareModel.backgroundColor = JokesStyle.instance.topJokeCellModel().shareBackgroundColor;
    shareModel.title = new AttributedText(JokesLocalization.instance.shareTitle(), JokesStyle.instance.topJokeCellModel().shareStyle);
    shareModel.isDisabled = false;
    shareModel.isLoading = false;
    return shareModel;
  }
  //#endregion

  //#region Joke
  displayedJokeItem(joke: Joke, isBlocked: boolean, isRead: boolean): JokesModels.DisplayedItem {
    if (joke.type === Joke.Type.qna.value && joke.answer !== undefined) {
      return this.displayedJokeQnaItem(joke, isBlocked, isRead);
    }
    return this.displayedJokeTextItem(joke, isBlocked);
  }
  //#endregion

  //#region Joke text
  displayedJokeTextItem(joke: Joke, isBlocked: boolean): JokesModels.DisplayedItem {
    let avatar = this.jokeAvatarViewModel(joke);
    let likeCount = this.jokeLikeViewModel(joke);
    let dislikeCount = this.jokeDislikeViewModel(joke);
    let commentCount = this.jokeCommentViewModel(joke);
    // let share = this.jokeShareViewModel();

    let model = new JokeTextCell.Model(avatar);
    model.id = joke.uuid;
    model.name = new AttributedText(joke.user?.name, JokesStyle.instance.jokeCellModel().nameStyle);
    model.username = new AttributedText(this.usernameText(joke.user), JokesStyle.instance.jokeCellModel().usernameStyle);
    model.text = new AttributedText(joke.text, JokesStyle.instance.jokeCellModel().textStyle);
    model.likeCount = likeCount;
    model.dislikeCount = dislikeCount;
    model.commentCount = commentCount;
    // TODO: Add share functionality
    // model.share = share;
    model.hideMore = true;
    model.time = new AttributedText(this.time(joke.createdAt), JokesStyle.instance.jokeCellModel().timeStyle);
    model.isBlocked = isBlocked;
    model.hideBlockedMore = true;
    model.isRanked = false;
    return new JokesModels.DisplayedItem(JokesModels.ItemType.jokeText, model);
  }
  //#endregion

  //#region Joke qna
  displayedJokeQnaItem(joke: Joke, isBlocked: boolean, isRead: boolean): JokesModels.DisplayedItem {
    let avatar = this.jokeAvatarViewModel(joke);
    let likeCount = this.jokeLikeViewModel(joke);
    let dislikeCount = this.jokeDislikeViewModel(joke);
    let commentCount = this.jokeCommentViewModel(joke);
    // let share = this.jokeShareViewModel();

    let model = new JokeQuestionAnswerCell.Model(avatar);
    model.id = joke.uuid;
    model.name = new AttributedText(joke.user?.name, JokesStyle.instance.jokeCellModel().nameStyle);
    model.username = new AttributedText(this.usernameText(joke.user), JokesStyle.instance.jokeCellModel().usernameStyle);
    model.text = new AttributedText(joke.text, JokesStyle.instance.jokeCellModel().textStyle);
    model.answer = new AttributedText(joke.answer, JokesStyle.instance.jokeCellModel().answerStyle);
    model.likeCount = likeCount;
    model.dislikeCount = dislikeCount;
    model.commentCount = commentCount;
    // model.share = share;
    model.hideMore = true;
    model.time = new AttributedText(this.time(joke.createdAt), JokesStyle.instance.jokeCellModel().timeStyle);
    model.isBlocked = isBlocked;
    model.hideBlockedMore = true;
    model.isRanked = false;
    model.isRead = isRead;
    return new JokesModels.DisplayedItem(JokesModels.ItemType.jokeQna, model);
  }
  //#endregion

  jokeAvatarViewModel(joke: Joke): UserAvatarView.Model {
    let loadingImage = new LoadingImageView.Model(this.compoundImage(joke.user?.photo?.url150, JokesStyle.instance.jokeCellModel().avatarPlaceholder), false);
    loadingImage.activityIndicatorColor = JokesStyle.instance.jokeCellModel().avatarActivityColor;
    loadingImage.borderTopLeftRadius = JokesStyle.instance.jokeCellModel().avatarBorderRadius;
    loadingImage.borderTopRightRadius = JokesStyle.instance.jokeCellModel().avatarBorderRadius;
    loadingImage.borderBottomLeftRadius = JokesStyle.instance.jokeCellModel().avatarBorderRadius;
    loadingImage.borderBottomRightRadius = JokesStyle.instance.jokeCellModel().avatarBorderRadius;

    let avatar = new UserAvatarView.Model(loadingImage);
    avatar.isDisabled = false;
    avatar.backgroundColor = JokesStyle.instance.jokeCellModel().avatarBackgroundColor;
    avatar.borderWidth = JokesStyle.instance.jokeCellModel().avatarBorderWidth;
    avatar.borderColor = JokesStyle.instance.jokeCellModel().avatarBorderColor;
    avatar.margin = JokesStyle.instance.jokeCellModel().avatarMargin;
    return avatar;
  }

  jokeLikeViewModel(joke: Joke): ImageTitleButton.Model {
    let isLiked = joke.isLiked();
    let likeCount = new ImageTitleButton.Model();
    likeCount.activityIndicatorColor = JokesStyle.instance.jokeCellModel().likeCountActivityColor;
    likeCount.image = new CompoundImage(undefined, JokesStyle.instance.jokeCellModel().likeCountImage);
    likeCount.imageTintColor = isLiked ? JokesStyle.instance.jokeCellModel().selectedLikeCountTintColor : JokesStyle.instance.jokeCellModel().unselectedLikeCountTintColor;
    likeCount.backgroundColor = isLiked ? JokesStyle.instance.jokeCellModel().selectedLikeCountBackgroundColor : JokesStyle.instance.jokeCellModel().unselectedLikeCountBackgroundColor;
    likeCount.title = new AttributedText(joke.likeCount.toString(), isLiked ? JokesStyle.instance.jokeCellModel().selectedLikeCountStyle : JokesStyle.instance.jokeCellModel().unselectedLikeCountStyle);
    likeCount.borderRadius = ApplicationConstraints.constant.x12;
    likeCount.isDisabled = false;
    likeCount.isLoading = false;
    return likeCount;
  }

  jokeDislikeViewModel(joke: Joke): ImageTitleButton.Model {
    let isDisliked = joke.isDisliked();
    let dislikeCount = new ImageTitleButton.Model();
    dislikeCount.activityIndicatorColor = JokesStyle.instance.jokeCellModel().dislikeCountActivityColor;
    dislikeCount.image = new CompoundImage(undefined, JokesStyle.instance.jokeCellModel().dislikeCountImage);
    dislikeCount.imageTintColor = isDisliked ? JokesStyle.instance.jokeCellModel().selectedDislikeCountTintColor : JokesStyle.instance.jokeCellModel().unselectedDislikeCountTintColor;
    dislikeCount.backgroundColor = isDisliked ? JokesStyle.instance.jokeCellModel().selectedDislikeCountBackgroundColor : JokesStyle.instance.jokeCellModel().unselectedDislikeCountBackgroundColor;
    dislikeCount.title = new AttributedText(joke.dislikeCount.toString(), isDisliked ? JokesStyle.instance.jokeCellModel().selectedDislikeCountStyle : JokesStyle.instance.jokeCellModel().unselectedDislikeCountStyle);
    dislikeCount.borderRadius = ApplicationConstraints.constant.x12;
    dislikeCount.isDisabled = false;
    dislikeCount.isLoading = false;
    return dislikeCount;
  }

  jokeCommentViewModel(joke: Joke): ImageTitleButton.Model {
    let commentCount = new ImageTitleButton.Model();
    commentCount.activityIndicatorColor = JokesStyle.instance.jokeCellModel().commentCountActivityColor;
    commentCount.image = new CompoundImage(undefined, JokesStyle.instance.jokeCellModel().commentCountImage);
    commentCount.imageTintColor = JokesStyle.instance.jokeCellModel().commentCountTintColor;
    commentCount.backgroundColor = JokesStyle.instance.jokeCellModel().commentCountBackgroundColor;
    commentCount.title = new AttributedText(joke.commentCount.toString(), JokesStyle.instance.jokeCellModel().commentCountStyle);
    commentCount.borderRadius = ApplicationConstraints.constant.x12;
    commentCount.isDisabled = false;
    commentCount.isLoading = false;
    return commentCount;
  }

  jokeShareViewModel(): ImageTitleButton.Model {
    let shareModel = new ImageTitleButton.Model();
    shareModel.image = new CompoundImage(undefined, JokesStyle.instance.jokeCellModel().shareImage);
    shareModel.backgroundColor = JokesStyle.instance.jokeCellModel().shareBackgroundColor;
    shareModel.title = new AttributedText(JokesLocalization.instance.shareTitle(), JokesStyle.instance.jokeCellModel().shareStyle);
    shareModel.isDisabled = false;
    shareModel.isLoading = false;
    return shareModel;
  }

  compoundImage(url?: string, placeholder?: any): CompoundImage {
    if (url !== undefined && url !== null && url.length > 0) {
      let image = new CompoundImage(url);
      image.resizeMode = 'cover';
      return image;
    }
    let image = new CompoundImage(undefined, placeholder);
    image.resizeMode = 'cover';
    return image;
  }

  time(createdAt?: string): string | undefined {
    if (createdAt === undefined || createdAt === null || createdAt.length === 0) {
      return undefined;
    }
    let date = parseISO(createdAt);
    let locale = this.locale();
    return formatDistanceToNowStrict(date, {addSuffix: false, roundingMethod: 'floor', locale: locale});
  }

  locale(): Locale | undefined {
    switch (LocalizationManager.instance.preferredLocalization()) {
      case 'en':
        return enUS;
      case 'ro':
        return ro;
      default:
        return undefined;
    }
  }

  usernameText(user?: User): string | undefined {
    let username = user?.username;
    if (username !== undefined) {
      return JokesLocalization.instance.usernameTitle(username);
    }
    return undefined;
  }
}
