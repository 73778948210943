export const ApplicationStyle = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    primary: '#201D2E',
    secondary: '#BC2753',

    lightPrimary: '#6FE9ED',
    lightPrimaryShade15: '#6FE9ED4D',

    lightSecondary: '#E64E7A',
    lightSecondaryShade15: '#E64E7A4D',

    transparent: 'transparent',

    gray: '#697882',
    lightGray: '#E1E8ED',

    backgroundColor: '#FFFFFF',

    gold: '#FFD700',
    silver: '#C0C0C0',
    bronze: '#CD7F32',

    neonDarkGreen: '#20CC00',
    neonOrange: '#FBB23F',
    neonPurple: '#E64E7A',

    facebookBlue: '#1877F2',
    appleBlack: '#000000',
  },

  fonts: {
    regular: 'Helvetica',
    bold: 'Helvetica-Bold',
    boldOblique: 'Helvetica-BoldOblique',
    light: 'Helvetica-Light',
    oblique: 'Helvetica-Oblique',
  },

  images: {
    neonLogoMediumImage: require('../resources/images/neon_medium_logo_image@3x.png'),
    neonLogoLargeImage: require('../resources/images/neon_logo_large_image@3x.png'),
    neonLogoTextLargeImage: require('../resources/images/neon_logo_text_large_image@3x.png'),

    logoMediumImage: require('../resources/images/logo_medium_image@3x.png'),

    neonJokerSelectedSmallImage: require('../resources/images/neon_joker_selected_small_image.png'),
    neonJokerUnselectedSmallImage: require('../resources/images/neon_joker_unselected_small_image.png'),

    moreSmallGrayImage: require('../resources/images/more_small_gray_image.png'),
    moreSmallWhiteImage: require('../resources/images/more_small_white_image.png'),

    navigationBarBackgroundImage: require('../resources/images/navigation_bar_background_image.png'),
    tabBarBackgroundImage: require('../resources/images/tab_bar_background_image.png'),
    buttonBackgroundImage: require('../resources/images/button_wall_background_image.png'),

    sourceSmallImage: require('../resources/images/source_small_image.png'),
    wallBackgroundImage: require('../resources/images/wall_background_image.png'),
    blurredWallImage: require('../resources/images/wall_blur_image.png'),

    addJokeLargeImage: require('../resources/images/add_joke_large_image.png'),
    neonFireMediumImage: require('../resources/images/neon_fire_medium_image.png'),
    neonFireSelectedSmallImage: require('../resources/images/neon_fire_selected_small_image.png'),
    neonFireUnselectedSmallImage: require('../resources/images/neon_fire_unselected_small_image.png'),

    backArrowSmallImage: require('../resources/images/back_arrow_small_image.png'),
    userAvatarPlaceholderSmallImage: require('../resources/images/user_avatar_placeholder_small_image@3x.png'),

    commentSmallImage: require('../resources/images/comment_small_image.png'),
    dislikeSmallImage: require('../resources/images/dislike_small_image@3x.png'),
    likeSmallImage: require('../resources/images/like_small_image@3x.png'),

    settingsIcon: require('../resources/images/settings_white_image.png'),
    cameraLargeImage: require('../resources/images/camera_large_image.png'),

    showPasswordSmallImage: require('../resources/images/show_password_small_image.png'),
    hidePasswordSmallImage: require('../resources/images/hide_password_small_image.png'),

    blockedUserMediumImage: require('../resources/images/blocked_user_medium_image.png'),
    blockedUserLargeImage: require('../resources/images/blocked_user_large_image.png'),

    checkedSmallImage: require('../resources/images/checked_small_image.png'),
    uncheckedSmallImage: require('../resources/images/unchecked_small_image.png'),

    editWhiteSmallImage: require('../resources/images/edit_white_small_image.png'),

    logoutSmallImage: require('../resources/images/logout_primary_small_image.png'),
    notificationSmallImage: require('../resources/images/notification_primary_small_image.png'),
    policySmallImage: require('../resources/images/policy_primary_small_image.png'),
    removeSmallImage: require('../resources/images/remove_account_secondary_small_image.png'),
    shareSmallImage: require('../resources/images/share_secondary_small_image.png'),
    termsSmallImage: require('../resources/images/terms_secondary_small_image.png'),
    rightArrowSmallImage: require('../resources/images/right_arrow_gray_small_image.png'),

    lockSmallWhiteImage: require('../resources/images/lock_small_white_image.png'),
    unlockedSmallWhiteImage: require('../resources/images/unlocked_small_white_image.png'),

    questionMediumImage: require('../resources/images/question_medium_image.png'),
    answerMediumImage: require('../resources/images/answer_medium_image.png'),
    answerSmallImage: require('../resources/images/answer_small_image@3x.png'),

    shareSmallGrayImage: require('../resources/images/share_small_gray_image@3x.png'),
    shareSmallWhiteImage: require('../resources/images/share_small_white_image.png'),

    notificationSmallWhiteImage: require('../resources/images/notification_small_white_image.png'),

    facebookSmallWhiteImage: require('../resources/images/facebook_white_small_image.png'),
    appleSmallWhiteImage: require('../resources/images/apple_white_small_image.png'),

    likeSmallWhiteImage: require('../resources/images/like_small_white_image.png'),
    dislikeSmallWhiteImage: require('../resources/images/dislike_small_white_image.png'),

    likeSmallGrayImage: require('../resources/images/like_small_gray_image.png'),
    dislikeSmallGrayImage: require('../resources/images/dislike_small_gray_image.png'),

    appleMediumImage: require('../resources/images/apple_medium_image.png'),
    googleMediumImage: require('../resources/images/google_play_medium_image.png'),
  },

  imageNames: {
    notificationSmallImage: 'logo_small_white_image',
  },
};
