import React, {Component} from 'react';
import {ApplicationStyle} from '../../style/ApplicationStyle';
import {AttributedText} from '../../models/attributed_text/AttributedText';
import {ApplicationConstraints} from '../../style/ApplicationConstraints';
import {CompoundImage} from '../../models/image/CompoundImage';
import View from '../views/view/View';
import Image from '../views/image/Image';
import {ActivityIndicator} from '../views/activity_indicator/ActivityIndicator';
import {StyleSheet, ViewProps} from '../../models/style_sheet/StyleSheet';
import TouchableOpacity from '../touchable_opacity/TouchableOpacity';
import ImageBackground from '../views/image_background/ImageBackground';

export class ImageTitleButton extends Component<ImageTitleButton.Props> implements ImageTitleButton.ViewInterface {
  constructor(props: ImageTitleButton.Props) {
    super(props);
    if (this.props.model !== undefined) {
      this.props.model.viewInterface = this;
    }
  }

  componentDidUpdate(prevProps: Readonly<ImageTitleButton.Props>, prevState: Readonly<ImageTitleButton.State>, snapshot?: any): void {
    if (this.props.model !== undefined && this.props.model?.viewInterface === undefined) {
      this.props.model.viewInterface = this;
    }
  }

  reload() {
    this.setState({});
  }

  render(): React.ReactNode {
    return <View style={this.props.style}>{this.setupTouchableOpacity()}</View>;
  }

  setupTouchableOpacity() {
    let containerStyle = {
      ...this.constraints.container,
      backgroundColor: this.props.model?.backgroundColor,
      borderRadius: this.props.model?.borderRadius,
      borderColor: this.props.model?.borderColor,
      borderWidth: this.props.model?.borderWidth,
      opacity: this.props.model?.opacity,
      borderStyle: 'solid',
    };

    return (
      <TouchableOpacity style={containerStyle} onPress={() => this.onPress()}>
        {this.setupContainerView()}
      </TouchableOpacity>
    );
  }

  setupContainerView() {
    let backgroundImage = this.props.model?.backgroundImage;
    if (backgroundImage === undefined || backgroundImage === null) {
      return this.setupContentView();
    }
    return this.setupImageBackground();
  }

  setupImageBackground() {
    let backgroundImage = this.props.model?.backgroundImage;
    if (backgroundImage === undefined || backgroundImage === null) {
      return null;
    }

    return (
      <ImageBackground style={this.constraints.imageBackground} src={backgroundImage.image()}>
        {this.setupContentView()}
      </ImageBackground>
    );
  }

  setupContentView() {
    return (
      <View style={this.constraints.contentView}>
        {this.setupImageContainerView()}
        {this.setupActivityIndicator()}
        {this.setupTitle()}
      </View>
    );
  }

  setupImageContainerView() {
    if (this.props.model?.image !== undefined && this.props.model?.image !== null) {
      let imageContainerViewStyle = {
        ...this.constraints.imageContainerView,
        opacity: this.props.model.isLoading ? 0 : 1,
      };
      return <View style={imageContainerViewStyle}>{this.setupImage()}</View>;
    }
    return null;
  }

  setupImage() {
    if (this.props.model?.image !== undefined && this.props.model?.image !== null) {
      let style = {
        ...this.constraints.image,
        tintColor: this.props.model.imageTintColor,
      };
      return <Image style={style} src={this.props.model.image.image()} />;
    }
    return null;
  }

  setupTitle() {
    let style = {
      ...this.constraints.title,
      ...this.props.model?.title?.style,
      opacity: this.props.model?.isLoading ? 0 : 1,
    };
    return <View style={style}>{this.props.model?.title?.text}</View>;
  }

  setupActivityIndicator() {
    let style = {
      ...this.constraints.activityIndicator,
      opacity: this.props.model?.isLoading ? 1 : 0,
    };
    return <ActivityIndicator style={style} color={this.props.model?.activityIndicatorColor} animating={this.props.model?.isLoading} />;
  }

  onPress() {
    if (this.props.onPress === undefined || this.props.onPress === null) {
      return;
    }
    if (this.props.model?.isDisabled === true) {
      return;
    }
    if (this.props.model?.isLoading === true) {
      return;
    }
    this.props.onPress();
  }

  constraints = StyleSheet.create({
    container: {
      height: '100%',
      width: '100%',
    },
    contentView: {
      display: 'flex',
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageBackground: {
      height: '100%',
      width: '100%',
      borderRadius: 'inherit',
    },
    imageContainerView: {
      height: ApplicationConstraints.multiplier.x62,
      aspectRatio: 1,
      marginLeft: ApplicationConstraints.constant.x10,
    },
    image: {
      height: '100%',
      width: '100%',
    },
    title: {
      marginLeft: ApplicationConstraints.constant.x10,
      marginRight: ApplicationConstraints.constant.x10,
    },
    activityIndicator: {},
  });
}

export namespace ImageTitleButton {
  export class Model {
    image?: CompoundImage;
    imageTintColor?: string;
    title?: AttributedText;

    borderRadius = ApplicationConstraints.constant.x4;
    borderWidth = 0.0;
    borderColor = ApplicationStyle.colors.primary;

    activityIndicatorColor = ApplicationStyle.colors.white;
    backgroundColor = ApplicationStyle.colors.primary;

    isLoading = false;
    isDisabled = false;

    opacity = 1.0;

    backgroundImage?: CompoundImage;

    viewInterface?: ViewInterface;
  }

  export interface Props extends ViewProps {
    model?: ImageTitleButton.Model;
    onPress?: () => void;
  }

  export interface State {}

  export interface ViewInterface {
    reload(): void;
  }
}
