import {ChangePasswordModels} from './ChangePasswordModels';
import {ChangePasswordLocalization} from './ChangePasswordLocalization';
import {OperationError} from '../../operations/base/errors/OperationError';
import {ChangePasswordDisplayLogic} from './ChangePasswordScene';
import {InputTextFieldState} from '../../components/input_text_field/InputTextField';

export interface ChangePasswordPresentationLogic {
  presentInvalidNewPassword(): void;
  presentValidNewPassword(): void;

  presentInvalidConfirmNewPassword(): void;
  presentValidConfirmNewPassword(): void;

  presentDisabledChangePasswordButton(): void;
  presentEnabledChangePasswordButton(): void;

  presentLoadingState(): void;
  presentNotLoadingState(): void;

  presentSuccessChangePassword(response: ChangePasswordModels.ChangePasswordSuccess.Response): void;
  presentFailureChangePassword(response: ChangePasswordModels.ChangePasswordFailure.Response): void;

  presentNoErrorMessage(): void;
  presentNoSuccessMessage(): void;
}

export class ChangePasswordPresenter implements ChangePasswordPresentationLogic {
  displayer?: ChangePasswordDisplayLogic;

  presentInvalidNewPassword() {
    let viewModel = new ChangePasswordModels.PasswordValidation.ViewModel(InputTextFieldState.error, ChangePasswordLocalization.instance.newPasswordErrorText());
    this.displayer?.displayNewPasswordInputValidation(viewModel);
  }

  presentValidNewPassword() {
    let viewModel = new ChangePasswordModels.PasswordValidation.ViewModel(InputTextFieldState.normal, undefined);
    this.displayer?.displayNewPasswordInputValidation(viewModel);
  }

  presentInvalidConfirmNewPassword() {
    let viewModel = new ChangePasswordModels.PasswordValidation.ViewModel(InputTextFieldState.error, ChangePasswordLocalization.instance.confirmNewPasswordErrorText());
    this.displayer?.displayConfirmNewPasswordInputValidation(viewModel);
  }

  presentValidConfirmNewPassword() {
    let viewModel = new ChangePasswordModels.PasswordValidation.ViewModel(InputTextFieldState.normal, undefined);
    this.displayer?.displayConfirmNewPasswordInputValidation(viewModel);
  }

  presentDisabledChangePasswordButton() {
    this.displayer?.displayDisabledChangePasswordButton();
  }

  presentEnabledChangePasswordButton() {
    this.displayer?.displayEnabledChangePasswordButton();
  }

  presentLoadingState() {
    this.displayer?.displayLoadingState();
  }

  presentNotLoadingState() {
    this.displayer?.displayNotLoadingState();
  }

  presentSuccessChangePassword(response: ChangePasswordModels.ChangePasswordSuccess.Response) {
    let text = response?.message?.message ?? ChangePasswordLocalization.instance.successText();
    let viewModel = new ChangePasswordModels.ChangePasswordSuccess.ViewModel(text);
    this.displayer?.displaySuccessChangePassword(viewModel);
  }

  presentFailureChangePassword(response: ChangePasswordModels.ChangePasswordFailure.Response) {
    let viewModel = new ChangePasswordModels.ChangePasswordFailure.ViewModel(this.errorMessage(response.error));
    this.displayer?.displayFailureChangePassword(viewModel);
  }

  presentNoErrorMessage() {
    this.displayer?.displayNoErrorMessage();
  }

  presentNoSuccessMessage() {
    this.displayer?.displayNoSuccessMessage();
  }

  errorMessage(error: OperationError): string {
    let code = error.code ? error.code : 0;
    if (code >= 1400 && code < 1450) {
      return error.localizedMessage() ?? ChangePasswordLocalization.instance.errorText();
    }
    return ChangePasswordLocalization.instance.errorText();
  }
}
