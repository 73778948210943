import {JokesScene} from './JokesScene';

export interface JokesRoutingLogic {
  navigateToUrl(url: string): void;
}

export class JokesRouter implements JokesRoutingLogic {
  scene?: JokesScene;

  navigateToUrl(url: string): void {
    window.open(url);
  }
}
