import React, {HTMLInputTypeAttribute} from 'react';
import {AttributedText} from '../../models/attributed_text/AttributedText';

export default class TextInput extends React.Component<TextInput.Props, TextInput.State> {
  textInput?: HTMLInputElement | null;

  render() {
    return (
      <input
        ref={ref => (this.textInput = ref)}
        style={{...this.props.style, ...this.props.attributedText?.style}}
        placeholder={this.props.placeholder}
        autoComplete={this.props.autoComplete}
        value={this.props.attributedText?.text}
        type={this.props.keyboardType}
        onChange={event => this.onChangeText(event.target.value)}
        onBlur={() => this.onSubmitEditing()}
        maxLength={this.props.maxLength}
        disabled={!this.props.editable}
        onClick={() => this.focus()}
      />
    );
  }

  focus() {
    this.textInput?.focus();
  }

  onChangeText(text: string) {
    this.props.onChangeText && this.props.onChangeText(text);
  }

  onSubmitEditing() {
    this.props.onSubmitEditing && this.props.onSubmitEditing();
  }
}

export namespace TextInput {
  export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    attributedText: AttributedText | undefined;
    onChangeText?: (text?: string) => void;
    onSubmitEditing?: () => void;
    autoComplete?: string;
    placeholder?: string;
    keyboardType?: HTMLInputTypeAttribute;
    maxLength?: number;
    editable?: boolean;
  }

  export interface State {}
}
