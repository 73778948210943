import {localizedStrings} from '../../localization/LocalizationManager';

export class ChangePasswordLocalization {
  static instance = new ChangePasswordLocalization();

  newPasswordPlaceholder(): string {
    return localizedStrings['Change.password.scene.new.password.placeholder'];
  }

  newPasswordErrorText(): string {
    return localizedStrings['Change.password.scene.new.password.error.text'];
  }

  confirmNewPasswordPlaceholder(): string {
    return localizedStrings['Change.password.scene.confirm.new.password.placeholder'];
  }

  confirmNewPasswordErrorText(): string {
    return localizedStrings['Change.password.scene.confirm.new.password.error.text'];
  }

  changePasswordText(): string {
    return localizedStrings['Change.password.scene.change.password.text'];
  }

  successText(): string {
    return localizedStrings['Change.password.scene.success.text'];
  }

  errorText(): string {
    return localizedStrings['Change.password.scene.error.text'];
  }
}
