import {Photo} from '../photo/Photo';

export class User {
  static currentUser?: User = undefined;

  id?: number;
  uuid?: string;

  createdAt?: string;

  username?: string;
  email?: string;
  name?: string;

  photo?: Photo;

  points: number = 0;

  constructor(id?: number, uuid?: string) {
    this.id = id;
    this.uuid = uuid;
  }
}

export namespace User {
  export class Report {
    id?: number;
    uuid?: string;

    type?: number;
    text?: string;

    createdAt?: string;
  }
}

export namespace User {
  export namespace Report {
    export class Type {
      static custom = new Type(0);
      static sexuality = new Type(1);
      static violence = new Type(2);
      static copyright = new Type(3);
      static illegal = new Type(4);

      static allCases: Type[] = [this.custom, this.sexuality, this.violence, this.copyright, this.illegal];

      value: number;

      constructor(value: number) {
        this.value = value;
      }

      static from(value?: number): Type | undefined {
        if (value === undefined) {
          return undefined;
        }
        return new Type(value);
      }
    }
  }
}

export namespace User {
  export class PushNotificationsSettings {
    id?: number;
    uuid?: string;

    createdAt?: string;

    isQnaJokeEnabled: boolean = false;
    isTextJokeEnabled: boolean = false;
    isJokeOfTheDayEnabled: boolean = false;
    isJokeOfTheWeekEnabled: boolean = false;
    isJokeOfTheMonthEnabled: boolean = false;
    isJokeOfTheYearEnabled: boolean = false;
    isJokeLikeEnabled: boolean = false;
    isJokeStatusEnabled: boolean = false;

    updateWith(settings: PushNotificationsSettings) {
      this.id = settings.id;
      this.uuid = settings.uuid;

      this.createdAt = settings.createdAt;

      this.isQnaJokeEnabled = settings.isQnaJokeEnabled;
      this.isTextJokeEnabled = settings.isTextJokeEnabled;
      this.isJokeOfTheDayEnabled = settings.isJokeOfTheDayEnabled;
      this.isJokeOfTheWeekEnabled = settings.isJokeOfTheWeekEnabled;
      this.isJokeOfTheMonthEnabled = settings.isJokeOfTheMonthEnabled;
      this.isJokeOfTheYearEnabled = settings.isJokeOfTheYearEnabled;
      this.isJokeLikeEnabled = settings.isJokeLikeEnabled;
      this.isJokeStatusEnabled = settings.isJokeStatusEnabled;
    }
  }
}

export namespace User {
  export namespace PushNotificationsSettings {
    export class Type {
      static textJoke = new Type(1);
      static qnaJoke = new Type(2);
      static jokeOfTheDay = new Type(3);
      static jokeOfTheWeek = new Type(4);
      static jokeOfTheMonth = new Type(5);
      static jokeOfTheYear = new Type(6);
      static jokeLike = new Type(7);
      static jokeStatus = new Type(8);

      static allCases: Type[] = [this.textJoke, this.qnaJoke, this.jokeOfTheDay, this.jokeOfTheWeek, this.jokeOfTheMonth, this.jokeOfTheYear, this.jokeLike, this.jokeStatus];

      value: number;

      constructor(value: number) {
        this.value = value;
      }

      static from(value?: number): Type | undefined {
        if (value === undefined) {
          return undefined;
        }
        return new Type(value);
      }
    }
  }
}
