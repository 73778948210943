import React, {PureComponent} from 'react';
import {AttributedText} from '../../../models/attributed_text/AttributedText';
import {ApplicationConstraints} from '../../../style/ApplicationConstraints';
import {v4 as uuidv4} from 'uuid';
import {UserAvatarView} from '../../../components/views/user_avatar_view/UserAvatarView';
import {ImageTitleButton} from '../../../components/buttons/ImageTitleButton';
import {ApplicationStyle} from '../../../style/ApplicationStyle';
import {CompoundImage} from '../../../models/image/CompoundImage';
import View from '../../views/view/View';
import Text from '../../text/Text';
import Image from '../../views/image/Image';
import {StyleSheet, ViewProps} from '../../../models/style_sheet/StyleSheet';
import TouchableOpacity from '../../touchable_opacity/TouchableOpacity';
import {ApplicationLocalization} from '../../../scenes/application/ApplicationLocalization';

export class JokeQuestionAnswerCell extends PureComponent<JokeQuestionAnswerCell.Props, JokeQuestionAnswerCell.State> implements JokeQuestionAnswerCell.CellInterface, UserAvatarView.Delegate {
  constructor(props: JokeQuestionAnswerCell.Props) {
    super(props);
    this.props.model.cellInterface = this;
  }

  componentDidUpdate(prevProps: Readonly<JokeQuestionAnswerCell.Props>, prevState: Readonly<JokeQuestionAnswerCell.State>, snapshot?: any): void {
    if (this.props.model.cellInterface === undefined) {
      this.props.model.cellInterface = this;
    }
  }

  reload(): void {
    this.setState({});
  }

  render() {
    return this.setupContentView();
  }

  setupContentView() {
    return (
      <View key={uuidv4()} style={{...constraints.contentView, ...styles.contentView}}>
        {this.setupContainerView()}
      </View>
    );
  }

  setupContainerView() {
    return (
      <View key={uuidv4()} style={{...constraints.containerView, ...styles.containerView}}>
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
        {this.setupRankJokeContainerView()}
        {this.setupTopContainerView()}
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
        {this.setupText()}
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
        {this.setupAnswer()}
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
        {this.setupBottomContainerView()}
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
      </View>
    );
  }

  setupRankJokeContainerView() {
    if (!this.props.model.isRanked) {
      return null;
    }
    return (
      <View style={constraints.rankJokeContainerView}>
        {this.setupRankJoke()}
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
      </View>
    );
  }

  setupRankJoke() {
    let model = new ImageTitleButton.Model();
    model.isDisabled = true;
    model.backgroundColor = ApplicationStyle.colors.lightPrimaryShade15;
    model.borderRadius = ApplicationConstraints.constant.x16;
    model.title = this.props.model.rankJoke;
    return <ImageTitleButton style={constraints.rankJoke} model={model} />;
  }

  setupTopContainerView() {
    return (
      <View key={uuidv4()} style={constraints.topContainerView}>
        {this.setupUserAvatarView()}
        {this.setupHorizontalSpaceView(ApplicationConstraints.constant.x8)}
        {this.setupUserContainerView()}
        {this.setupHorizontalSpaceView(ApplicationConstraints.constant.x8)}
        {this.setupMoreImage()}
      </View>
    );
  }

  setupUserAvatarView() {
    return <UserAvatarView model={this.props.model.avatar} style={constraints.avatarView} delegate={this} />;
  }

  userAvatarViewOnPress(_view: UserAvatarView): void {
    this.props.delegate?.jokeQuestionAnswerCellOnPressUserAvatar(this, this.props.model.id);
  }

  setupUserContainerView() {
    return (
      <TouchableOpacity style={constraints.userContainerView} onPress={() => this.props.delegate?.jokeQuestionAnswerCellOnPressUserName(this, this.props.model.id)}>
        {this.setupName()}
        {this.setupUsername()}
      </TouchableOpacity>
    );
  }

  setupName() {
    return <Text key={uuidv4()} style={{...this.props.model.name?.style, ...constraints.name}} attributedText={this.props.model.name} />;
  }

  setupUsername() {
    return <Text key={uuidv4()} style={{...this.props.model.username?.style, ...constraints.username}} attributedText={this.props.model.username} />;
  }

  setupMoreImage() {
    if (this.props.model.hideMore) {
      return null;
    }
    return (
      <TouchableOpacity style={constraints.moreImage} onPress={() => this.props.delegate?.jokeQuestionAnswerCellOnPressMore(this, this.props.model.id)}>
        <Image src={ApplicationStyle.images.moreSmallGrayImage} resizeMode="contain" />
      </TouchableOpacity>
    );
  }

  setupText() {
    return <Text key={uuidv4()} style={{...this.props.model.text?.style, ...constraints.text}} attributedText={this.props.model.text} />;
  }

  setupAnswer() {
    if (this.props.model.isRead) {
      return this.setupAnswerText();
    } else {
      return this.setupAnswerButton();
    }
  }

  setupAnswerButton() {
    let model = new ImageTitleButton.Model();
    model.image = new CompoundImage(undefined, ApplicationStyle.images.answerSmallImage, 'contain');
    model.isDisabled = false;
    model.backgroundImage = new CompoundImage(undefined, ApplicationStyle.images.buttonBackgroundImage, 'contain');
    model.borderRadius = ApplicationConstraints.constant.x16;
    model.borderColor = ApplicationStyle.colors.white;
    model.backgroundColor = ApplicationStyle.colors.primary;
    model.title = new AttributedText(ApplicationLocalization.instance.readAnswerTitle(), styles.answerButtonText);
    return <ImageTitleButton style={constraints.answerButton} model={model} onPress={() => this.props.delegate?.jokeQuestionAnswerCellOnPressReadAnswer(this, this.props.model.id)} />;
  }

  setupAnswerText() {
    return <Text key={uuidv4()} style={{...this.props.model.answer?.style, ...constraints.answerText}} attributedText={this.props.model.answer} />;
  }

  setupBottomContainerView() {
    return (
      <View style={constraints.bottomContainerView}>
        {this.setupLikeCountView()}
        {this.setupHorizontalSpaceView(ApplicationConstraints.constant.x8)}
        {this.setupDislikeCountView()}
        {this.setupHorizontalSpaceView(ApplicationConstraints.constant.x8)}
        {this.setupShareView()}
        {this.setupHorizontalSpaceView(ApplicationConstraints.constant.x8, 1)}
        {this.setupTime()}
      </View>
    );
  }

  setupLikeCountView() {
    if (this.props.model.likeCount !== undefined && this.props.model.likeCount !== null) {
      return <ImageTitleButton style={constraints.likeCountView} model={this.props.model.likeCount} onPress={() => this.props.delegate?.jokeQuestionAnswerCellOnPressLikeCount(this, this.props.model.id)} />;
    }
    return null;
  }

  setupDislikeCountView() {
    if (this.props.model.dislikeCount !== undefined && this.props.model.dislikeCount !== null) {
      return <ImageTitleButton style={constraints.dislikeCountView} model={this.props.model.dislikeCount} onPress={() => this.props.delegate?.jokeQuestionAnswerCellOnPressDislikeCount(this, this.props.model.id)} />;
    }
    return null;
  }

  setupCommentCountView() {
    if (this.props.model.commentCount !== undefined && this.props.model.commentCount !== null) {
      return <ImageTitleButton style={constraints.commentCountView} model={this.props.model.commentCount} onPress={() => this.props.delegate?.jokeQuestionAnswerCellOnPressCommentCount(this, this.props.model.id)} />;
    }
    return null;
  }

  setupShareView() {
    if (this.props.model.share !== undefined && this.props.model.share !== null) {
      return <ImageTitleButton style={constraints.shareView} model={this.props.model.share} onPress={() => this.props.delegate?.jokeQuestionAnswerCellOnPressShare(this, this.props.model.id)} />;
    }
    return null;
  }

  setupTime() {
    return <Text key={uuidv4()} style={{...this.props.model.time?.style, ...constraints.time}} attributedText={this.props.model.time} />;
  }

  setupVerticalSpaceView(space: number) {
    return <View key={uuidv4()} style={{height: space}} />;
  }

  setupHorizontalSpaceView(space: number, flex?: number) {
    return <View key={uuidv4()} style={{width: space, flex: flex}} />;
  }
}

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: ApplicationStyle.colors.transparent,
  },
  containerView: {
    backgroundColor: ApplicationStyle.colors.white,
    borderColor: ApplicationStyle.colors.lightGray,
    borderRadius: ApplicationConstraints.constant.x16,
    borderWidth: ApplicationConstraints.constant.x1,
    borderStyle: 'solid',
  },
  answerButtonText: {
    color: ApplicationStyle.colors.white,
    fontFamily: ApplicationStyle.fonts.regular,
    fontSize: 17,
  },
});

const constraints = StyleSheet.create({
  contentView: {
    display: 'flex',
    flex: 1,
    width: ApplicationConstraints.multiplier.x100,
  },
  containerView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
  },
  rankJokeContainerView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  rankJoke: {
    display: 'flex',
    marginLeft: ApplicationConstraints.constant.x16,
    alignSelf: 'flex-start',
    height: ApplicationConstraints.constant.x32,
  },
  topContainerView: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  userContainerView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  name: {},
  username: {},
  moreImage: {
    height: ApplicationConstraints.constant.x20,
  },
  avatarView: {
    width: ApplicationConstraints.constant.x40,
    height: ApplicationConstraints.constant.x40,
  },
  text: {
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
  },
  answerButton: {
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
    height: ApplicationConstraints.constant.x32,
  },
  answerText: {
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
  },
  bottomContainerView: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  likeCountView: {
    height: ApplicationConstraints.constant.x24,
  },
  dislikeCountView: {
    height: ApplicationConstraints.constant.x24,
  },
  commentCountView: {
    height: ApplicationConstraints.constant.x24,
  },
  shareView: {
    height: ApplicationConstraints.constant.x24,
  },
  time: {},
});

export namespace JokeQuestionAnswerCell {
  export class Model {
    id?: string;

    avatar: UserAvatarView.Model;

    rankJoke?: AttributedText;
    isRanked: boolean = false;

    name?: AttributedText;
    username?: AttributedText;
    text?: AttributedText;
    answer?: AttributedText;
    isRead: boolean = false;

    likeCount?: ImageTitleButton.Model;
    dislikeCount?: ImageTitleButton.Model;
    commentCount?: ImageTitleButton.Model;
    share?: ImageTitleButton.Model;

    time?: AttributedText;

    hideMore: boolean = false;

    isBlocked: boolean = false;
    hideBlockedMore: boolean = false;
    blockedText?: AttributedText;

    cellInterface?: CellInterface;

    constructor(avatar: UserAvatarView.Model) {
      this.avatar = avatar;
    }
  }

  export interface Props extends ViewProps {
    model: Model;
    delegate?: Delegate;
  }

  export interface State {}

  export interface CellInterface {
    reload(): void;
  }

  export interface Delegate {
    jokeQuestionAnswerCellOnPressLikeCount(cell: JokeQuestionAnswerCell, id?: string): void;
    jokeQuestionAnswerCellOnPressDislikeCount(cell: JokeQuestionAnswerCell, id?: string): void;
    jokeQuestionAnswerCellOnPressCommentCount(cell: JokeQuestionAnswerCell, id?: string): void;
    jokeQuestionAnswerCellOnPressShare(cell: JokeQuestionAnswerCell, id?: string): void;
    jokeQuestionAnswerCellOnPressMore(cell: JokeQuestionAnswerCell, id?: string): void;
    jokeQuestionAnswerCellOnPressUserAvatar(cell: JokeQuestionAnswerCell, id?: string): void;
    jokeQuestionAnswerCellOnPressUserName(cell: JokeQuestionAnswerCell, id?: string): void;
    jokeQuestionAnswerCellOnPressReadAnswer(cell: JokeQuestionAnswerCell, id?: string): void;

    jokeQuestionAnswerCellOnPressBlockedMore(cell: JokeQuestionAnswerCell, id?: string): void;
    jokeQuestionAnswerCellOnPressBlockedContainerView(cell: JokeQuestionAnswerCell, id?: string): void;
  }
}
