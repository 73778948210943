import {OperationError} from '../../operations/base/errors/OperationError';
import {TaskConfigurator} from '../../tasks/configurator/TaskConfigurator';
import {taskEnvironment} from '../../tasks/environment/TaskEnvironment';
import {AuthenticationTaskProtocol, AuthenticationTaskModels} from '../../tasks/authentication/AuthenticationTask';
import {Message} from '../../models/message/Message';

export interface ChangePasswordWorkerDelegate {
  successDidChangePassword(message?: Message): void;
  failureDidChangePassword(error: OperationError): void;
}

export class ChangePasswordWorker {
  delegate?: ChangePasswordWorkerDelegate;

  authenticationTask: AuthenticationTaskProtocol = TaskConfigurator.instance.authenticationTask();

  constructor(delegate?: ChangePasswordWorkerDelegate) {
    this.delegate = delegate;
    this.authenticationTask.environment = taskEnvironment();
  }

  changePassword(newPassword: string, confirmNewPassword: string, resetPasswordToken: string) {
    let delegate = this.delegate;
    this.authenticationTask.updatePassword(new AuthenticationTaskModels.UpdatePassword.Request(newPassword, confirmNewPassword, resetPasswordToken), {
      success(response?: AuthenticationTaskModels.UpdatePassword.Response) {
        delegate?.successDidChangePassword(response?.message);
      },
      failure(error: OperationError) {
        delegate?.failureDidChangePassword(error);
      },
    });
  }
}
