export class AttributedText {
  text?: string;
  style?: any;
  numberOfLines?: number;

  isTappable: boolean = false;
  tappableIdentifier?: any;

  constructor(text?: string, style?: any, numberOfLines?: number) {
    this.text = text;
    this.style = style;
    this.numberOfLines = numberOfLines;
  }
}
