import {ApplicationStyle} from '../../style/ApplicationStyle';
import {ApplicationConstraints} from '../../style/ApplicationConstraints';
import {withAlphaHex} from 'with-alpha-hex';

export class JokesStyle {
  static instance = new JokesStyle();

  contentViewModel(): JokesStyle.ContentViewModel {
    return new JokesStyle.ContentViewModel();
  }

  flatListModel(): JokesStyle.FlatListModel {
    return new JokesStyle.FlatListModel();
  }

  topJokeCellModel(): JokesStyle.TopJokeCellModel {
    return new JokesStyle.TopJokeCellModel();
  }

  jokeCellModel(): JokesStyle.JokeCellModel {
    return new JokesStyle.JokeCellModel();
  }

  cellModel(): JokesStyle.CellModel {
    return new JokesStyle.CellModel();
  }
}

export namespace JokesStyle {
  export class ContentViewModel {
    backgroundColor: string = ApplicationStyle.colors.transparent;
  }

  export class FlatListModel {
    backgroundColor: string = ApplicationStyle.colors.backgroundColor;
    activityIndicatorColor: string = ApplicationStyle.colors.primary;
    loadMoreBackground: any = ApplicationStyle.images.buttonBackgroundImage;

    noMoreItemsTextStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 14,
    };
    loadMoreItemsTextStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
    };
    errorTextStyle: any = {
      color: ApplicationStyle.colors.secondary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 14,
    };
  }

  export class CellModel {
    backgroundColor: string = ApplicationStyle.colors.transparent;
  }

  export class TopJokeCellModel {
    avatarBorderRadius: number = ApplicationConstraints.constant.x20;
    avatarActivityColor: string = ApplicationStyle.colors.white;
    avatarBackgroundColor: string = ApplicationStyle.colors.transparent;
    avatarBorderColor: string = withAlphaHex(ApplicationStyle.colors.white, 0.5);
    avatarBorderWidth: number = ApplicationConstraints.constant.x1;
    avatarMargin = ApplicationConstraints.constant.x0;
    avatarPlaceholder: any = ApplicationStyle.images.userAvatarPlaceholderSmallImage;

    topJokeTextStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.bold,
      fontSize: 16,
    };

    nameStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.bold,
      fontSize: 17,
    };
    usernameStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 14,
    };
    textStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
      whiteSpace: 'pre-wrap',
    };
    answerStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
    };

    likeCountActivityColor: string = ApplicationStyle.colors.white;
    likeCountImage: any = ApplicationStyle.images.likeSmallWhiteImage;
    selectedLikeCountTintColor: string = ApplicationStyle.colors.white;
    selectedLikeCountBackgroundColor: string = withAlphaHex(ApplicationStyle.colors.white, 0.3);
    selectedLikeCountStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };
    unselectedLikeCountTintColor: string = ApplicationStyle.colors.white;
    unselectedLikeCountBackgroundColor: string = ApplicationStyle.colors.transparent;
    unselectedLikeCountStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    dislikeCountActivityColor: string = ApplicationStyle.colors.white;
    dislikeCountImage: any = ApplicationStyle.images.dislikeSmallWhiteImage;
    selectedDislikeCountBackgroundColor: string = withAlphaHex(ApplicationStyle.colors.white, 0.3);
    selectedDislikeCountTintColor: string = ApplicationStyle.colors.white;
    selectedDislikeCountStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };
    unselectedDislikeCountBackgroundColor: string = ApplicationStyle.colors.transparent;
    unselectedDislikeCountTintColor: string = ApplicationStyle.colors.white;
    unselectedDislikeCountStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    commentCountActivityColor: string = ApplicationStyle.colors.white;
    commentCountBackgroundColor: string = ApplicationStyle.colors.transparent;
    commentCountImage: any = ApplicationStyle.images.commentSmallImage;
    commentCountTintColor: string = ApplicationStyle.colors.white;
    commentCountStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    shareBackgroundColor: string = ApplicationStyle.colors.transparent;
    shareImage: any = ApplicationStyle.images.shareSmallWhiteImage;
    shareStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    timeStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.oblique,
      fontSize: 13,
    };

    blockedTextStyle: any = {
      color: ApplicationStyle.colors.white,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 18,
    };
  }

  export class JokeCellModel {
    avatarBorderRadius: number = ApplicationConstraints.constant.x20;
    avatarActivityColor: string = ApplicationStyle.colors.primary;
    avatarBackgroundColor: string = ApplicationStyle.colors.primary;
    avatarBorderColor: string = withAlphaHex(ApplicationStyle.colors.white, 0.5);
    avatarBorderWidth: number = ApplicationConstraints.constant.x1;
    avatarMargin = ApplicationConstraints.constant.x0;
    avatarPlaceholder: any = ApplicationStyle.images.userAvatarPlaceholderSmallImage;

    nameStyle: any = {
      color: ApplicationStyle.colors.primary,
      fontFamily: ApplicationStyle.fonts.bold,
      fontSize: 17,
    };
    usernameStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 14,
    };
    textStyle: any = {
      color: ApplicationStyle.colors.primary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
      whiteSpace: 'pre-wrap',
    };
    answerStyle: any = {
      color: ApplicationStyle.colors.primary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
      whiteSpace: 'pre-wrap',
    };

    likeCountActivityColor: string = ApplicationStyle.colors.gray;
    likeCountImage: any = ApplicationStyle.images.likeSmallGrayImage;
    selectedLikeCountBackgroundColor: string = withAlphaHex(ApplicationStyle.colors.lightSecondary, 0.1);
    selectedLikeCountTintColor: string = ApplicationStyle.colors.lightSecondary;
    selectedLikeCountStyle: any = {
      color: ApplicationStyle.colors.lightSecondary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };
    unselectedLikeCountBackgroundColor: string = ApplicationStyle.colors.transparent;
    unselectedLikeCountTintColor: string = ApplicationStyle.colors.gray;
    unselectedLikeCountStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    dislikeCountActivityColor: string = ApplicationStyle.colors.primary;
    dislikeCountImage: any = ApplicationStyle.images.dislikeSmallGrayImage;
    selectedDislikeCountBackgroundColor: string = withAlphaHex(ApplicationStyle.colors.lightSecondary, 0.1);
    selectedDislikeCountTintColor: string = ApplicationStyle.colors.lightSecondary;
    selectedDislikeCountStyle: any = {
      color: ApplicationStyle.colors.lightSecondary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };
    unselectedDislikeCountBackgroundColor: string = ApplicationStyle.colors.transparent;
    unselectedDislikeCountTintColor: string = ApplicationStyle.colors.gray;
    unselectedDislikeCountStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    commentCountActivityColor: string = ApplicationStyle.colors.primary;
    commentCountBackgroundColor: string = ApplicationStyle.colors.transparent;
    commentCountImage: any = ApplicationStyle.images.commentSmallImage;
    commentCountTintColor: string = ApplicationStyle.colors.gray;
    commentCountStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    shareBackgroundColor: string = ApplicationStyle.colors.transparent;
    shareImage: any = ApplicationStyle.images.shareSmallGrayImage;
    shareStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    timeStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.oblique,
      fontSize: 13,
    };
  }
}
