import React, {PureComponent} from 'react';
import {ApplicationConstraints} from '../../../style/ApplicationConstraints';
import {v4 as uuidv4} from 'uuid';
import {ApplicationStyle} from '../../../style/ApplicationStyle';
import {AttributedText} from '../../../models/attributed_text/AttributedText';
import {ApplicationLocalization} from '../../../scenes/application/ApplicationLocalization';
import View from '../../views/view/View';
import Image from '../../views/image/Image';
import Text from '../../text/Text';
import {StyleSheet, ViewProps} from '../../../models/style_sheet/StyleSheet';

export class DownloadAppView extends PureComponent<DownloadAppView.Props, DownloadAppView.State> implements DownloadAppView.CellInterface {
  componentDidUpdate(prevProps: Readonly<DownloadAppView.Props>, prevState: Readonly<DownloadAppView.State>, snapshot?: any): void {
    if (this.props.model.cellInterface === undefined) {
      this.props.model.cellInterface = this;
    }
  }

  reload(): void {
    this.setState({});
  }

  render() {
    return this.setupContentView();
  }

  setupContentView() {
    return (
      <View key={uuidv4()} style={{...this.props.style, ...constraints.contentView, ...styles.contentView}}>
        {this.setupSeparatorView()}
        {this.setupContainerView()}
      </View>
    );
  }

  setupContainerView() {
    return (
      <View key={uuidv4()} style={{...constraints.containerView, ...styles.containerView}}>
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
        {this.setupText()}
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
        {this.setupDownloadContainer()}
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
      </View>
    );
  }

  setupSeparatorView() {
    return <View key={uuidv4()} style={{...constraints.separatorView, ...styles.separatorView}} />;
  }

  setupText() {
    return <Text key={uuidv4()} style={{...styles.text, ...constraints.text}} attributedText={new AttributedText(ApplicationLocalization.instance.downloadAppText(), styles.text)} />;
  }

  setupDownloadContainer() {
    return (
      <View style={constraints.downloadContainerView}>
        {this.setupAppleButton()}
        {this.setupHorizontalSpaceView(ApplicationConstraints.constant.x8)}
        {this.setupGoogleButton()}
      </View>
    );
  }

  setupAppleButton() {
    return <Image onClick={() => this.props.delegate?.downloadAppViewOnPressApple(this)} style={constraints.appleButton} src={ApplicationStyle.images.appleMediumImage}></Image>;
  }

  setupGoogleButton() {
    return <Image onClick={() => this.props.delegate?.downloadAppViewOnPressGoogle(this)} style={constraints.googleButton} src={ApplicationStyle.images.googleMediumImage}></Image>;
  }

  setupVerticalSpaceView(space: number) {
    return <View key={uuidv4()} style={{height: space}} />;
  }

  setupHorizontalSpaceView(space: number, flex?: number) {
    return <View key={uuidv4()} style={{width: space, flex: flex}} />;
  }
}

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: ApplicationStyle.colors.white,
  },
  containerView: {
    backgroundColor: ApplicationStyle.colors.white,
  },
  text: {
    color: ApplicationStyle.colors.primary,
    fontFamily: ApplicationStyle.fonts.bold,
    fontSize: 17,
  },
  separatorView: {
    backgroundColor: ApplicationStyle.colors.lightGray,
  },
});

const constraints = StyleSheet.create({
  contentView: {
    display: 'flex',
    flex: 1,
    width: ApplicationConstraints.multiplier.x100,
    flexDirection: 'column',
  },
  containerView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
  },
  text: {
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
  },
  downloadContainerView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  shareButton: {
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
    height: ApplicationConstraints.constant.x32,
  },
  appleButton: {
    height: ApplicationConstraints.constant.x40,
  },
  googleButton: {
    height: ApplicationConstraints.constant.x40,
  },
  separatorView: {
    height: ApplicationConstraints.constant.x1,
  },
});

export namespace DownloadAppView {
  export class Model {
    cellInterface?: CellInterface;
  }

  export interface Props extends ViewProps {
    model: Model;
    delegate?: Delegate;
  }

  export interface State {}

  export interface CellInterface {
    reload(): void;
  }

  export interface Delegate {
    downloadAppViewOnPressApple(cell: DownloadAppView): void;
    downloadAppViewOnPressGoogle(cell: DownloadAppView): void;
  }
}
