import React, {PureComponent} from 'react';
import {ApplicationConstraints} from '../../../style/ApplicationConstraints';
import {v4 as uuidv4} from 'uuid';
import {ImageTitleButton} from '../../../components/buttons/ImageTitleButton';
import {ApplicationStyle} from '../../../style/ApplicationStyle';
import {AttributedText} from '../../../models/attributed_text/AttributedText';
import {CompoundImage} from '../../../models/image/CompoundImage';
import {ApplicationLocalization} from '../../../scenes/application/ApplicationLocalization';
import View from '../../views/view/View';
import Image from '../../views/image/Image';
import Text from '../../text/Text';
import {StyleSheet, ViewProps} from '../../../models/style_sheet/StyleSheet';

export class ShareAppCell extends PureComponent<ShareAppCell.Props, ShareAppCell.State> implements ShareAppCell.CellInterface {
  componentDidUpdate(prevProps: Readonly<ShareAppCell.Props>, prevState: Readonly<ShareAppCell.State>, snapshot?: any): void {
    if (this.props.model.cellInterface === undefined) {
      this.props.model.cellInterface = this;
    }
  }

  reload(): void {
    this.setState({});
  }

  render() {
    return this.setupContentView();
  }

  setupContentView() {
    return (
      <View key={uuidv4()} style={{...constraints.contentView, ...styles.contentView}}>
        {this.setupContainerView()}
      </View>
    );
  }

  setupContainerView() {
    return (
      <View key={uuidv4()} style={{...constraints.containerView, ...styles.containerView}}>
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
        {this.setupTopContainerView()}
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
        {this.setupText()}
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
        {this.setupShareButton()}
        {this.setupVerticalSpaceView(ApplicationConstraints.constant.x16)}
      </View>
    );
  }

  setupTopContainerView() {
    return (
      <View key={uuidv4()} style={constraints.topContainerView}>
        {this.setupImageView()}
        {this.setupHorizontalSpaceView(ApplicationConstraints.constant.x8)}
        {this.setupTitleContainerView()}
        {this.setupHorizontalSpaceView(ApplicationConstraints.constant.x8)}
      </View>
    );
  }

  setupImageView() {
    return <Image src={ApplicationStyle.images.logoMediumImage} style={constraints.imageView} />;
  }

  setupTitleContainerView() {
    return (
      <View style={constraints.titleContainerView}>
        {this.setupTitle()}
        {this.setupSubtitle()}
      </View>
    );
  }

  setupTitle() {
    return <Text key={uuidv4()} style={{...styles.title, ...constraints.title}} attributedText={new AttributedText(ApplicationLocalization.instance.shareAppTitle())} />;
  }

  setupSubtitle() {
    return <Text key={uuidv4()} style={{...styles.subtitle, ...constraints.subtitle}} attributedText={new AttributedText(ApplicationLocalization.instance.shareAppSubtitle())} />;
  }

  setupText() {
    return <Text key={uuidv4()} style={{...styles.text, ...constraints.text}} attributedText={new AttributedText(ApplicationLocalization.instance.shareAppText())} />;
  }

  setupShareButton() {
    let model = new ImageTitleButton.Model();
    model.image = new CompoundImage(undefined, ApplicationStyle.images.shareSmallWhiteImage, 'contain');
    model.isDisabled = false;
    model.backgroundImage = new CompoundImage(undefined, ApplicationStyle.images.buttonBackgroundImage, 'cover');
    model.borderRadius = ApplicationConstraints.constant.x16;
    model.borderColor = ApplicationStyle.colors.white;
    model.backgroundColor = ApplicationStyle.colors.primary;
    model.title = new AttributedText(ApplicationLocalization.instance.shareAppButtonTitle(), styles.shareButtonText);
    return <ImageTitleButton style={constraints.shareButton} model={model} onPress={() => this.props.delegate?.shareAppCellOnPressShare(this)} />;
  }

  setupVerticalSpaceView(space: number) {
    return <View key={uuidv4()} style={{height: space}} />;
  }

  setupHorizontalSpaceView(space: number, flex?: number) {
    return <View key={uuidv4()} style={{width: space, flex: flex}} />;
  }
}

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: ApplicationStyle.colors.transparent,
  },
  containerView: {
    backgroundColor: ApplicationStyle.colors.white,
    borderColor: ApplicationStyle.colors.lightGray,
    borderRadius: ApplicationConstraints.constant.x16,
    borderWidth: ApplicationConstraints.constant.x1,
    borderStyle: 'solid',
  },
  title: {
    color: ApplicationStyle.colors.primary,
    fontFamily: ApplicationStyle.fonts.bold,
    fontSize: 17,
    includeFontPadding: false,
  },
  subtitle: {
    color: ApplicationStyle.colors.gray,
    fontFamily: ApplicationStyle.fonts.regular,
    fontSize: 14,
    includeFontPadding: false,
  },
  text: {
    color: ApplicationStyle.colors.primary,
    fontFamily: ApplicationStyle.fonts.regular,
    fontSize: 17,
    includeFontPadding: false,
  },
  shareButtonText: {
    color: ApplicationStyle.colors.white,
    fontFamily: ApplicationStyle.fonts.regular,
    fontSize: 17,
    includeFontPadding: false,
  },
});

const constraints = StyleSheet.create({
  contentView: {
    display: 'flex',
    flex: 1,
    width: ApplicationConstraints.multiplier.x100,
  },
  containerView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
  },
  topContainerView: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainerView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  title: {},
  subtitle: {},
  imageView: {
    width: ApplicationConstraints.constant.x40,
    height: ApplicationConstraints.constant.x40,
  },
  text: {
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
  },
  shareButton: {
    marginLeft: ApplicationConstraints.constant.x16,
    marginRight: ApplicationConstraints.constant.x16,
    height: ApplicationConstraints.constant.x32,
  },
});

export namespace ShareAppCell {
  export class Model {
    cellInterface?: CellInterface;
  }

  export interface Props extends ViewProps {
    model: Model;
    delegate?: Delegate;
  }

  export interface State {}

  export interface CellInterface {
    reload(): void;
  }

  export interface Delegate {
    shareAppCellOnPressShare(cell: ShareAppCell): void;
  }
}
