import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import {Configuration} from '../../../config';
import {ApplicationLocalization} from '../../application/ApplicationLocalization';

export default class JokesMetaTagsBuilder extends Component {
  render() {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ApplicationLocalization.instance.appTitle()}</title>
        <meta property="og:title" content={ApplicationLocalization.instance.appTitle()} />
        <meta property="og:image" content="https://iglume.ro/wp-content/uploads/2022/12/open_graph_image.png" />
        <meta property="og:url" content="https://iglume.ro" />
        <meta property="og:description" content={ApplicationLocalization.instance.metaTagDescription()} />
        <meta property="og:type" content="website" />

        <meta property="al:ios:url" content={Configuration.instance.iOSDeepLink()} />
        <meta property="al:ios:app_store_id" content={Configuration.instance.iOSStoreId()} />
        <meta property="al:ios:app_name" content={ApplicationLocalization.instance.appTitle()} />

        <meta property="al:android:url" content={Configuration.instance.androidDeepLink()} />
        <meta property="al:android:package" content={Configuration.instance.androidPackage()} />
        <meta property="al:android:app_name" content={ApplicationLocalization.instance.appTitle()} />

        <meta property="al:web:should_fallback" content="false" />
      </Helmet>
    );
  }
}
