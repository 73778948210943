import React from 'react';
import {ViewProps} from '../../../models/style_sheet/StyleSheet';
import {v4 as uuidv4} from 'uuid';

export class SpaceCell extends React.Component<SpaceCell.Props, SpaceCell.State> {
  render() {
    let style = Object.assign(this.props.style ?? {}, {height: this.props.model.height});
    return <div key={uuidv4()} style={style} />;
  }
}

export namespace SpaceCell {
  export class Model {
    height: number = 0;

    constructor(height: number) {
      this.height = height;
    }
  }

  export interface Props extends ViewProps {
    model: Model;
  }

  export interface State {}
}
