import {v4 as uuidv4} from 'uuid';
import {AttributedText} from '../../models/attributed_text/AttributedText';
import {Joke} from '../../models/joke/Joke';
import {User} from '../../models/user/User';
import {OperationError} from '../../operations/base/errors/OperationError';

export namespace JokeDetailsModels {
  export enum ItemType {
    jokeText,
    jokeQna,
    space,
  }

  export class DisplayedItem {
    uuid: string;
    type: ItemType;
    model?: any;

    constructor(type: ItemType, model?: any) {
      this.uuid = uuidv4();
      this.type = type;
      this.model = model;
    }
  }

  export namespace JokeDetails {
    export class Request {
      jokeId?: string;

      constructor(jokeId?: string) {
        this.jokeId = jokeId;
      }
    }

    export class Response {
      jokeId?: string;

      constructor(jokeId?: string) {
        this.jokeId = jokeId;
      }
    }

    export class ViewModel {
      jokeId?: string;

      constructor(jokeId?: string) {
        this.jokeId = jokeId;
      }
    }
  }

  export namespace ItemsPresentation {
    export class Response {
      joke: Joke;
      isJokeRead: boolean = false;
      currentUser?: User;

      constructor(joke: Joke, isJokeRead: boolean, currentUser?: User) {
        this.joke = joke;
        this.isJokeRead = isJokeRead;
        this.currentUser = currentUser;
      }
    }

    export class ViewModel {
      items: DisplayedItem[];

      constructor(items: DisplayedItem[]) {
        this.items = items;
      }
    }
  }

  export namespace ActionAlertPresentation {
    export class Response {
      error: OperationError;

      constructor(error: OperationError) {
        this.error = error;
      }
    }

    export class ViewModel {
      title?: string;
      message?: string;

      constructor(title?: string, message?: string) {
        this.title = title;
        this.message = message;
      }
    }
  }

  export enum ActionSheetActionType {
    source = 'source',
    reportJoke = 'report_joke',
    shareJoke = 'share_joke',
  }

  export enum ActionSheetType {
    more = 'more',
  }

  export namespace UrlNavigation {
    export class Response {
      url: string;
      constructor(url: string) {
        this.url = url;
      }
    }

    export class ViewModel {
      url: string;
      constructor(url: string) {
        this.url = url;
      }
    }
  }

  export namespace ItemLoadingState {
    export class Response {
      isLoading: boolean;
      id?: string;

      constructor(isLoading: boolean, id?: string) {
        this.isLoading = isLoading;
        this.id = id;
      }
    }

    export class ViewModel {
      id?: string;
      isLoading: boolean;

      constructor(isLoading: boolean, id?: string) {
        this.isLoading = isLoading;
        this.id = id;
      }
    }
  }

  export namespace ItemState {
    export class Response {
      count?: number;
      isSelected?: boolean;
      id?: string;

      constructor(id?: string, count?: number, isSelected?: boolean) {
        this.count = count;
        this.isSelected = isSelected;
        this.id = id;
      }
    }

    export class ViewModel {
      id?: string;
      backgroundColor: string;
      text?: AttributedText;
      imageTintColor?: string;

      constructor(backgroundColor: string, id?: string, text?: AttributedText, imageTintColor?: string) {
        this.backgroundColor = backgroundColor;
        this.id = id;
        this.text = text;
        this.imageTintColor = imageTintColor;
      }
    }
  }

  export namespace ItemReadState {
    export class Response {
      isRead: boolean;
      id?: string;

      constructor(isRead: boolean, id?: string) {
        this.isRead = isRead;
        this.id = id;
      }
    }

    export class ViewModel {
      isRead: boolean;
      id?: string;

      constructor(isRead: boolean, id?: string) {
        this.isRead = isRead;
        this.id = id;
      }
    }
  }

  export namespace JokeReportsNavigation {
    export class Response {
      id?: string;

      constructor(id?: string) {
        this.id = id;
      }
    }

    export class ViewModel {
      id?: string;

      constructor(id?: string) {
        this.id = id;
      }
    }
  }

  export namespace ShareJokeNavigation {
    export class Response {
      id: string;

      constructor(id: string) {
        this.id = id;
      }
    }

    export class ViewModel {
      title: string;
      url: string;

      constructor(title: string, url: string) {
        this.title = title;
        this.url = url;
      }
    }
  }
}
