import {Joke} from '../../models/joke/Joke';
import {Like} from '../../models/like/Like';
import {OperationError} from '../../operations/base/errors/OperationError';
import {JokeDetailsModels} from './JokeDetailsModels';
import {JokeDetailsPresentationLogic} from './JokeDetailsPresenter';
import {JokeDetailsWorker, JokeDetailsWorkerDelegate} from './JokeDetailsWorker';
import {User} from '../../models/user/User';
import {Configuration} from '../../config';

export interface JokeDetailsBusinessLogic {
  shouldResetDetails(): void;

  shouldSetupJokeDetails(request: JokeDetailsModels.JokeDetails.Request): void;
  shouldFetchJokeDetails(): void;

  shouldSelectMore(): void;

  shouldSelectLike(): void;
  shouldSelectDislike(): void;
  shouldSelectShare(): void;
  shouldSelectReadAnswer(): void;

  shouldNavigateToAppleStore(): void;
  shouldNavigateToGooglePlay(): void;
}

export class JokeDetailsInteractor implements JokeDetailsBusinessLogic, JokeDetailsWorkerDelegate {
  presenter?: JokeDetailsPresentationLogic;
  worker?: JokeDetailsWorker;

  jokeId?: string;
  joke?: Joke;
  isFetchingJokeDetails: boolean = false;
  isUpdatingLikeState: boolean = false;
  isJokeRead: boolean = false;

  currentUser?: User;

  constructor() {
    this.worker = new JokeDetailsWorker(this);
    this.currentUser = User.currentUser;
  }

  shouldResetDetails(): void {
    this.joke = undefined;
    this.currentUser = User.currentUser;

    this.presenter?.presentNotLoadingState();
    this.shouldPresentItems();
  }

  //#region User details
  shouldSetupJokeDetails(request: JokeDetailsModels.JokeDetails.Request): void {
    this.jokeId = request.jokeId;
  }

  shouldFetchJokeDetails(): void {
    if (this.jokeId === undefined || this.jokeId === null) {
      return;
    }
    if (!this.isFetchingJokeDetails) {
      this.isFetchingJokeDetails = true;
      this.presenter?.presentLoadingState();
      this.worker?.fetchJokeDetails(this.jokeId);
    }
  }

  successDidFetchJokeDetails(joke?: Joke): void {
    this.joke = joke;
    this.isFetchingJokeDetails = false;
    this.presenter?.presentNotLoadingState();
    this.shouldPresentItems();
  }

  failureDidFetchJokeDetails(error: OperationError): void {
    this.isFetchingJokeDetails = false;
    this.presenter?.presentNotLoadingState();
    this.presenter?.presentErrorActionAlert(new JokeDetailsModels.ActionAlertPresentation.Response(error));
  }
  //#endregion

  shouldSelectMore(): void {}

  shouldSelectShare(): void {
    if (this.joke === undefined || this.joke.uuid === undefined) {
      return;
    }
    this.presenter?.presentNavigateToShareJoke(new JokeDetailsModels.ShareJokeNavigation.Response(this.joke.uuid));
  }

  shouldPresentItems() {
    if (this.joke !== undefined) {
      this.presenter?.presentItems(new JokeDetailsModels.ItemsPresentation.Response(this.joke, this.isJokeRead, this.currentUser));
    }
  }

  isValidUrl(value: string): boolean {
    try {
      return Boolean(new URL(value));
    } catch {
      return false;
    }
  }

  shouldSelectLike(): void {
    if (this.currentUser === undefined) {
      return;
    }

    if (this.isUpdatingLikeState === true) {
      return;
    }

    if (this.joke !== undefined && this.joke.uuid !== undefined) {
      let like = this.joke.like;
      if (like !== undefined && like.uuid !== undefined) {
        if (like.type === Like.Type.like.value) {
          this.shouldDeleteLikeJoke(this.joke.uuid);
        } else {
          this.shouldUpdateJokeLike(this.joke.uuid, like.uuid, Like.Type.like.value);
        }
      } else {
        this.shouldAddLikeJoke(this.joke.uuid);
      }
    }
  }

  shouldSelectDislike(): void {}

  //#region Add like
  shouldAddLikeJoke(id: string) {}
  //#endregion

  //#region Delete like
  shouldDeleteLikeJoke(id: string) {}
  //#endregion

  //#region Add dislike
  shouldAddDislikeJoke(id: string) {}
  //#endregion

  //#region Delete dislike
  shouldDeleteDislikeJoke(id: string) {}
  //#endregion

  //#region Update joke like
  shouldUpdateJokeLike(id: string, likeId: string, type: number) {}
  //#endregion

  shouldPresentLikeLoadingState(isLoading: boolean, id?: string) {
    this.presenter?.presentLikeLoadingState(new JokeDetailsModels.ItemLoadingState.Response(isLoading, id));
  }

  shouldPresentDislikeLoadingState(isLoading: boolean, id?: string) {
    this.presenter?.presentDislikeLoadingState(new JokeDetailsModels.ItemLoadingState.Response(isLoading, id));
  }

  shouldPresentLikeState(id?: string, count?: number, isSelected?: boolean) {
    this.presenter?.presentLikeState(new JokeDetailsModels.ItemState.Response(id, count, isSelected));
  }

  shouldPresentDislikeState(id?: string, count?: number, isSelected?: boolean) {
    this.presenter?.presentDislikeState(new JokeDetailsModels.ItemState.Response(id, count, isSelected));
  }

  shouldSelectReadAnswer(): void {
    this.isJokeRead = true;
    this.presenter?.presentReadState(new JokeDetailsModels.ItemReadState.Response(true, this.joke?.uuid));
  }

  shouldNavigateToAppleStore(): void {
    let url = Configuration.instance.appleStoreUrl();
    this.presenter?.presentNavigateToUrl(new JokeDetailsModels.UrlNavigation.Response(url));
  }

  shouldNavigateToGooglePlay(): void {
    let url = Configuration.instance.googlePlayUrl();
    this.presenter?.presentNavigateToUrl(new JokeDetailsModels.UrlNavigation.Response(url));
  }
}
