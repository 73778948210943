import React, {HTMLInputTypeAttribute} from 'react';
import {AttributedText} from '../../models/attributed_text/AttributedText';
import {ApplicationConstraints} from '../../style/ApplicationConstraints';
import {ApplicationStyle} from '../../style/ApplicationStyle';
import View from '../views/view/View';
import {StyleSheet, ViewProps} from '../../models/style_sheet/StyleSheet';
import Text from '../text/Text';
import TextInput from '../text_input/TextInput';

export enum InputTextFieldState {
  error,
  normal,
}

export class InputTextField extends React.Component<InputTextField.Props> {
  reload() {
    this.setState({});
  }

  render(): React.ReactNode {
    return (
      <View {...this.props}>
        <View style={this.constraints().container}>
          {this.setupInputText()}
          {this.setupErrorText()}
        </View>
      </View>
    );
  }

  private setupInputText() {
    return (
      <TextInput
        keyboardType={this.props.model.keyboardType}
        style={{...this.constraints().input, ...this.props.model.text?.style}}
        placeholder={this.props.model.placeholderText}
        editable={this.props.model.isEnabled}
        autoComplete={this.props.model.autoComplete}
        onChangeText={text => this.onChangeText(text)}
        onSubmitEditing={this.props.onSubmitEditing}
        maxLength={this.props.model.maxLength}
        attributedText={this.props.model.text}
      />
    );
  }

  private setupErrorText() {
    if (this.props.model.state === InputTextFieldState.error) {
      return <Text style={{...this.constraints().errorText, ...this.props.model.error?.style}} attributedText={this.props.model.error} />;
    }
    return undefined;
  }

  private onChangeText(text?: string) {
    this.props.onChangeText && this.props.onChangeText(text);
  }

  constraints(): any {
    return StyleSheet.create({
      container: {
        flexDirection: 'column',
        display: 'flex',
        flex: 1,
      },
      input: {
        padding: ApplicationConstraints.constant.x8,
        display: 'flex',
        flex: 1,
        borderRadius: this.props.model.borderRadius,
        borderWidth: this.props.model.borderWidth,
        borderColor: this.props.model.borderColor,
        borderStyle: 'solid',
        backgroundColor: this.props.model.backgroundColor,
      },
      errorText: {
        marginTop: ApplicationConstraints.constant.x4,
      },
    });
  }
}

export namespace InputTextField {
  export class Model {
    text?: AttributedText;
    error?: AttributedText;
    placeholderText?: string;
    state: InputTextFieldState = InputTextFieldState.normal;
    isEnabled: boolean = true;
    placeholderTextColor: string = ApplicationStyle.colors.gray;
    borderRadius = ApplicationConstraints.constant.x4;
    borderWidth = ApplicationConstraints.constant.x1;
    backgroundColor: string = ApplicationStyle.colors.white;
    borderColor = ApplicationStyle.colors.transparent;
    keyboardType: HTMLInputTypeAttribute = 'default';
    maxLength?: number;
    autoComplete?: string;
  }

  export interface Props extends ViewProps {
    model: Model;
    onChangeText?: (text?: string) => void;
    onSubmitEditing?: () => void;
  }
}
