import {OperationError} from '../../operations/base/errors/OperationError';
import {Joke} from '../../models/joke/Joke';
import {JokeTaskModels, JokeTaskProtocol} from '../../tasks/joke/JokeTask';
import {TaskConfigurator} from '../../tasks/configurator/TaskConfigurator';
import {JokesModels} from './JokesModels';
import {taskEnvironment} from '../../tasks/environment/TaskEnvironment';

export interface JokesWorkerDelegate {
  successDidFetchJokes(jokes: Joke[]): void;
  failureDidFetchJokes(error: OperationError): void;

  successDidFetchTopJokes(jokes: Joke[], type: JokesModels.TopItemType): void;
  failureDidFetchTopJokes(error: OperationError, type: JokesModels.TopItemType): void;
}

export class JokesWorker {
  delegate?: JokesWorkerDelegate;

  jokeTask: JokeTaskProtocol = TaskConfigurator.instance.jokeTask();

  constructor(delegate?: JokesWorkerDelegate) {
    this.delegate = delegate;
    this.jokeTask.environment = taskEnvironment();
  }

  fetchJokes(page: number, limit: number, orderBy: number) {
    let delegate = this.delegate;
    this.jokeTask.fetchJokes(new JokeTaskModels.FetchJokes.Request(page, limit, orderBy), {
      success(response?: JokeTaskModels.FetchJokes.Response) {
        delegate?.successDidFetchJokes(response?.jokes ?? []);
      },
      failure(error: OperationError) {
        delegate?.failureDidFetchJokes(error);
      },
    });
  }

  fetchTopJokes(page: number, limit: number, orderBy: number, type: JokesModels.TopItemType, startedAt?: string, endedAt?: string) {
    let delegate = this.delegate;
    this.jokeTask.fetchJokes(new JokeTaskModels.FetchJokes.Request(page, limit, orderBy, startedAt, endedAt), {
      success(response?: JokeTaskModels.FetchJokes.Response) {
        delegate?.successDidFetchTopJokes(response?.jokes ?? [], type);
      },
      failure(error: OperationError) {
        delegate?.failureDidFetchTopJokes(error, type);
      },
    });
  }
}
