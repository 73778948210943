import {InputTextFieldState} from '../../components/input_text_field/InputTextField';
import {Message} from '../../models/message/Message';
import {OperationError} from '../../operations/base/errors/OperationError';

export namespace ChangePasswordModels {
  export namespace UpdateToken {
    export class Request {
      resetPasswordToken: string | null;

      constructor(resetPasswordToken: string | null) {
        this.resetPasswordToken = resetPasswordToken;
      }
    }
  }

  export namespace UpdateInput {
    export class Request {
      text?: string;

      constructor(text?: string) {
        this.text = text;
      }
    }
  }

  export namespace PasswordValidation {
    export class ViewModel {
      state: InputTextFieldState;
      errorMessage?: string;

      constructor(state: InputTextFieldState, errorMessage?: string) {
        this.state = state;
        this.errorMessage = errorMessage;
      }
    }
  }

  export namespace ChangePasswordFailure {
    export class Response {
      error: OperationError;

      constructor(error: OperationError) {
        this.error = error;
      }
    }

    export class ViewModel {
      errorMessage: string;

      constructor(errorMessage: string) {
        this.errorMessage = errorMessage;
      }
    }
  }

  export namespace ChangePasswordSuccess {
    export class Response {
      message?: Message;

      constructor(message?: Message) {
        this.message = message;
      }
    }

    export class ViewModel {
      text: string;

      constructor(text: string) {
        this.text = text;
      }
    }
  }
}
