import {JokeDetailsScene} from './JokeDetailsScene';

export interface JokeDetailsRoutingLogic {
  dismissScene(): void;

  showActionAlertScene(title?: string, message?: string): void;
  dismissActionAlertScene(): void;

  navigateToUrl(url: string): void;

  dismissActionSheetScene(): void;

  navigateToJokeReports(jokeId?: string): void;
  navigateToShare(title: string, url: string): void;
}

export class JokeDetailsRouter implements JokeDetailsRoutingLogic {
  scene?: JokeDetailsScene;

  dismissScene() {
    if (this.scene !== undefined) {
    }
  }

  showActionAlertScene(title?: string, message?: string): void {
    if (this.scene !== undefined) {
    }
  }

  dismissActionAlertScene(): void {
    if (this.scene !== undefined) {
    }
  }

  navigateToUrl(url: string): void {
    window.open(url);
  }

  dismissActionSheetScene(): void {
    if (this.scene !== undefined) {
    }
  }

  navigateToJokeReports(jokeId?: string): void {
    if (this.scene !== undefined) {
    }
  }

  navigateToShare(title: string, url: string) {}
}
