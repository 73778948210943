import {Joke} from '../../models/joke/Joke';
import {OperationError} from '../../operations/base/errors/OperationError';
import {TaskConfigurator} from '../../tasks/configurator/TaskConfigurator';
import {JokeTaskModels, JokeTaskProtocol} from '../../tasks/joke/JokeTask';
import {taskEnvironment} from '../../tasks/environment/TaskEnvironment';

export interface JokeDetailsWorkerDelegate {
  successDidFetchJokeDetails(joke?: Joke): void;
  failureDidFetchJokeDetails(error: OperationError): void;
}

export class JokeDetailsWorker {
  delegate?: JokeDetailsWorkerDelegate;

  jokeTask: JokeTaskProtocol = TaskConfigurator.instance.jokeTask();

  constructor(delegate?: JokeDetailsWorkerDelegate) {
    this.delegate = delegate;
    this.jokeTask.environment = taskEnvironment();
  }

  fetchJokeDetails(jokeId: string) {
    let delegate = this.delegate;
    this.jokeTask.fetchJoke(new JokeTaskModels.FetchJoke.Request(jokeId), {
      success(response?: JokeTaskModels.FetchJoke.Response) {
        delegate?.successDidFetchJokeDetails(response?.joke);
      },
      failure(error: OperationError) {
        delegate?.failureDidFetchJokeDetails(error);
      },
    });
  }
}
