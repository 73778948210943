import {TaskEnvironment} from '../environment/TaskEnvironment';
import {JokeTaskProtocol, JokeTask} from '../joke/JokeTask';
import {AuthenticationTaskProtocol, AuthenticationTask} from '../authentication/AuthenticationTask';

export class TaskConfigurator {
  static instance = new TaskConfigurator();

  environment: TaskEnvironment = TaskEnvironment.memory;

  jokeTask(): JokeTaskProtocol {
    return new JokeTask(this.environment);
  }

  authenticationTask(): AuthenticationTaskProtocol {
    return new AuthenticationTask(this.environment);
  }
}
