import {ApplicationStyle} from '../../style/ApplicationStyle';
import {ApplicationConstraints} from '../../style/ApplicationConstraints';
import {withAlphaHex} from 'with-alpha-hex';

export class JokeDetailsStyle {
  static instance = new JokeDetailsStyle();

  flatListModel(): JokeDetailsStyle.FlatListModel {
    return new JokeDetailsStyle.FlatListModel();
  }

  jokeCellModel(): JokeDetailsStyle.JokeCellModel {
    return new JokeDetailsStyle.JokeCellModel();
  }

  cellModel(): JokeDetailsStyle.CellModel {
    return new JokeDetailsStyle.CellModel();
  }
}

export namespace JokeDetailsStyle {
  export class FlatListModel {
    backgroundColor: any = ApplicationStyle.colors.backgroundColor;
    activityIndicatorColor: any = ApplicationStyle.colors.primary;
    noMoreItemsTextStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 14,
    };
    errorTextStyle: any = {
      color: ApplicationStyle.colors.secondary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 14,
    };
    blockImage: any = ApplicationStyle.images.blockedUserLargeImage;
    blockTextStyle: any = {
      color: ApplicationStyle.colors.primary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 14,
    };
  }

  export class CellModel {
    backgroundColor: any = ApplicationStyle.colors.transparent;
  }

  export class JokeCellModel {
    containerBorderColor: string = ApplicationStyle.colors.lightGray;
    containerBackgroundColor: string = ApplicationStyle.colors.white;
    containerBorderRadius: number = ApplicationConstraints.constant.x16;
    containerBorderWidth: number = ApplicationConstraints.constant.x1;

    avatarBorderRadius: number = ApplicationConstraints.constant.x20;
    avatarActivityColor: string = ApplicationStyle.colors.primary;
    avatarBackgroundColor: string = ApplicationStyle.colors.transparent;
    avatarBorderColor: string = withAlphaHex(ApplicationStyle.colors.white, 0.5);
    avatarBorderWidth: number = ApplicationConstraints.constant.x1;
    avatarMargin = ApplicationConstraints.constant.x0;
    avatarPlaceholder: any = ApplicationStyle.images.userAvatarPlaceholderSmallImage;

    moreImage: any = ApplicationStyle.images.moreSmallGrayImage;

    nameStyle: any = {
      color: ApplicationStyle.colors.primary,
      fontFamily: ApplicationStyle.fonts.bold,
      fontSize: 17,
    };
    usernameStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 14,
    };
    textStyle: any = {
      color: ApplicationStyle.colors.primary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
      whiteSpace: 'pre-wrap',
    };
    answerStyle: any = {
      color: ApplicationStyle.colors.primary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 17,
      whiteSpace: 'pre-wrap',
    };

    likeCountActivityColor: string = ApplicationStyle.colors.gray;
    likeCountImage: any = ApplicationStyle.images.likeSmallGrayImage;
    selectedLikeCountBackgroundColor: string = withAlphaHex(ApplicationStyle.colors.lightSecondary, 0.1);
    selectedLikeCountTintColor: string = ApplicationStyle.colors.lightSecondary;
    selectedLikeCountStyle: any = {
      color: ApplicationStyle.colors.lightSecondary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };
    unselectedLikeCountBackgroundColor: string = ApplicationStyle.colors.transparent;
    unselectedLikeCountTintColor: string = ApplicationStyle.colors.gray;
    unselectedLikeCountStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    dislikeCountActivityColor: string = ApplicationStyle.colors.primary;
    dislikeCountImage: any = ApplicationStyle.images.dislikeSmallGrayImage;
    selectedDislikeCountBackgroundColor: string = withAlphaHex(ApplicationStyle.colors.lightSecondary, 0.1);
    selectedDislikeCountTintColor: string = ApplicationStyle.colors.lightSecondary;
    selectedDislikeCountStyle: any = {
      color: ApplicationStyle.colors.lightSecondary,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };
    unselectedDislikeCountBackgroundColor: string = ApplicationStyle.colors.transparent;
    unselectedDislikeCountTintColor: string = ApplicationStyle.colors.gray;
    unselectedDislikeCountStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    commentCountActivityColor: string = ApplicationStyle.colors.primary;
    commentCountBackgroundColor: string = ApplicationStyle.colors.transparent;
    commentCountImage: any = ApplicationStyle.images.commentSmallImage;
    commentCountTintColor: string = ApplicationStyle.colors.gray;
    commentCountStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    shareBackgroundColor: string = ApplicationStyle.colors.transparent;
    shareImage: any = ApplicationStyle.images.shareSmallGrayImage;
    shareStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.regular,
      fontSize: 16,
    };

    timeStyle: any = {
      color: ApplicationStyle.colors.gray,
      fontFamily: ApplicationStyle.fonts.oblique,
      fontSize: 13,
    };
  }
}
