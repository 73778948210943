export type ImageResizeMode = 'cover' | 'contain' | 'stretch' | 'repeat' | 'center';

export class CompoundImage {
  uri: string | undefined;
  source?: string | undefined;
  resizeMode?: ImageResizeMode = 'cover';

  constructor(uri?: string, source?: string, resizeMode?: ImageResizeMode) {
    this.uri = uri;
    this.source = source;
    this.resizeMode = resizeMode ?? 'cover';
  }

  image(): string | undefined {
    if (this.source !== undefined) {
      return this.source;
    }
    return this.uri;
  }
}
