import {OperationError} from '../../operations/base/errors/OperationError';
import {Operation, Result} from '../../operations/base/operations/Operation';
import {OperationQueue} from '../../operations/base/operations/OperationQueue';
import {TaskEnvironment} from '../environment/TaskEnvironment';
import {TaskProtocol} from '../task_protocol/TaskProtocol';
import {UpdatePasswordOperation, UpdatePasswordLocalOperation, UpdatePasswordOperationModels} from '../../operations/authentication/UpdatePasswordOperation';
import {Message} from '../../models/message/Message';

export namespace AuthenticationTaskModels {
  export namespace UpdatePassword {
    export class Request {
      newPassword: string;
      confirmNewPassword: string;

      resetPasswordToken: string;

      constructor(newPassword: string, confirmNewPassword: string, resetPasswordToken: string) {
        this.newPassword = newPassword;
        this.confirmNewPassword = confirmNewPassword;
        this.resetPasswordToken = resetPasswordToken;
      }
    }

    export class Response {
      message?: Message;

      constructor(message?: Message) {
        this.message = message;
      }
    }
  }
}

export interface AuthenticationTaskProtocol extends TaskProtocol {
  updatePassword(model: AuthenticationTaskModels.UpdatePassword.Request, completionHandler: Result<AuthenticationTaskModels.UpdatePassword.Response>): void;
}

export class AuthenticationTask implements AuthenticationTaskProtocol {
  environment: TaskEnvironment;

  constructor(environment: TaskEnvironment) {
    this.environment = environment;
  }

  updatePasswordOperationQueue: OperationQueue = new OperationQueue();

  updatePassword(model: AuthenticationTaskModels.UpdatePassword.Request, completionHandler: Result<AuthenticationTaskModels.UpdatePassword.Response>): void {
    let operationModel = new UpdatePasswordOperationModels.Request(model.newPassword, model.confirmNewPassword, model.resetPasswordToken);
    let operation = this.updatePasswordOperation(operationModel, completionHandler);
    this.updatePasswordOperationQueue.addOperation(operation);
  }

  private updatePasswordOperation(model: UpdatePasswordOperationModels.Request, completionHandler: Result<AuthenticationTaskModels.UpdatePassword.Response>): Operation {
    let operationCompletionHandler: Result<UpdatePasswordOperationModels.Response> = {
      success(value?: UpdatePasswordOperationModels.Response) {
        completionHandler.success(new AuthenticationTaskModels.UpdatePassword.Response(value?.toMessage()));
      },
      failure(error: OperationError) {
        completionHandler.failure(error);
      },
    };
    switch (this.environment) {
      case TaskEnvironment.production:
        return new UpdatePasswordOperation(model, operationCompletionHandler);
      case TaskEnvironment.development:
        return new UpdatePasswordOperation(model, operationCompletionHandler);
      case TaskEnvironment.memory:
        return new UpdatePasswordLocalOperation(model, operationCompletionHandler);
    }
  }
}
