import * as React from 'react';

type StyleObject = {
  [key: string]: React.CSSProperties;
};

export interface ViewProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export class StyleSheet {
  static create<T extends StyleObject>(styles: T): T {
    let styleObject = {} as T;
    for (let key in styles) {
      styleObject[key] = styles[key];
    }
    return styleObject;
  }
}
