import React from 'react';
import {ImageResizeMode} from '../../../models/image/CompoundImage';

export default class Image extends React.Component<Image.Props, Image.State> {
  render() {
    return <img {...this.props} alt="" />;
  }
}

export namespace Image {
  export interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    resizeMode?: ImageResizeMode;
    backgroundPosition?: string;
  }

  export interface State {}
}
