import {JokesScene} from './scenes/jokes/JokesScene';
import {JokeDetailsScene} from './scenes/joke_details/JokeDetailsScene';
import {Routes, Route, useLocation} from 'react-router-dom';
import {ChangePasswordScene} from './scenes/change_password/ChangePasswordScene';

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<JokesScene model={new JokesScene.Model()} />} />
        <Route path="joke" element={<JokeDetails />} />
        <Route path="change-password" element={<ChangePassword />} />
      </Routes>
    </div>
  );
};

function JokeDetails() {
  let location = useLocation();
  let jokeId = new URLSearchParams(location.search).get('id');
  return <JokeDetailsScene id={jokeId} model={new JokeDetailsScene.Model()} />;
}

function ChangePassword() {
  let location = useLocation();
  let resetPasswordToken = new URLSearchParams(location.search).get('reset_token');
  return <ChangePasswordScene model={new ChangePasswordScene.Model(resetPasswordToken)} />;
}

export default App;
