import React from 'react';
import {StyleSheet} from '../../../models/style_sheet/StyleSheet';

export default class ImageBackground extends React.Component<ImageBackground.Props, ImageBackground.State> {
  render() {
    return <div style={this.styles.container}>{this.props.children}</div>;
  }

  styles = StyleSheet.create({
    container: {
      ...this.props.style,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundImage: `url(${this.props.src})`,
    },
  });
}

export namespace ImageBackground {
  export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    src: string | any;
  }

  export interface State {}
}
