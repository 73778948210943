import {localizedStrings} from '../../localization/LocalizationManager';

export class ApplicationLocalization {
  static instance = new ApplicationLocalization();

  cancelTitle(): string {
    return localizedStrings['Cancel.title'];
  }

  saveTitle(): string {
    return localizedStrings['Save.title'];
  }

  doneTitle(): string {
    return localizedStrings['Done.title'];
  }

  okTitle(): string {
    return localizedStrings['OK.title'];
  }

  editTitle(): string {
    return localizedStrings['Edit.title'];
  }

  uploadPhotoTitle(): string {
    return localizedStrings['Upload.photo.title'];
  }

  shareTitle(): string {
    return localizedStrings['Share.title'];
  }

  jokesTitle(): string {
    return localizedStrings['Jokes.title'];
  }

  trendingTitle(): string {
    return localizedStrings['Trending.title'];
  }

  jokeStatusPendingTitle(): string {
    return localizedStrings['Joke.status.pending.title'];
  }

  jokeStatusApprovedTitle(): string {
    return localizedStrings['Joke.status.approved.title'];
  }

  jokeStatusRejectedTitle(): string {
    return localizedStrings['Joke.status.rejected.title'];
  }

  jokeStatusAdminRemovedTitle(): string {
    return localizedStrings['Joke.status.admin.removed.title'];
  }

  jokeStatusOwnerRemovedTitle(): string {
    return localizedStrings['Joke.status.owner.removed.title'];
  }

  spamReasonText(): string {
    return localizedStrings['Joke.reason.spam.text'];
  }

  vulgarReasonText(): string {
    return localizedStrings['Joke.reason.vulgar.text'];
  }

  termsAndConditionsTitle(): string {
    return localizedStrings['Terms.and.conditions.title'];
  }

  privacyPolicyTitle(): string {
    return localizedStrings['Privacy.policy.title'];
  }

  andTitle(): string {
    return localizedStrings['And.title'];
  }

  ampersandTitle(): string {
    return localizedStrings['Ampersand.title'];
  }

  usernameTitle(username: string): string {
    return localizedStrings.formatString(localizedStrings['Username.title'], username) as string;
  }

  blockedUserTitle(): string {
    return localizedStrings['Blocked.user.title'];
  }

  blockUserTitle(username: string): string {
    return localizedStrings.formatString(localizedStrings['Block.user.title'], username) as string;
  }

  unblockUserTitle(username: string): string {
    return localizedStrings.formatString(localizedStrings['Unblock.user.title'], username) as string;
  }

  blockTitle(): string {
    return localizedStrings['Block.title'];
  }

  unblockTitle(): string {
    return localizedStrings['Unblock.title'];
  }

  reportJokeTitle(): string {
    return localizedStrings['Report.joke.title'];
  }

  reportUserTitle(): string {
    return localizedStrings['Report.user.title'];
  }

  shareJokeTitle(): string {
    return localizedStrings['Share.joke.title'];
  }

  shareJokeMessage(): string {
    return localizedStrings['Share.joke.message'];
  }

  readAnswerTitle(): string {
    return localizedStrings['Read.answer.title'];
  }

  jokeReportCustomTypeText(): string {
    return localizedStrings['Joke.report.custom.type.text'];
  }

  jokeReportSexualityTypeText(): string {
    return localizedStrings['Joke.report.sexuality.type.text'];
  }

  jokeReportViolenceTypeText(): string {
    return localizedStrings['Joke.report.violence.type.text'];
  }

  jokeReportCopyrightTypeText(): string {
    return localizedStrings['Joke.report.copyright.type.text'];
  }

  jokeReportIllegalTypeText(): string {
    return localizedStrings['Joke.report.illegal.type.text'];
  }

  iOSTitle(): string {
    return localizedStrings['iOS.title'];
  }

  androidTitle(): string {
    return localizedStrings['Android.title'];
  }

  jokeTypeTextTitle(): string {
    return localizedStrings['Joke.type.text.title'];
  }

  jokeTypeQnaTitle(): string {
    return localizedStrings['Joke.type.qna.title'];
  }

  userReportCustomTypeText(): string {
    return localizedStrings['User.report.custom.type.text'];
  }

  userReportSexualityTypeText(): string {
    return localizedStrings['User.report.sexuality.type.text'];
  }

  userReportViolenceTypeText(): string {
    return localizedStrings['User.report.violence.type.text'];
  }

  userReportCopyrightTypeText(): string {
    return localizedStrings['User.report.copyright.type.text'];
  }

  userReportIllegalTypeText(): string {
    return localizedStrings['User.report.illegal.type.text'];
  }

  shareAppTitle(): string {
    return localizedStrings['Share.app.title'];
  }

  shareAppSubtitle(): string {
    return localizedStrings['Share.app.subtitle'];
  }

  shareAppText(): string {
    return localizedStrings['Share.app.text'];
  }

  shareAppButtonTitle(): string {
    return localizedStrings['Share.app.button.title'];
  }

  shareAppMessage(): string {
    return localizedStrings['Share.app.message'];
  }

  pushNotificationsPermissionsTitle(): string {
    return localizedStrings['Push.notifications.permissions.title'];
  }

  pushNotificationsPermissionsSubtitle(): string {
    return localizedStrings['Push.notifications.permissions.subtitle'];
  }

  downloadAppTitle(): string {
    return localizedStrings['Download.app.title'];
  }

  downloadAppSubtitle(): string {
    return localizedStrings['Download.app.subtitle'];
  }

  downloadAppText(): string {
    return localizedStrings['Download.app.text'];
  }

  loadMoreTitle(): string {
    return localizedStrings['Load.more.title'];
  }

  appTitle(): string {
    return localizedStrings['App.title'];
  }

  metaTagDescription(): string {
    return localizedStrings['Meta.tag.description'];
  }
}
